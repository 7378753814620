import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";

import prevBtn from "../../components/assets/previous.png";

import WOW from "wowjs";

import { v4 as uuidV4 } from "uuid";

import bb1 from "../images/photos/bbb (1).jpg";
import bb2 from "../images/photos/bbb (2).JPG";
import bb3 from "../images/photos/bbb (3).JPG";
import bb4 from "../images/photos/bbb (4).JPG";
import bb5 from "../images/photos/bbb (5).JPG";
import bb6 from "../images/photos/bbb (6).JPG";
import bb7 from "../images/photos/bbb (7).jpg";
import bb8 from "../images/photos/bbb (8).JPG";
import bb9 from "../images/photos/bbb (9).JPG";
import bb10 from "../images/photos/bbb (10).JPG";
import bb11 from "../images/photos/bbb (11).JPG";
import bb12 from "../images/photos/bbb (12).JPG";
import bb13 from "../images/photos/bbb (13).JPG";
import bb14 from "../images/photos/bbb (14).JPG";
import bb15 from "../images/photos/bbb (15).JPG";
import bb16 from "../images/photos/bbb (16).JPG";
import bb17 from "../images/photos/bbb (17).jpg";
import bb18 from "../images/photos/bbb (18).JPG";
import bb19 from "../images/photos/bbb (19).JPG";
import bb20 from "../images/photos/bbb (20).JPG";
import bb21 from "../images/photos/bbb (21).JPG";
import bb22 from "../images/photos/bbb (22).JPG";
import bb23 from "../images/photos/bbb (23).JPG";
import bb24 from "../images/photos/bbb (24).jpg";
import bb25 from "../images/photos/bbb (25).jpg";
import bb26 from "../images/photos/bbb (26).jpg";
import bb27 from "../images/photos/bbb (27).jpg";
import bb28 from "../images/photos/bbb (28).jpg";
import bb29 from "../images/photos/bbb (29).jpg";

// blog / src / components / images / photos / yuvachaithanya - yathra(1).jpg;

import yuvachaithanyaYathra1 from "../images/photos/yuvachaithanya-yathra (1).jpg";
import yuvachaithanyaYathra2 from "../images/photos/yuvachaithanya-yathra (2).jpg";
import yuvachaithanyaYathra3 from "../images/photos/yuvachaithanya-yathra (3).jpg";
import yuvachaithanyaYathra4 from "../images/photos/yuvachaithanya-yathra (4).jpg";
import yuvachaithanyaYathra5 from "../images/photos/yuvachaithanya-yathra (5).jpg";
import yuvachaithanyaYathra6 from "../images/photos/yuvachaithanya-yathra (6).jpg";
import yuvachaithanyaYathra7 from "../images/photos/yuvachaithanya-yathra (7).jpg";
import yuvachaithanyaYathra8 from "../images/photos/yuvachaithanya-yathra (8).jpg";
import yuvachaithanyaYathra9 from "../images/photos/yuvachaithanya-yathra (9).jpg";
import yuvachaithanyaYathra10 from "../images/photos/yuvachaithanya-yathra (10).jpg";
import yuvachaithanyaYathra11 from "../images/photos/yuvachaithanya-yathra (11).jpg";
import yuvachaithanyaYathra12 from "../images/photos/yuvachaithanya-yathra (12).jpg";
import yuvachaithanyaYathra13 from "../images/photos/yuvachaithanya-yathra (13).jpg";
import yuvachaithanyaYathra14 from "../images/photos/yuvachaithanya-yathra (14).jpg";
import yuvachaithanyaYathra15 from "../images/photos/yuvachaithanya-yathra (15).jpg";
import yuvachaithanyaYathra16 from "../images/photos/yuvachaithanya-yathra (16).jpg";
import yuvachaithanyaYathra17 from "../images/photos/yuvachaithanya-yathra (17).jpg";
import yuvachaithanyaYathra18 from "../images/photos/yuvachaithanya-yathra (18).jpg";
import yuvachaithanyaYathra19 from "../images/photos/yuvachaithanya-yathra (19).jpg";
import yuvachaithanyaYathra20 from "../images/photos/yuvachaithanya-yathra (20).jpg";
import yuvachaithanyaYathra21 from "../images/photos/yuvachaithanya-yathra (21).JPG";
import yuvachaithanyaYathra22 from "../images/photos/yuvachaithanya-yathra (22).JPG";
import yuvachaithanyaYathra23 from "../images/photos/yuvachaithanya-yathra (23).JPG";
import yuvachaithanyaYathra24 from "../images/photos/yuvachaithanya-yathra (24).JPG";
import yuvachaithanyaYathra25 from "../images/photos/yuvachaithanya-yathra (25).JPG";
import yuvachaithanyaYathra26 from "../images/photos/yuvachaithanya-yathra (26).JPG";
import yuvachaithanyaYathra27 from "../images/photos/yuvachaithanya-yathra (27).jpg";
import yuvachaithanyaYathra28 from "../images/photos/yuvachaithanya-yathra (28).jpg";
import yuvachaithanyaYathra29 from "../images/photos/yuvachaithanya-yathra (29).jpg";
import yuvachaithanyaYathra30 from "../images/photos/yuvachaithanya-yathra (30).jpg";
import yuvachaithanyaYathra31 from "../images/photos/yuvachaithanya-yathra (31).jpg";
import yuvachaithanyaYathra32 from "../images/photos/yuvachaithanya-yathra (32).jpg";
import yuvachaithanyaYathra33 from "../images/photos/yuvachaithanya-yathra (33).jpg";
import yuvachaithanyaYathra34 from "../images/photos/yuvachaithanya-yathra (34).jpg";
import yuvachaithanyaYathra35 from "../images/photos/yuvachaithanya-yathra (35).jpg";
import yuvachaithanyaYathra36 from "../images/photos/yuvachaithanya-yathra (36).jpg";
import yuvachaithanyaYathra37 from "../images/photos/yuvachaithanya-yathra (37).jpg";
import yuvachaithanyaYathra38 from "../images/photos/yuvachaithanya-yathra (38).jpg";
import yuvachaithanyaYathra39 from "../images/photos/yuvachaithanya-yathra (39).jpg";
import yuvachaithanyaYathra40 from "../images/photos/yuvachaithanya-yathra (40).jpg";
import yuvachaithanyaYathra41 from "../images/photos/yuvachaithanya-yathra (41).jpg";
import yuvachaithanyaYathra42 from "../images/photos/yuvachaithanya-yathra (42).jpg";
import yuvachaithanyaYathra43 from "../images/photos/yuvachaithanya-yathra (43).jpg";
import yuvachaithanyaYathra44 from "../images/photos/yuvachaithanya-yathra (44).jpg";
import yuvachaithanyaYathra45 from "../images/photos/yuvachaithanya-yathra (45).jpg";
import yuvachaithanyaYathra46 from "../images/photos/yuvachaithanya-yathra (46).jpg";
import yuvachaithanyaYathra47 from "../images/photos/yuvachaithanya-yathra (47).jpg";
import yuvachaithanyaYathra48 from "../images/photos/yuvachaithanya-yathra (48).jpg";
import yuvachaithanyaYathra49 from "../images/photos/yuvachaithanya-yathra (49).jpg";
import yuvachaithanyaYathra50 from "../images/photos/yuvachaithanya-yathra (50).jpg";
import yuvachaithanyaYathra51 from "../images/photos/yuvachaithanya-yathra (51).jpg";
import yuvachaithanyaYathra52 from "../images/photos/yuvachaithanya-yathra (52).jpg";
import yuvachaithanyaYathra53 from "../images/photos/yuvachaithanya-yathra (53).jpg";
import yuvachaithanyaYathra54 from "../images/photos/yuvachaithanya-yathra (54).jpg";
import yuvachaithanyaYathra55 from "../images/photos/yuvachaithanya-yathra (55).jpg";
import yuvachaithanyaYathra56 from "../images/photos/yuvachaithanya-yathra (56).jpg";
import yuvachaithanyaYathra57 from "../images/photos/yuvachaithanya-yathra (57).jpg";
import yuvachaithanyaYathra58 from "../images/photos/yuvachaithanya-yathra (58).jpg";
import yuvachaithanyaYathra59 from "../images/photos/yuvachaithanya-yathra (59).jpg";
import yuvachaithanyaYathra60 from "../images/photos/yuvachaithanya-yathra (60).jpg";
import yuvachaithanyaYathra61 from "../images/photos/yuvachaithanya-yathra (61).jpg";
import yuvachaithanyaYathra62 from "../images/photos/yuvachaithanya-yathra (62).jpg";
import yuvachaithanyaYathra63 from "../images/photos/yuvachaithanya-yathra (63).jpg";
import yuvachaithanyaYathra64 from "../images/photos/yuvachaithanya-yathra (64).jpg";
import yuvachaithanyaYathra65 from "../images/photos/yuvachaithanya-yathra (65).jpg";
import yuvachaithanyaYathra66 from "../images/photos/yuvachaithanya-yathra (66).jpg";
import yuvachaithanyaYathra67 from "../images/photos/yuvachaithanya-yathra (67).jpg";
import yuvachaithanyaYathra68 from "../images/photos/yuvachaithanya-yathra (68).jpg";
import yuvachaithanyaYathra69 from "../images/photos/yuvachaithanya-yathra (69).jpg";
import yuvachaithanyaYathra70 from "../images/photos/yuvachaithanya-yathra (70).jpg";
import yuvachaithanyaYathra71 from "../images/photos/yuvachaithanya-yathra (71).jpg";
import yuvachaithanyaYathra72 from "../images/photos/yuvachaithanya-yathra (72).jpg";
import yuvachaithanyaYathra73 from "../images/photos/yuvachaithanya-yathra (73).jpg";
import yuvachaithanyaYathra74 from "../images/photos/yuvachaithanya-yathra (74).jpg";
import yuvachaithanyaYathra75 from "../images/photos/yuvachaithanya-yathra (75).jpg";
import yuvachaithanyaYathra76 from "../images/photos/yuvachaithanya-yathra (76).jpg";
import yuvachaithanyaYathra77 from "../images/photos/yuvachaithanya-yathra (77).jpg";
import yuvachaithanyaYathra78 from "../images/photos/yuvachaithanya-yathra (78).jpg";
import yuvachaithanyaYathra79 from "../images/photos/yuvachaithanya-yathra (79).jpg";
import yuvachaithanyaYathra80 from "../images/photos/yuvachaithanya-yathra (80).jpg";
import yuvachaithanyaYathra81 from "../images/photos/yuvachaithanya-yathra (81).jpg";
import yuvachaithanyaYathra82 from "../images/photos/yuvachaithanya-yathra (82).jpg";
import yuvachaithanyaYathra83 from "../images/photos/yuvachaithanya-yathra (83).jpg";
import yuvachaithanyaYathra84 from "../images/photos/yuvachaithanya-yathra (84).jpg";
import yuvachaithanyaYathra85 from "../images/photos/yuvachaithanya-yathra (85).jpg";
import yuvachaithanyaYathra86 from "../images/photos/yuvachaithanya-yathra (86).jpg";
import yuvachaithanyaYathra87 from "../images/photos/yuvachaithanya-yathra (87).jpg";
import yuvachaithanyaYathra88 from "../images/photos/yuvachaithanya-yathra (88).jpg";
import yuvachaithanyaYathra89 from "../images/photos/yuvachaithanya-yathra (89).jpg";
import yuvachaithanyaYathra90 from "../images/photos/yuvachaithanya-yathra (90).jpg";
import yuvachaithanyaYathra91 from "../images/photos/yuvachaithanya-yathra (91).jpg";
import yuvachaithanyaYathra92 from "../images/photos/yuvachaithanya-yathra (92).jpg";
import yuvachaithanyaYathra93 from "../images/photos/yuvachaithanya-yathra (93).jpg";
import yuvachaithanyaYathra94 from "../images/photos/yuvachaithanya-yathra (94).jpg";
import yuvachaithanyaYathra95 from "../images/photos/yuvachaithanya-yathra (95).jpg";
import yuvachaithanyaYathra96 from "../images/photos/yuvachaithanya-yathra (96).jpg";
import yuvachaithanyaYathra97 from "../images/photos/yuvachaithanya-yathra (97).jpg";
import yuvachaithanyaYathra98 from "../images/photos/yuvachaithanya-yathra (98).jpg";
import yuvachaithanyaYathra99 from "../images/photos/yuvachaithanya-yathra (99).JPG";
import yuvachaithanyaYathra100 from "../images/photos/yuvachaithanya-yathra (100).jpg";
import yuvachaithanyaYathra101 from "../images/photos/yuvachaithanya-yathra (101).jpg";
import yuvachaithanyaYathra102 from "../images/photos/yuvachaithanya-yathra (102).jpg";
import yuvachaithanyaYathra103 from "../images/photos/yuvachaithanya-yathra (103).jpg";
import yuvachaithanyaYathra104 from "../images/photos/yuvachaithanya-yathra (104).jpg";
import yuvachaithanyaYathra105 from "../images/photos/yuvachaithanya-yathra (105).jpg";
import yuvachaithanyaYathra106 from "../images/photos/yuvachaithanya-yathra (106).jpg";
import yuvachaithanyaYathra107 from "../images/photos/yuvachaithanya-yathra (107).jpg";
import HeaderAbout from "../headerAbout/headerAbout";
import Header from "../header/header";
import Footer from "../footer/footer";

import folder from "./photos/folder.png";

//LEADERS 1111111111111111111111111111111111111

//CBN
import cbn1 from "../images/allImages/CBN/CBN (1).jpg";
import cbn2 from "../images/allImages/CBN/CBN (2).jpg";
import cbn3 from "../images/allImages/CBN/CBN (3).jpg";
import cbn4 from "../images/allImages/CBN/CBN (4).jpg";
import cbn5 from "../images/allImages/CBN/CBN (5).jpg";
import cbn6 from "../images/allImages/CBN/CBN (6).jpg";
import cbn7 from "../images/allImages/CBN/CBN (7).jpg";
import cbn8 from "../images/allImages/CBN/CBN (8).jpg";
import cbn9 from "../images/allImages/CBN/CBN (9).jpg";
import cbn10 from "../images/allImages/CBN/CBN (10).JPG";
import cbn11 from "../images/allImages/CBN/CBN (11).jpg";
import cbn12 from "../images/allImages/CBN/CBN (12).jpg";
import cbn13 from "../images/allImages/CBN/CBN (13).jpg";

//CBN Sir

import cbnSir1 from "../images/allImages/CBN Sir/CBN Sir (1).jpg";
import cbnSir2 from "../images/allImages/CBN Sir/CBN Sir (2).jpg";
import cbnSir3 from "../images/allImages/CBN Sir/CBN Sir (3).jpg";
import cbnSir4 from "../images/allImages/CBN Sir/CBN Sir (4).jpg";
import cbnSir5 from "../images/allImages/CBN Sir/CBN Sir (5).jpg";
import cbnSir6 from "../images/allImages/CBN Sir/CBN Sir (6).jpg";
import cbnSir7 from "../images/allImages/CBN Sir/CBN Sir (7).jpg";
import cbnSir8 from "../images/allImages/CBN Sir/CBN Sir (8).jpg";
import cbnSir9 from "../images/allImages/CBN Sir/CBN Sir (9).jpg";
import cbnSir10 from "../images/allImages/CBN Sir/CBN Sir (10).jpg";
import cbnSir11 from "../images/allImages/CBN Sir/CBN Sir (11).jpg";
import cbnSir12 from "../images/allImages/CBN Sir/CBN Sir (12).jpg";
import cbnSir13 from "../images/allImages/CBN Sir/CBN Sir (13).jpg";
import cbnSir14 from "../images/allImages/CBN Sir/CBN Sir (14).jpg";
import cbnSir15 from "../images/allImages/CBN Sir/CBN Sir (15).jpg";
import cbnSir16 from "../images/allImages/CBN Sir/CBN Sir (16).jpg";
import cbnSir17 from "../images/allImages/CBN Sir/CBN Sir (17).jpg";
import cbnSir18 from "../images/allImages/CBN Sir/CBN Sir (18).jpg";
import cbnSir19 from "../images/allImages/CBN Sir/CBN Sir (19).jpg";
import cbnSir20 from "../images/allImages/CBN Sir/CBN Sir (20).jpg";
import cbnSir21 from "../images/allImages/CBN Sir/CBN Sir (21).jpg";
import cbnSir22 from "../images/allImages/CBN Sir/CBN Sir (22).jpg";
import cbnSir23 from "../images/allImages/CBN Sir/CBN Sir (23).jpg";
import cbnSir24 from "../images/allImages/CBN Sir/CBN Sir (24).jpg";
import cbnSir25 from "../images/allImages/CBN Sir/CBN Sir (25).jpg";
import cbnSir26 from "../images/allImages/CBN Sir/CBN Sir (26).jpg";

//Kollu

import kollu1 from "../images/allImages/Kollu/Kollu (1).jpg";
import kollu2 from "../images/allImages/Kollu/Kollu (2).jpg";
import kollu3 from "../images/allImages/Kollu/Kollu (3).jpg";
import kollu4 from "../images/allImages/Kollu/Kollu (4).jpg";
import kollu5 from "../images/allImages/Kollu/Kollu (5).jpg";
import kollu6 from "../images/allImages/Kollu/Kollu (6).jpg";
import kollu7 from "../images/allImages/Kollu/Kollu (7).jpg";
import kollu8 from "../images/allImages/Kollu/Kollu (8).jpg";
import kollu9 from "../images/allImages/Kollu/Kollu (9).jpg";
import kollu10 from "../images/allImages/Kollu/Kollu (10).jpg";
import kollu11 from "../images/allImages/Kollu/Kollu (11).jpg";
import kollu12 from "../images/allImages/Kollu/Kollu (12).jpg";
import kollu13 from "../images/allImages/Kollu/Kollu (13).jpg";

//Lokesh

import lokesh1 from "../images/allImages/Lokesh/Lokesh (1).jpg";
import lokesh2 from "../images/allImages/Lokesh/Lokesh (2).jpg";
import lokesh3 from "../images/allImages/Lokesh/Lokesh (3).jpg";
import lokesh4 from "../images/allImages/Lokesh/Lokesh (4).jpg";
import lokesh5 from "../images/allImages/Lokesh/Lokesh (5).jpg";
import lokesh6 from "../images/allImages/Lokesh/Lokesh (6).jpg";
import lokesh7 from "../images/allImages/Lokesh/Lokesh (7).jpg";
import lokesh8 from "../images/allImages/Lokesh/Lokesh (8).jpg";
import lokesh9 from "../images/allImages/Lokesh/Lokesh (9).jpg";
import lokesh10 from "../images/allImages/Lokesh/Lokesh (10).jpg";
import lokesh11 from "../images/allImages/Lokesh/Lokesh (11).jpg";
import lokesh12 from "../images/allImages/Lokesh/Lokesh (12).jpg";
import lokesh13 from "../images/allImages/Lokesh/Lokesh (13).jpg";
import lokesh14 from "../images/allImages/Lokesh/Lokesh (14).jpg";
import lokesh15 from "../images/allImages/Lokesh/Lokesh (15).jpg";
import lokesh16 from "../images/allImages/Lokesh/Lokesh (16).jpg";
import lokesh17 from "../images/allImages/Lokesh/Lokesh (17).jpg";
import lokesh18 from "../images/allImages/Lokesh/Lokesh (18).jpg";
import lokesh19 from "../images/allImages/Lokesh/Lokesh (19).JPG";
import lokesh20 from "../images/allImages/Lokesh/Lokesh (20).JPG";
import lokesh21 from "../images/allImages/Lokesh/Lokesh (21).jpg";

//Atchennaidu
import atchennaidu1 from "../images/allImages/Atchennaidu/atchennaidu (1).jpg";
import atchennaidu2 from "../images/allImages/Atchennaidu/atchennaidu (2).jpg";
import atchennaidu3 from "../images/allImages/Atchennaidu/atchennaidu (3).jpg";
import atchennaidu4 from "../images/allImages/Atchennaidu/atchennaidu (4).jpg";
import atchennaidu5 from "../images/allImages/Atchennaidu/atchennaidu (5).jpg";
import atchennaidu6 from "../images/allImages/Atchennaidu/atchennaidu (6).jpg";
import atchennaidu7 from "../images/allImages/Atchennaidu/atchennaidu (7).jpg";
import atchennaidu8 from "../images/allImages/Atchennaidu/atchennaidu (8).jpg";
import atchennaidu9 from "../images/allImages/Atchennaidu/atchennaidu (9).jpg";

//BVR
import bvr1 from "../images/allImages/BVR/BVR (1).jpg";
import bvr2 from "../images/allImages/BVR/BVR (2).jpg";
import bvr3 from "../images/allImages/BVR/BVR (3).jpg";
import bvr4 from "../images/allImages/BVR/BVR (4).jpg";
import bvr5 from "../images/allImages/BVR/BVR (5).jpg";
import bvr6 from "../images/allImages/BVR/BVR (6).jpg";
import bvr7 from "../images/allImages/BVR/BVR (7).jpg";
import bvr8 from "../images/allImages/BVR/BVR (8).jpg";
import bvr9 from "../images/allImages/BVR/BVR (9).jpg";
import bvr10 from "../images/allImages/BVR/BVR (10).jpg";
import bvr11 from "../images/allImages/BVR/BVR (11).jpg";
import bvr12 from "../images/allImages/BVR/BVR (12).jpg";
import bvr13 from "../images/allImages/BVR/BVR (13).jpg";
import bvr14 from "../images/allImages/BVR/BVR (14).jpg";
import bvr15 from "../images/allImages/BVR/BVR (15).jpg";
import bvr16 from "../images/allImages/BVR/BVR (16).jpg";
import bvr17 from "../images/allImages/BVR/BVR (17).jpg";
import bvr18 from "../images/allImages/BVR/BVR (18).jpg";
import bvr19 from "../images/allImages/BVR/BVR (19).jpg";
import bvr20 from "../images/allImages/BVR/BVR (20).jpg";
import bvr21 from "../images/allImages/BVR/BVR (21).jpg";

//IMP PERSONS 22222222222222222222222222222222

//BR Ambedkar
import brAmbedkar1 from "../images/allImages/BR Ambedkar/BR Ambedkar (1).jpg";
import brAmbedkar2 from "../images/allImages/BR Ambedkar/BR Ambedkar (2).jpg";
import brAmbedkar3 from "../images/allImages/BR Ambedkar/BR Ambedkar (3).jpg";
import brAmbedkar4 from "../images/allImages/BR Ambedkar/BR Ambedkar (4).jpg";
import brAmbedkar5 from "../images/allImages/BR Ambedkar/BR Ambedkar (5).jpg";
import brAmbedkar6 from "../images/allImages/BR Ambedkar/BR Ambedkar (6).jpg";

//Vivekanandha
import vivekanandha1 from "../images/allImages/vivekanandha/imp-person1.jpeg";
import vivekanandha2 from "../images/allImages/vivekanandha/imp-person2.jpeg";

//BRC
import brc1 from "../images/allImages/BRC/BRC (1).jpg";
import brc2 from "../images/allImages/BRC/BRC (2).jpg";
import brc3 from "../images/allImages/BRC/BRC (3).jpg";
import brc4 from "../images/allImages/BRC/BRC (4).jpg";
import brc5 from "../images/allImages/BRC/BRC (5).jpg";
import brc6 from "../images/allImages/BRC/BRC (6).jpg";
import brc7 from "../images/allImages/BRC/BRC (7).jpg";
import brc8 from "../images/allImages/BRC/BRC (8).jpg";
import brc9 from "../images/allImages/BRC/BRC (9).jpg";
import brc10 from "../images/allImages/BRC/BRC (10).jpg";
import brc11 from "../images/allImages/BRC/BRC (11).jpg";
import brc12 from "../images/allImages/BRC/BRC (12).jpg";
import brc13 from "../images/allImages/BRC/BRC (13).jpg";
import brc14 from "../images/allImages/BRC/BRC (14).jpg";
import brc15 from "../images/allImages/BRC/BRC (15).jpg";
import brc16 from "../images/allImages/BRC/BRC (16).jpg";
import brc17 from "../images/allImages/BRC/BRC (17).jpg";
import brc18 from "../images/allImages/BRC/BRC (18).jpg";
import brc19 from "../images/allImages/BRC/BRC (19).jpg";
import brc20 from "../images/allImages/BRC/BRC (20).jpg";
import brc21 from "../images/allImages/BRC/BRC (21).jpg";
import brc22 from "../images/allImages/BRC/BRC (22).jpg";
import brc23 from "../images/allImages/BRC/BRC (23).jpg";
import brc24 from "../images/allImages/BRC/BRC (24).jpg";
import brc25 from "../images/allImages/BRC/BRC (25).jpg";
import brc26 from "../images/allImages/BRC/BRC (26).jpg";
import brc27 from "../images/allImages/BRC/BRC (27).jpg";
import brc28 from "../images/allImages/BRC/BRC (28).jpg";
import brc29 from "../images/allImages/BRC/BRC (29).jpg";
import brc30 from "../images/allImages/BRC/BRC (30).jpg";
import brc31 from "../images/allImages/BRC/BRC (31).jpg";
import brc32 from "../images/allImages/BRC/BRC (32).JPG";
import brc33 from "../images/allImages/BRC/BRC (33).JPG";
import brc34 from "../images/allImages/BRC/BRC (34).JPG";
import brc35 from "../images/allImages/BRC/BRC (35).JPG";
import brc36 from "../images/allImages/BRC/BRC (36).JPG";
import brc37 from "../images/allImages/BRC/BRC (37).JPG";
import brc38 from "../images/allImages/BRC/BRC (38).jpg";
import brc39 from "../images/allImages/BRC/BRC (39).JPG";
import brc40 from "../images/allImages/BRC/BRC (40).JPG";
import brc41 from "../images/allImages/BRC/BRC (41).JPG";
import brc42 from "../images/allImages/BRC/BRC (42).jpg";

//Poole
import poole1 from "../images/allImages/Poole/Poole (1).jpg";
import poole2 from "../images/allImages/Poole/Poole (2).jpg";
import poole3 from "../images/allImages/Poole/Poole (3).jpg";
import poole4 from "../images/allImages/Poole/Poole (4).jpg";
import poole5 from "../images/allImages/Poole/Poole (5).jpg";
import poole6 from "../images/allImages/Poole/Poole (6).jpg";
import poole7 from "../images/allImages/Poole/Poole (7).jpg";
import poole8 from "../images/allImages/Poole/Poole (8).jpg";
import poole9 from "../images/allImages/Poole/Poole (9).jpg";

//Gandhi
import gandhi1 from "../images/allImages/Gandhi/Gandhi (1).jpg";
import gandhi2 from "../images/allImages/Gandhi/Gandhi (2).jpg";
import gandhi3 from "../images/allImages/Gandhi/Gandhi (3).jpg";
import gandhi4 from "../images/allImages/Gandhi/Gandhi (4).jpg";
import gandhi5 from "../images/allImages/Gandhi/Gandhi (5).jpg";
import gandhi6 from "../images/allImages/Gandhi/Gandhi (6).jpg";
import gandhi7 from "../images/allImages/Gandhi/Gandhi (7).jpg";

//NTR
import ntr1 from "../images/allImages/NTR/NTR (1).jpg";
import ntr2 from "../images/allImages/NTR/NTR (2).jpg";
import ntr3 from "../images/allImages/NTR/NTR (3).jpg";
import ntr4 from "../images/allImages/NTR/NTR (4).jpg";
import ntr5 from "../images/allImages/NTR/NTR (5).jpg";
import ntr6 from "../images/allImages/NTR/NTR (6).jpg";
import ntr7 from "../images/allImages/NTR/NTR (7).jpg";
import ntr8 from "../images/allImages/NTR/NTR (8).jpg";
import ntr9 from "../images/allImages/NTR/NTR (9).jpg";
import ntr10 from "../images/allImages/NTR/NTR (10).jpg";
import ntr11 from "../images/allImages/NTR/NTR (11).jpg";
import ntr12 from "../images/allImages/NTR/NTR (12).jpg";

//politics 3333333333333333333333333333333333

//AMARAVATHI
import amravathi1 from "../images/allImages/Amaravathi/amaravathi (1).jpeg";
import amravathi2 from "../images/allImages/Amaravathi/amaravathi (2).jpg";
import amravathi3 from "../images/allImages/Amaravathi/amaravathi (3).jpg";
import amravathi4 from "../images/allImages/Amaravathi/amaravathi (4).jpeg";
import amravathi5 from "../images/allImages/Amaravathi/amaravathi (5).jpeg";
import amravathi6 from "../images/allImages/Amaravathi/amaravathi (6).jpg";
import amravathi7 from "../images/allImages/Amaravathi/amaravathi (7).jpeg";
import amravathi8 from "../images/allImages/Amaravathi/amaravathi (8).jpeg";
import amravathi9 from "../images/allImages/Amaravathi/amaravathi (9).jpeg";
import amravathi10 from "../images/allImages/Amaravathi/amaravathi (10).jpeg";
import amravathi11 from "../images/allImages/Amaravathi/amaravathi (11).jpeg";
import amravathi12 from "../images/allImages/Amaravathi/amaravathi (12).jpeg";
import amravathi13 from "../images/allImages/Amaravathi/amaravathi (13).jpeg";
import amravathi14 from "../images/allImages/Amaravathi/amaravathi (14).jpeg";
import amravathi15 from "../images/allImages/Amaravathi/amaravathi (15).jpeg";
import amravathi16 from "../images/allImages/Amaravathi/amaravathi (16).jpeg";
import amravathi17 from "../images/allImages/Amaravathi/amaravathi (17).jpeg";
import amravathi18 from "../images/allImages/Amaravathi/amaravathi (18).jpeg";
import amravathi19 from "../images/allImages/Amaravathi/amaravathi (19).jpeg";
import amravathi20 from "../images/allImages/Amaravathi/amaravathi (20).jpg";
import amravathi21 from "../images/allImages/Amaravathi/amaravathi (21).jpeg";
import amravathi22 from "../images/allImages/Amaravathi/amaravathi (22).jpeg";
import amravathi23 from "../images/allImages/Amaravathi/amaravathi (23).jpeg";
import amravathi24 from "../images/allImages/Amaravathi/amaravathi (24).jpg";
import amravathi25 from "../images/allImages/Amaravathi/amaravathi (25).jpg";

//Baadude Badudu

import baadudeBadudu1 from "../images/allImages/Baadude Badudu/baadude-badudu (1).jpg";
import baadudeBadudu2 from "../images/allImages/Baadude Badudu/baadude-badudu (2).jpg";
import baadudeBadudu3 from "../images/allImages/Baadude Badudu/baadude-badudu (3).jpg";
import baadudeBadudu4 from "../images/allImages/Baadude Badudu/baadude-badudu (4).jpg";
import baadudeBadudu5 from "../images/allImages/Baadude Badudu/baadude-badudu (5).jpg";
import baadudeBadudu6 from "../images/allImages/Baadude Badudu/baadude-badudu (6).jpg";
import baadudeBadudu7 from "../images/allImages/Baadude Badudu/baadude-badudu (7).jpg";
import baadudeBadudu8 from "../images/allImages/Baadude Badudu/baadude-badudu (8).jpg";
import baadudeBadudu9 from "../images/allImages/Baadude Badudu/baadude-badudu (9).jpg";
import baadudeBadudu10 from "../images/allImages/Baadude Badudu/baadude-badudu (10).jpg";
import baadudeBadudu11 from "../images/allImages/Baadude Badudu/baadude-badudu (11).jpg";
import baadudeBadudu12 from "../images/allImages/Baadude Badudu/baadude-badudu (12).jpg";
import baadudeBadudu13 from "../images/allImages/Baadude Badudu/baadude-badudu (13).jpg";
import baadudeBadudu14 from "../images/allImages/Baadude Badudu/baadude-badudu (14).jpg";
import baadudeBadudu15 from "../images/allImages/Baadude Badudu/baadude-badudu (15).jpg";
import baadudeBadudu16 from "../images/allImages/Baadude Badudu/baadude-badudu (16).jpg";
import baadudeBadudu17 from "../images/allImages/Baadude Badudu/baadude-badudu (17).jpg";
import baadudeBadudu18 from "../images/allImages/Baadude Badudu/baadude-badudu (18).jpg";
import baadudeBadudu19 from "../images/allImages/Baadude Badudu/baadude-badudu (19).jpg";
import baadudeBadudu20 from "../images/allImages/Baadude Badudu/baadude-badudu (20).jpg";
import baadudeBadudu21 from "../images/allImages/Baadude Badudu/baadude-badudu (21).jpg";
import baadudeBadudu22 from "../images/allImages/Baadude Badudu/baadude-badudu (22).jpg";

// BC Hostel Visit
import bcHostelVisit1 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (1).jpg";
import bcHostelVisit2 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (2).jpg";
import bcHostelVisit3 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (3).jpg";
import bcHostelVisit4 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (4).jpg";
import bcHostelVisit5 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (5).jpg";
import bcHostelVisit6 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (6).jpg";
import bcHostelVisit7 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (7).jpg";
import bcHostelVisit8 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (8).jpg";
import bcHostelVisit9 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (9).jpg";
import bcHostelVisit10 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (10).jpg";
import bcHostelVisit11 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (11).jpg";
import bcHostelVisit12 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (12).jpg";
import bcHostelVisit13 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (13).jpg";
import bcHostelVisit14 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (14).jpg";
import bcHostelVisit15 from "../images/allImages/BC Hostel Visit/bc-hostel-visit (15).jpg";

//BC Round Tables
import bcRoundTables1 from "../images/allImages/BC Round Tables/BC Round Tables (1).jpg";
import bcRoundTables2 from "../images/allImages/BC Round Tables/BC Round Tables (2).jpg";
import bcRoundTables3 from "../images/allImages/BC Round Tables/BC Round Tables (3).jpg";
import bcRoundTables4 from "../images/allImages/BC Round Tables/BC Round Tables (4).jpg";
import bcRoundTables5 from "../images/allImages/BC Round Tables/BC Round Tables (5).jpg";
import bcRoundTables6 from "../images/allImages/BC Round Tables/BC Round Tables (6).jpg";
import bcRoundTables7 from "../images/allImages/BC Round Tables/BC Round Tables (7).jpg";
import bcRoundTables8 from "../images/allImages/BC Round Tables/BC Round Tables (8).jpg";
import bcRoundTables9 from "../images/allImages/BC Round Tables/BC Round Tables (9).jpg";
import bcRoundTables10 from "../images/allImages/BC Round Tables/BC Round Tables (10).jpg";
import bcRoundTables11 from "../images/allImages/BC Round Tables/BC Round Tables (11).jpg";
import bcRoundTables12 from "../images/allImages/BC Round Tables/BC Round Tables (12).jpg";
import bcRoundTables13 from "../images/allImages/BC Round Tables/BC Round Tables (13).jpg";
import bcRoundTables14 from "../images/allImages/BC Round Tables/BC Round Tables (14).jpg";
import bcRoundTables15 from "../images/allImages/BC Round Tables/BC Round Tables (15).jpg";
import bcRoundTables16 from "../images/allImages/BC Round Tables/BC Round Tables (16).jpg";
import bcRoundTables17 from "../images/allImages/BC Round Tables/BC Round Tables (17).jpg";
import bcRoundTables18 from "../images/allImages/BC Round Tables/BC Round Tables (18).jpg";
import bcRoundTables19 from "../images/allImages/BC Round Tables/BC Round Tables (19).jpg";
import bcRoundTables20 from "../images/allImages/BC Round Tables/BC Round Tables (20).jpg";
import bcRoundTables21 from "../images/allImages/BC Round Tables/BC Round Tables (21).jpg";
//Bhavishyat Guarantee Bus yatra

import bhavishyatGuaranteeBusyatra1 from "../images/allImages/Bhavishyat Guarantee Bus yatra/Bhavishyat Guarantee Bus yatra (1).jpg";
import bhavishyatGuaranteeBusyatra2 from "../images/allImages/Bhavishyat Guarantee Bus yatra/Bhavishyat Guarantee Bus yatra (2).jpg";
import bhavishyatGuaranteeBusyatra3 from "../images/allImages/Bhavishyat Guarantee Bus yatra/Bhavishyat Guarantee Bus yatra (3).jpg";
import bhavishyatGuaranteeBusyatra4 from "../images/allImages/Bhavishyat Guarantee Bus yatra/Bhavishyat Guarantee Bus yatra (4).jpg";
import bhavishyatGuaranteeBusyatra5 from "../images/allImages/Bhavishyat Guarantee Bus yatra/Bhavishyat Guarantee Bus yatra (5).jpg";

//Bus Charges
import busCharges1 from "../images/allImages/Bus Charges/Bus Charges (1).jpg";
import busCharges2 from "../images/allImages/Bus Charges/Bus Charges (2).jpg";
import busCharges3 from "../images/allImages/Bus Charges/Bus Charges (3).jpg";
import busCharges4 from "../images/allImages/Bus Charges/Bus Charges (4).jpg";
import busCharges5 from "../images/allImages/Bus Charges/Bus Charges (5).jpg";
import busCharges6 from "../images/allImages/Bus Charges/Bus Charges (6).jpg";
import busCharges7 from "../images/allImages/Bus Charges/Bus Charges (7).jpg";
import busCharges8 from "../images/allImages/Bus Charges/Bus Charges (8).jpg";
import busCharges9 from "../images/allImages/Bus Charges/Bus Charges (9).jpg";
import busCharges10 from "../images/allImages/Bus Charges/Bus Charges (10).jpg";
import busCharges11 from "../images/allImages/Bus Charges/Bus Charges (11).jpg";
import busCharges12 from "../images/allImages/Bus Charges/Bus Charges (12).jpg";
import busCharges13 from "../images/allImages/Bus Charges/Bus Charges (13).jpg";

//CBN Dharmaporata Deeksha
import cbnDharmaporataDeeksha1 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (1).jpg";
import cbnDharmaporataDeeksha2 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (2).jpg";
import cbnDharmaporataDeeksha3 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (3).jpg";
import cbnDharmaporataDeeksha4 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (4).jpg";
import cbnDharmaporataDeeksha5 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (5).jpg";
import cbnDharmaporataDeeksha6 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (6).jpg";
import cbnDharmaporataDeeksha7 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (7).jpg";
import cbnDharmaporataDeeksha8 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (8).jpg";
import cbnDharmaporataDeeksha9 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (9).jpg";
import cbnDharmaporataDeeksha10 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (10).jpg";
import cbnDharmaporataDeeksha11 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (11).jpg";
import cbnDharmaporataDeeksha12 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (12).jpg";
import cbnDharmaporataDeeksha13 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (13).jpg";
import cbnDharmaporataDeeksha14 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (14).jpg";
import cbnDharmaporataDeeksha15 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (15).jpg";
import cbnDharmaporataDeeksha16 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (16).jpg";
import cbnDharmaporataDeeksha17 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (17).jpg";
import cbnDharmaporataDeeksha18 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (18).jpg";
import cbnDharmaporataDeeksha19 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (19).jpg";
import cbnDharmaporataDeeksha20 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (20).jpg";
import cbnDharmaporataDeeksha21 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (21).jpg";
import cbnDharmaporataDeeksha22 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (22).jpg";
import cbnDharmaporataDeeksha23 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (23).jpg";
import cbnDharmaporataDeeksha24 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (24).jpg";
import cbnDharmaporataDeeksha25 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (25).jpg";
import cbnDharmaporataDeeksha26 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (26).jpg";
import cbnDharmaporataDeeksha27 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (27).jpg";
import cbnDharmaporataDeeksha28 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (28).jpg";
import cbnDharmaporataDeeksha29 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (29).jpg";
import cbnDharmaporataDeeksha30 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (30).jpg";
import cbnDharmaporataDeeksha31 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (31).jpg";
import cbnDharmaporataDeeksha32 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (32).jpg";
import cbnDharmaporataDeeksha33 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (33).jpg";
import cbnDharmaporataDeeksha34 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (34).jpg";
import cbnDharmaporataDeeksha35 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (35).jpg";
import cbnDharmaporataDeeksha36 from "../images/allImages/CBN Dharmaporata Deeksha/CBN Dharmaporata Deeksha (36).jpg";

//CBN Program
import cbnProgram1 from "../images/allImages/CBN Program/CBN Program (1).jpg";
import cbnProgram2 from "../images/allImages/CBN Program/CBN Program (2).jpg";
import cbnProgram3 from "../images/allImages/CBN Program/CBN Program (3).jpg";
import cbnProgram4 from "../images/allImages/CBN Program/CBN Program (4).jpg";
import cbnProgram5 from "../images/allImages/CBN Program/CBN Program (5).jpg";
import cbnProgram6 from "../images/allImages/CBN Program/CBN Program (6).jpg";
import cbnProgram7 from "../images/allImages/CBN Program/CBN Program (7).jpg";
import cbnProgram8 from "../images/allImages/CBN Program/CBN Program (8).jpg";
import cbnProgram9 from "../images/allImages/CBN Program/CBN Program (9).jpg";
import cbnProgram10 from "../images/allImages/CBN Program/CBN Program (10).jpg";
import cbnProgram11 from "../images/allImages/CBN Program/CBN Program (11).jpg";
import cbnProgram12 from "../images/allImages/CBN Program/CBN Program (12).jpg";
import cbnProgram13 from "../images/allImages/CBN Program/CBN Program (13).jpg";
import cbnProgram14 from "../images/allImages/CBN Program/CBN Program (14).jpg";
import cbnProgram15 from "../images/allImages/CBN Program/CBN Program (15).jpg";
import cbnProgram16 from "../images/allImages/CBN Program/CBN Program (16).jpg";

//Cycle Yatra
import cycleYatra1 from "../images/allImages/Cycle Yatra/Cycle Yatra (1).jpg";
import cycleYatra2 from "../images/allImages/Cycle Yatra/Cycle Yatra (2).jpg";
import cycleYatra3 from "../images/allImages/Cycle Yatra/Cycle Yatra (3).jpg";
import cycleYatra4 from "../images/allImages/Cycle Yatra/Cycle Yatra (4).jpg";
import cycleYatra5 from "../images/allImages/Cycle Yatra/Cycle Yatra (5).jpg";
import cycleYatra6 from "../images/allImages/Cycle Yatra/Cycle Yatra (6).jpg";
import cycleYatra7 from "../images/allImages/Cycle Yatra/Cycle Yatra (7).jpg";
import cycleYatra8 from "../images/allImages/Cycle Yatra/Cycle Yatra (8).jpg";
import cycleYatra9 from "../images/allImages/Cycle Yatra/Cycle Yatra (9).jpg";
import cycleYatra10 from "../images/allImages/Cycle Yatra/Cycle Yatra (10).jpg";
import cycleYatra11 from "../images/allImages/Cycle Yatra/Cycle Yatra (11).jpg";
import cycleYatra12 from "../images/allImages/Cycle Yatra/Cycle Yatra (12).jpg";
import cycleYatra13 from "../images/allImages/Cycle Yatra/Cycle Yatra (13).jpg";
import cycleYatra14 from "../images/allImages/Cycle Yatra/Cycle Yatra (14).jpg";
import cycleYatra15 from "../images/allImages/Cycle Yatra/Cycle Yatra (15).jpg";
import cycleYatra16 from "../images/allImages/Cycle Yatra/Cycle Yatra (16).jpg";
import cycleYatra17 from "../images/allImages/Cycle Yatra/Cycle Yatra (17).jpg";
import cycleYatra18 from "../images/allImages/Cycle Yatra/Cycle Yatra (18).jpg";
import cycleYatra19 from "../images/allImages/Cycle Yatra/Cycle Yatra (19).jpg";
import cycleYatra20 from "../images/allImages/Cycle Yatra/Cycle Yatra (20).jpg";
import cycleYatra21 from "../images/allImages/Cycle Yatra/Cycle Yatra (21).jpg";
import cycleYatra22 from "../images/allImages/Cycle Yatra/Cycle Yatra (22).jpg";
import cycleYatra23 from "../images/allImages/Cycle Yatra/Cycle Yatra (23).jpg";
import cycleYatra24 from "../images/allImages/Cycle Yatra/Cycle Yatra (24).jpg";
import cycleYatra25 from "../images/allImages/Cycle Yatra/Cycle Yatra (25).jpg";
import cycleYatra26 from "../images/allImages/Cycle Yatra/Cycle Yatra (26).jpg";
import cycleYatra27 from "../images/allImages/Cycle Yatra/Cycle Yatra (27).jpg";
import cycleYatra28 from "../images/allImages/Cycle Yatra/Cycle Yatra (28).jpg";

//Idemi Karma
import idemiKarma1 from "../images/allImages/IdemiKarma/IdemiKarma (1).jpg";
import idemiKarma2 from "../images/allImages/IdemiKarma/IdemiKarma (2).jpg";
import idemiKarma3 from "../images/allImages/IdemiKarma/IdemiKarma (3).jpg";
import idemiKarma4 from "../images/allImages/IdemiKarma/IdemiKarma (4).jpg";
import idemiKarma5 from "../images/allImages/IdemiKarma/IdemiKarma (5).jpg";
import idemiKarma6 from "../images/allImages/IdemiKarma/IdemiKarma (6).jpg";
import idemiKarma7 from "../images/allImages/IdemiKarma/IdemiKarma (7).jpg";
import idemiKarma8 from "../images/allImages/IdemiKarma/IdemiKarma (8).jpg";
import idemiKarma9 from "../images/allImages/IdemiKarma/IdemiKarma (9).jpg";
import idemiKarma10 from "../images/allImages/IdemiKarma/IdemiKarma (10).jpg";
import idemiKarma11 from "../images/allImages/IdemiKarma/IdemiKarma (11).jpg";
import idemiKarma12 from "../images/allImages/IdemiKarma/IdemiKarma (12).jpg";
import idemiKarma13 from "../images/allImages/IdemiKarma/IdemiKarma (13).jpg";
import idemiKarma14 from "../images/allImages/IdemiKarma/IdemiKarma (14).jpg";
import idemiKarma15 from "../images/allImages/IdemiKarma/IdemiKarma (15).jpg";
import idemiKarma16 from "../images/allImages/IdemiKarma/IdemiKarma (16).jpg";
import idemiKarma17 from "../images/allImages/IdemiKarma/IdemiKarma (17).jpg";
import idemiKarma18 from "../images/allImages/IdemiKarma/IdemiKarma (18).jpg";
import idemiKarma19 from "../images/allImages/IdemiKarma/IdemiKarma (19).jpg";
import idemiKarma20 from "../images/allImages/IdemiKarma/IdemiKarma (20).jpg";

//Mahanadu
import mahanadu1 from "../images/allImages/Mahanadu/Mahanadu (1).jpg";
import mahanadu2 from "../images/allImages/Mahanadu/Mahanadu (2).jpg";
import mahanadu3 from "../images/allImages/Mahanadu/Mahanadu (3).jpg";
import mahanadu4 from "../images/allImages/Mahanadu/Mahanadu (4).jpg";
import mahanadu5 from "../images/allImages/Mahanadu/Mahanadu (5).jpg";
import mahanadu6 from "../images/allImages/Mahanadu/Mahanadu (6).jpg";
import mahanadu7 from "../images/allImages/Mahanadu/Mahanadu (7).JPG";
import mahanadu8 from "../images/allImages/Mahanadu/Mahanadu (8).jpg";
import mahanadu9 from "../images/allImages/Mahanadu/Mahanadu (9).jpg";
import mahanadu10 from "../images/allImages/Mahanadu/Mahanadu (10).jpg";
import mahanadu11 from "../images/allImages/Mahanadu/Mahanadu (11).jpg";
import mahanadu12 from "../images/allImages/Mahanadu/Mahanadu (12).jpg";
import mahanadu13 from "../images/allImages/Mahanadu/Mahanadu (13).jpg";
import mahanadu14 from "../images/allImages/Mahanadu/Mahanadu (14).jpg";
import mahanadu15 from "../images/allImages/Mahanadu/Mahanadu (15).jpg";
import mahanadu16 from "../images/allImages/Mahanadu/Mahanadu (16).jpg";
import mahanadu17 from "../images/allImages/Mahanadu/Mahanadu (17).jpg";
import mahanadu18 from "../images/allImages/Mahanadu/Mahanadu (18).jpg";
import mahanadu19 from "../images/allImages/Mahanadu/Mahanadu (19).jpg";
import mahanadu20 from "../images/allImages/Mahanadu/Mahanadu (20).jpg";
import mahanadu21 from "../images/allImages/Mahanadu/Mahanadu (21).jpg";
import mahanadu22 from "../images/allImages/Mahanadu/Mahanadu (22).jpg";
import mahanadu23 from "../images/allImages/Mahanadu/Mahanadu (23).jpg";
import mahanadu24 from "../images/allImages/Mahanadu/Mahanadu (24).jpg";
import mahanadu25 from "../images/allImages/Mahanadu/Mahanadu (25).jpg";
import mahanadu26 from "../images/allImages/Mahanadu/Mahanadu (26).jpg";
import mahanadu27 from "../images/allImages/Mahanadu/Mahanadu (27).jpg";
import mahanadu28 from "../images/allImages/Mahanadu/Mahanadu (28).jpg";
import mahanadu29 from "../images/allImages/Mahanadu/Mahanadu (29).jpg";
import mahanadu30 from "../images/allImages/Mahanadu/Mahanadu (30).jpg";
import mahanadu31 from "../images/allImages/Mahanadu/Mahanadu (31).jpg";
import mahanadu32 from "../images/allImages/Mahanadu/Mahanadu (32).jpg";
import mahanadu33 from "../images/allImages/Mahanadu/Mahanadu (33).jpg";

//NLR Municipality
import nlrMunicipality1 from "../images/allImages/NLR Municipality/NLR Municipality (1).jpg";
import nlrMunicipality2 from "../images/allImages/NLR Municipality/NLR Municipality (2).jpg";
import nlrMunicipality3 from "../images/allImages/NLR Municipality/NLR Municipality (3).jpg";
import nlrMunicipality4 from "../images/allImages/NLR Municipality/NLR Municipality (4).jpg";
import nlrMunicipality5 from "../images/allImages/NLR Municipality/NLR Municipality (5).jpg";
import nlrMunicipality6 from "../images/allImages/NLR Municipality/NLR Municipality (6).jpg";
import nlrMunicipality7 from "../images/allImages/NLR Municipality/NLR Municipality (7).jpg";
import nlrMunicipality8 from "../images/allImages/NLR Municipality/NLR Municipality (8).jpg";
import nlrMunicipality9 from "../images/allImages/NLR Municipality/NLR Municipality (9).jpg";
import nlrMunicipality10 from "../images/allImages/NLR Municipality/NLR Municipality (10).jpg";
import nlrMunicipality11 from "../images/allImages/NLR Municipality/NLR Municipality (11).jpg";
import nlrMunicipality12 from "../images/allImages/NLR Municipality/NLR Municipality (12).jpg";
import nlrMunicipality13 from "../images/allImages/NLR Municipality/NLR Municipality (13).jpg";
import nlrMunicipality14 from "../images/allImages/NLR Municipality/NLR Municipality (14).jpg";
import nlrMunicipality15 from "../images/allImages/NLR Municipality/NLR Municipality (15).jpg";
import nlrMunicipality16 from "../images/allImages/NLR Municipality/NLR Municipality (16).jpg";
import nlrMunicipality17 from "../images/allImages/NLR Municipality/NLR Municipality (17).jpg";
import nlrMunicipality18 from "../images/allImages/NLR Municipality/NLR Municipality (18).jpg";
import nlrMunicipality19 from "../images/allImages/NLR Municipality/NLR Municipality (19).jpg";
import nlrMunicipality20 from "../images/allImages/NLR Municipality/NLR Municipality (20).jpg";
import nlrMunicipality21 from "../images/allImages/NLR Municipality/NLR Municipality (21).jpg";
import nlrMunicipality22 from "../images/allImages/NLR Municipality/NLR Municipality (22).jpg";
import nlrMunicipality23 from "../images/allImages/NLR Municipality/NLR Municipality (23).jpg";
import nlrMunicipality24 from "../images/allImages/NLR Municipality/NLR Municipality (24).jpg";
import nlrMunicipality25 from "../images/allImages/NLR Municipality/NLR Municipality (25).jpg";
import nlrMunicipality26 from "../images/allImages/NLR Municipality/NLR Municipality (26).jpg";

//Election Campaign
import electionCampaign1 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (1).jpg";
import electionCampaign2 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (2).jpg";
import electionCampaign3 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (3).jpg";
import electionCampaign4 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (4).jpg";
import electionCampaign5 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (5).jpg";
import electionCampaign6 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (6).jpg";
import electionCampaign7 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (7).jpg";
import electionCampaign8 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (8).jpg";
import electionCampaign9 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (9).jpg";
import electionCampaign10 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (10).jpg";
import electionCampaign11 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (11).jpg";
import electionCampaign12 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (12).jpg";
import electionCampaign13 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (13).jpg";
import electionCampaign14 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (14).jpg";
import electionCampaign15 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (15).jpg";
import electionCampaign16 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (16).jpg";
import electionCampaign17 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (17).jpg";
import electionCampaign18 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (18).jpg";
import electionCampaign19 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (19).jpg";
import electionCampaign20 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (20).jpg";
import electionCampaign21 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (21).jpg";
import electionCampaign22 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (22).jpg";
import electionCampaign23 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (23).jpg";
import electionCampaign24 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (24).jpg";
import electionCampaign25 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (25).jpg";
import electionCampaign26 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (26).jpg";
import electionCampaign27 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (27).jpg";
import electionCampaign28 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (28).jpg";
import electionCampaign29 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (29).jpg";
import electionCampaign30 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (30).jpg";
import electionCampaign31 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (31).jpg";
import electionCampaign32 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (32).jpg";
import electionCampaign33 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (33).jpg";
import electionCampaign34 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (34).jpg";
import electionCampaign35 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (35).jpg";
import electionCampaign36 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (36).jpg";
import electionCampaign37 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (37).jpg";
import electionCampaign38 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (38).jpg";
import electionCampaign39 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (39).jpg";
import electionCampaign40 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (40).jpg";
import electionCampaign41 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (41).jpg";
import electionCampaign42 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (42).jpg";
import electionCampaign43 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (43).jpg";
import electionCampaign44 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (44).jpg";
import electionCampaign45 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (45).jpg";
import electionCampaign46 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (46).jpg";
import electionCampaign47 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (47).jpg";
import electionCampaign48 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (48).jpg";
import electionCampaign49 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (49).jpg";
import electionCampaign50 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (50).jpg";
import electionCampaign51 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (51).jpg";
import electionCampaign52 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (52).jpg";
import electionCampaign53 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (53).jpg";
import electionCampaign54 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (54).jpg";
import electionCampaign55 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (55).jpg";
import electionCampaign56 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (56).jpg";
import electionCampaign57 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (57).jpg";
import electionCampaign58 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (58).jpg";
import electionCampaign59 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (59).jpg";
import electionCampaign60 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (60).jpg";
import electionCampaign61 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (61).jpg";
import electionCampaign62 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (62).jpg";
import electionCampaign63 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (63).jpg";
import electionCampaign64 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (64).jpg";
import electionCampaign65 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (65).jpg";
import electionCampaign66 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (66).jpg";
import electionCampaign67 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (67).jpg";
import electionCampaign68 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (68).jpg";
import electionCampaign69 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (69).jpg";
import electionCampaign70 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (70).jpg";
import electionCampaign71 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (71).jpg";
import electionCampaign72 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (72).jpg";
import electionCampaign73 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (73).jpg";
import electionCampaign74 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (74).jpg";
import electionCampaign75 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (75).jpg";
import electionCampaign76 from "../images/allImages/2019 Election Campaign/2019 Election Campaign (76).jpg";

//Pressmeet
import pressmeet1 from "../images/allImages/Pressmeet/Pressmeet (1).jpg";
import pressmeet2 from "../images/allImages/Pressmeet/Pressmeet (2).jpg";
import pressmeet3 from "../images/allImages/Pressmeet/Pressmeet (3).jpg";
import pressmeet4 from "../images/allImages/Pressmeet/Pressmeet (4).jpg";
import pressmeet5 from "../images/allImages/Pressmeet/Pressmeet (5).jpg";
import pressmeet6 from "../images/allImages/Pressmeet/Pressmeet (6).jpg";
import pressmeet7 from "../images/allImages/Pressmeet/Pressmeet (7).jpg";
import pressmeet8 from "../images/allImages/Pressmeet/Pressmeet (8).jpg";
import pressmeet9 from "../images/allImages/Pressmeet/Pressmeet (9).jpg";
import pressmeet10 from "../images/allImages/Pressmeet/Pressmeet (10).jpg";
import pressmeet11 from "../images/allImages/Pressmeet/Pressmeet (11).jpg";
import pressmeet12 from "../images/allImages/Pressmeet/Pressmeet (12).jpg";
import pressmeet13 from "../images/allImages/Pressmeet/Pressmeet (13).jpg";
import pressmeet14 from "../images/allImages/Pressmeet/Pressmeet (14).jpg";
import pressmeet15 from "../images/allImages/Pressmeet/Pressmeet (15).jpg";
import pressmeet16 from "../images/allImages/Pressmeet/Pressmeet (16).jpg";
import pressmeet17 from "../images/allImages/Pressmeet/Pressmeet (17).jpg";
import pressmeet18 from "../images/allImages/Pressmeet/Pressmeet (18).jpg";
import pressmeet19 from "../images/allImages/Pressmeet/Pressmeet (19).jpg";
import pressmeet20 from "../images/allImages/Pressmeet/Pressmeet (20).jpg";
import pressmeet21 from "../images/allImages/Pressmeet/Pressmeet (21).jpg";
import pressmeet22 from "../images/allImages/Pressmeet/Pressmeet (22).jpg";
import pressmeet23 from "../images/allImages/Pressmeet/Pressmeet (23).jpg";
import pressmeet24 from "../images/allImages/Pressmeet/Pressmeet (24).jpg";
import pressmeet25 from "../images/allImages/Pressmeet/Pressmeet (25).jpg";
import pressmeet26 from "../images/allImages/Pressmeet/Pressmeet (26).jpg";
import pressmeet27 from "../images/allImages/Pressmeet/Pressmeet (27).jpg";
import pressmeet28 from "../images/allImages/Pressmeet/Pressmeet (28).jpg";
import pressmeet29 from "../images/allImages/Pressmeet/Pressmeet (29).jpg";
import pressmeet30 from "../images/allImages/Pressmeet/Pressmeet (30).jpg";
import pressmeet31 from "../images/allImages/Pressmeet/Pressmeet (31).jpg";
import pressmeet32 from "../images/allImages/Pressmeet/Pressmeet (32).jpg";
import pressmeet33 from "../images/allImages/Pressmeet/Pressmeet (33).jpg";
import pressmeet34 from "../images/allImages/Pressmeet/Pressmeet (34).jpg";
import pressmeet35 from "../images/allImages/Pressmeet/Pressmeet (35).jpg";
import pressmeet36 from "../images/allImages/Pressmeet/Pressmeet (36).jpg";
import pressmeet37 from "../images/allImages/Pressmeet/Pressmeet (37).jpg";
import pressmeet38 from "../images/allImages/Pressmeet/Pressmeet (38).jpg";
import pressmeet39 from "../images/allImages/Pressmeet/Pressmeet (39).jpg";
import pressmeet40 from "../images/allImages/Pressmeet/Pressmeet (40).jpg";
import pressmeet41 from "../images/allImages/Pressmeet/Pressmeet (41).jpg";
import pressmeet42 from "../images/allImages/Pressmeet/Pressmeet (42).jpg";
import pressmeet43 from "../images/allImages/Pressmeet/Pressmeet (43).jpg";
import pressmeet44 from "../images/allImages/Pressmeet/Pressmeet (44).jpg";
import pressmeet45 from "../images/allImages/Pressmeet/Pressmeet (45).jpg";
import pressmeet46 from "../images/allImages/Pressmeet/Pressmeet (46).jpg";
import pressmeet47 from "../images/allImages/Pressmeet/Pressmeet (47).jpg";
import pressmeet48 from "../images/allImages/Pressmeet/Pressmeet (48).jpg";
import pressmeet49 from "../images/allImages/Pressmeet/Pressmeet (49).jpg";
import pressmeet50 from "../images/allImages/Pressmeet/Pressmeet (50).jpg";
import pressmeet51 from "../images/allImages/Pressmeet/Pressmeet (51).jpg";
import pressmeet52 from "../images/allImages/Pressmeet/Pressmeet (52).jpg";
import pressmeet53 from "../images/allImages/Pressmeet/Pressmeet (53).jpg";
import pressmeet54 from "../images/allImages/Pressmeet/Pressmeet (54).jpg";
import pressmeet55 from "../images/allImages/Pressmeet/Pressmeet (55).jpg";
import pressmeet56 from "../images/allImages/Pressmeet/Pressmeet (56).jpg";
import pressmeet57 from "../images/allImages/Pressmeet/Pressmeet (57).jpg";
import pressmeet58 from "../images/allImages/Pressmeet/Pressmeet (58).jpg";
import pressmeet59 from "../images/allImages/Pressmeet/Pressmeet (59).jpg";
import pressmeet60 from "../images/allImages/Pressmeet/Pressmeet (60).jpg";
import pressmeet61 from "../images/allImages/Pressmeet/Pressmeet (61).jpg";
import pressmeet62 from "../images/allImages/Pressmeet/Pressmeet (62).jpg";
import pressmeet63 from "../images/allImages/Pressmeet/Pressmeet (63).jpg";
import pressmeet64 from "../images/allImages/Pressmeet/Pressmeet (64).jpg";
import pressmeet65 from "../images/allImages/Pressmeet/Pressmeet (65).jpg";
import pressmeet66 from "../images/allImages/Pressmeet/Pressmeet (66).jpg";
import pressmeet67 from "../images/allImages/Pressmeet/Pressmeet (67).jpg";
import pressmeet68 from "../images/allImages/Pressmeet/Pressmeet (68).jpg";
import pressmeet69 from "../images/allImages/Pressmeet/Pressmeet (69).jpg";
import pressmeet70 from "../images/allImages/Pressmeet/Pressmeet (70).jpg";
import pressmeet71 from "../images/allImages/Pressmeet/Pressmeet (71).jpg";
import pressmeet72 from "../images/allImages/Pressmeet/Pressmeet (72).jpg";
import pressmeet73 from "../images/allImages/Pressmeet/Pressmeet (73).jpg";
import pressmeet74 from "../images/allImages/Pressmeet/Pressmeet (74).jpg";
import pressmeet75 from "../images/allImages/Pressmeet/Pressmeet (75).jpg";
import pressmeet76 from "../images/allImages/Pressmeet/Pressmeet (76).jpg";
import pressmeet77 from "../images/allImages/Pressmeet/Pressmeet (77).jpg";
import pressmeet78 from "../images/allImages/Pressmeet/Pressmeet (78).jpg";
import pressmeet79 from "../images/allImages/Pressmeet/Pressmeet (79).jpg";
import pressmeet80 from "../images/allImages/Pressmeet/Pressmeet (80).jpg";

//Raithu Porubata
import raithuPorubata1 from "../images/allImages/Raithu Porubata/Raithu Porubata (1).jpg";
import raithuPorubata2 from "../images/allImages/Raithu Porubata/Raithu Porubata (2).jpg";
import raithuPorubata3 from "../images/allImages/Raithu Porubata/Raithu Porubata (3).jpg";
import raithuPorubata4 from "../images/allImages/Raithu Porubata/Raithu Porubata (4).jpg";
import raithuPorubata5 from "../images/allImages/Raithu Porubata/Raithu Porubata (5).jpg";
import raithuPorubata6 from "../images/allImages/Raithu Porubata/Raithu Porubata (6).jpg";
import raithuPorubata7 from "../images/allImages/Raithu Porubata/Raithu Porubata (7).jpg";
import raithuPorubata8 from "../images/allImages/Raithu Porubata/Raithu Porubata (8).jpg";
import raithuPorubata9 from "../images/allImages/Raithu Porubata/Raithu Porubata (9).jpg";
import raithuPorubata10 from "../images/allImages/Raithu Porubata/Raithu Porubata (10).jpg";
import raithuPorubata11 from "../images/allImages/Raithu Porubata/Raithu Porubata (11).jpg";
import raithuPorubata12 from "../images/allImages/Raithu Porubata/Raithu Porubata (12).jpg";
import raithuPorubata13 from "../images/allImages/Raithu Porubata/Raithu Porubata (13).jpg";
import raithuPorubata14 from "../images/allImages/Raithu Porubata/Raithu Porubata (14).jpg";
import raithuPorubata15 from "../images/allImages/Raithu Porubata/Raithu Porubata (15).jpg";
import raithuPorubata16 from "../images/allImages/Raithu Porubata/Raithu Porubata (16).jpg";
import raithuPorubata17 from "../images/allImages/Raithu Porubata/Raithu Porubata (17).jpg";
import raithuPorubata18 from "../images/allImages/Raithu Porubata/Raithu Porubata (18).jpg";
import raithuPorubata19 from "../images/allImages/Raithu Porubata/Raithu Porubata (19).jpg";
import raithuPorubata20 from "../images/allImages/Raithu Porubata/Raithu Porubata (20).jpg";

//Special Status
import specialStatus1 from "../images/allImages/Special Status/Special Status (1).jpg";
import specialStatus2 from "../images/allImages/Special Status/Special Status (2).jpg";
import specialStatus3 from "../images/allImages/Special Status/Special Status (3).jpg";
import specialStatus4 from "../images/allImages/Special Status/Special Status (4).jpg";
import specialStatus5 from "../images/allImages/Special Status/Special Status (5).jpg";
import specialStatus6 from "../images/allImages/Special Status/Special Status (6).jpg";
import specialStatus7 from "../images/allImages/Special Status/Special Status (7).jpg";
import specialStatus8 from "../images/allImages/Special Status/Special Status (8).jpg";
import specialStatus9 from "../images/allImages/Special Status/Special Status (9).jpg";
import specialStatus10 from "../images/allImages/Special Status/Special Status (10).jpg";
import specialStatus11 from "../images/allImages/Special Status/Special Status (11).jpg";
import specialStatus12 from "../images/allImages/Special Status/Special Status (12).jpg";
import specialStatus13 from "../images/allImages/Special Status/Special Status (13).jpg";
import specialStatus14 from "../images/allImages/Special Status/Special Status (14).jpg";
import specialStatus15 from "../images/allImages/Special Status/Special Status (15).jpg";
import specialStatus16 from "../images/allImages/Special Status/Special Status (16).jpg";
import specialStatus17 from "../images/allImages/Special Status/Special Status (17).jpg";
import specialStatus18 from "../images/allImages/Special Status/Special Status (18).jpg";
import specialStatus19 from "../images/allImages/Special Status/Special Status (19).jpg";
import specialStatus20 from "../images/allImages/Special Status/Special Status (20).jpg";
import specialStatus21 from "../images/allImages/Special Status/Special Status (21).jpg";
import specialStatus22 from "../images/allImages/Special Status/Special Status (22).jpg";
import specialStatus23 from "../images/allImages/Special Status/Special Status (23).jpg";
import specialStatus24 from "../images/allImages/Special Status/Special Status (24).jpg";
import specialStatus25 from "../images/allImages/Special Status/Special Status (25).jpg";
import specialStatus26 from "../images/allImages/Special Status/Special Status (26).jpg";
import specialStatus27 from "../images/allImages/Special Status/Special Status (27).jpg";
import specialStatus28 from "../images/allImages/Special Status/Special Status (28).jpg";
import specialStatus29 from "../images/allImages/Special Status/Special Status (29).jpg";
import specialStatus30 from "../images/allImages/Special Status/Special Status (30).jpg";
import specialStatus31 from "../images/allImages/Special Status/Special Status (31).jpg";
import specialStatus32 from "../images/allImages/Special Status/Special Status (32).jpg";
import specialStatus33 from "../images/allImages/Special Status/Special Status (33).jpg";
import specialStatus34 from "../images/allImages/Special Status/Special Status (34).jpg";
import specialStatus35 from "../images/allImages/Special Status/Special Status (35).jpg";
import specialStatus36 from "../images/allImages/Special Status/Special Status (36).jpg";
import specialStatus37 from "../images/allImages/Special Status/Special Status (37).jpg";
import specialStatus38 from "../images/allImages/Special Status/Special Status (38).jpg";
import specialStatus39 from "../images/allImages/Special Status/Special Status (39).jpg";
import specialStatus40 from "../images/allImages/Special Status/Special Status (40).jpg";
import specialStatus41 from "../images/allImages/Special Status/Special Status (41).jpg";
import specialStatus42 from "../images/allImages/Special Status/Special Status (42).jpg";
import specialStatus43 from "../images/allImages/Special Status/Special Status (43).jpg";
import specialStatus44 from "../images/allImages/Special Status/Special Status (44).jpg";
import specialStatus45 from "../images/allImages/Special Status/Special Status (45).jpg";
import specialStatus46 from "../images/allImages/Special Status/Special Status (46).jpg";
import specialStatus47 from "../images/allImages/Special Status/Special Status (47).jpg";
import specialStatus48 from "../images/allImages/Special Status/Special Status (48).jpg";
import specialStatus49 from "../images/allImages/Special Status/Special Status (49).jpg";
import specialStatus50 from "../images/allImages/Special Status/Special Status (50).jpg";
import specialStatus51 from "../images/allImages/Special Status/Special Status (51).jpg";
import specialStatus52 from "../images/allImages/Special Status/Special Status (52).jpg";
import specialStatus53 from "../images/allImages/Special Status/Special Status (53).jpg";
import specialStatus54 from "../images/allImages/Special Status/Special Status (54).jpg";
import specialStatus55 from "../images/allImages/Special Status/Special Status (55).jpg";
import specialStatus56 from "../images/allImages/Special Status/Special Status (56).jpg";
import specialStatus57 from "../images/allImages/Special Status/Special Status (57).jpg";
import specialStatus58 from "../images/allImages/Special Status/Special Status (58).jpg";
import specialStatus59 from "../images/allImages/Special Status/Special Status (59).jpg";
import specialStatus60 from "../images/allImages/Special Status/Special Status (60).jpg";
import specialStatus61 from "../images/allImages/Special Status/Special Status (61).jpg";
import specialStatus62 from "../images/allImages/Special Status/Special Status (62).jpg";
import specialStatus63 from "../images/allImages/Special Status/Special Status (63).jpg";
import specialStatus64 from "../images/allImages/Special Status/Special Status (64).jpg";
import specialStatus65 from "../images/allImages/Special Status/Special Status (65).jpg";
import specialStatus66 from "../images/allImages/Special Status/Special Status (66).jpg";
import specialStatus67 from "../images/allImages/Special Status/Special Status (67).jpg";
import specialStatus68 from "../images/allImages/Special Status/Special Status (68).jpg";
import specialStatus69 from "../images/allImages/Special Status/Special Status (69).jpg";
import specialStatus70 from "../images/allImages/Special Status/Special Status (70).jpg";
import specialStatus71 from "../images/allImages/Special Status/Special Status (71).jpg";
import specialStatus72 from "../images/allImages/Special Status/Special Status (72).jpg";
import specialStatus73 from "../images/allImages/Special Status/Special Status (73).jpg";
import specialStatus74 from "../images/allImages/Special Status/Special Status (74).jpg";
import specialStatus75 from "../images/allImages/Special Status/Special Status (75).jpg";
import specialStatus76 from "../images/allImages/Special Status/Special Status (76).jpg";
import specialStatus77 from "../images/allImages/Special Status/Special Status (77).jpg";
import specialStatus78 from "../images/allImages/Special Status/Special Status (78).jpg";
import specialStatus79 from "../images/allImages/Special Status/Special Status (79).jpg";
import specialStatus80 from "../images/allImages/Special Status/Special Status (80).jpg";
import specialStatus81 from "../images/allImages/Special Status/Special Status (81).jpg";
import specialStatus82 from "../images/allImages/Special Status/Special Status (82).jpg";
import specialStatus83 from "../images/allImages/Special Status/Special Status (83).jpg";
import specialStatus84 from "../images/allImages/Special Status/Special Status (84).jpg";
import specialStatus85 from "../images/allImages/Special Status/Special Status (85).jpg";
import specialStatus86 from "../images/allImages/Special Status/Special Status (86).jpg";

//TPT Bi Election
import tptBiElection1 from "../images/allImages/TPT Bi Election/TPT Bi Election (1).jpg";
import tptBiElection2 from "../images/allImages/TPT Bi Election/TPT Bi Election (2).jpg";
import tptBiElection3 from "../images/allImages/TPT Bi Election/TPT Bi Election (3).jpg";
import tptBiElection4 from "../images/allImages/TPT Bi Election/TPT Bi Election (4).jpg";
import tptBiElection5 from "../images/allImages/TPT Bi Election/TPT Bi Election (5).jpg";
import tptBiElection6 from "../images/allImages/TPT Bi Election/TPT Bi Election (6).jpg";
import tptBiElection7 from "../images/allImages/TPT Bi Election/TPT Bi Election (7).jpg";
import tptBiElection8 from "../images/allImages/TPT Bi Election/TPT Bi Election (8).jpg";
import tptBiElection9 from "../images/allImages/TPT Bi Election/TPT Bi Election (9).jpg";
import tptBiElection10 from "../images/allImages/TPT Bi Election/TPT Bi Election (10).jpg";
import tptBiElection11 from "../images/allImages/TPT Bi Election/TPT Bi Election (11).jpg";
import tptBiElection12 from "../images/allImages/TPT Bi Election/TPT Bi Election (12).jpg";
import tptBiElection13 from "../images/allImages/TPT Bi Election/TPT Bi Election (13).jpg";
import tptBiElection14 from "../images/allImages/TPT Bi Election/TPT Bi Election (14).jpg";
import tptBiElection15 from "../images/allImages/TPT Bi Election/TPT Bi Election (15).jpg";
import tptBiElection16 from "../images/allImages/TPT Bi Election/TPT Bi Election (16).jpg";
import tptBiElection17 from "../images/allImages/TPT Bi Election/TPT Bi Election (17).jpg";
import tptBiElection18 from "../images/allImages/TPT Bi Election/TPT Bi Election (18).jpg";
import tptBiElection19 from "../images/allImages/TPT Bi Election/TPT Bi Election (19).jpg";
import tptBiElection20 from "../images/allImages/TPT Bi Election/TPT Bi Election (20).jpg";
import tptBiElection21 from "../images/allImages/TPT Bi Election/TPT Bi Election (21).jpg";
import tptBiElection22 from "../images/allImages/TPT Bi Election/TPT Bi Election (22).jpg";
import tptBiElection23 from "../images/allImages/TPT Bi Election/TPT Bi Election (23).jpg";
import tptBiElection24 from "../images/allImages/TPT Bi Election/TPT Bi Election (24).jpg";
import tptBiElection25 from "../images/allImages/TPT Bi Election/TPT Bi Election (25).jpg";
import tptBiElection26 from "../images/allImages/TPT Bi Election/TPT Bi Election (26).jpg";
import tptBiElection27 from "../images/allImages/TPT Bi Election/TPT Bi Election (27).jpg";
import tptBiElection28 from "../images/allImages/TPT Bi Election/TPT Bi Election (28).jpg";
import tptBiElection29 from "../images/allImages/TPT Bi Election/TPT Bi Election (29).jpg";
import tptBiElection30 from "../images/allImages/TPT Bi Election/TPT Bi Election (30).jpg";
import tptBiElection31 from "../images/allImages/TPT Bi Election/TPT Bi Election (31).jpg";
import tptBiElection32 from "../images/allImages/TPT Bi Election/TPT Bi Election (32).jpg";
import tptBiElection33 from "../images/allImages/TPT Bi Election/TPT Bi Election (33).jpg";
import tptBiElection34 from "../images/allImages/TPT Bi Election/TPT Bi Election (34).jpg";
import tptBiElection35 from "../images/allImages/TPT Bi Election/TPT Bi Election (35).jpg";
import tptBiElection36 from "../images/allImages/TPT Bi Election/TPT Bi Election (36).jpg";
import tptBiElection37 from "../images/allImages/TPT Bi Election/TPT Bi Election (37).jpg";
import tptBiElection38 from "../images/allImages/TPT Bi Election/TPT Bi Election (38).jpg";
import tptBiElection39 from "../images/allImages/TPT Bi Election/TPT Bi Election (39).jpg";
import tptBiElection40 from "../images/allImages/TPT Bi Election/TPT Bi Election (40).jpg";
import tptBiElection41 from "../images/allImages/TPT Bi Election/TPT Bi Election (41).jpg";
import tptBiElection42 from "../images/allImages/TPT Bi Election/TPT Bi Election (42).jpg";

//Yuvagalam
import yuvagalam1 from "../images/allImages/Yuvagalam/Yuvagalam (1).jpg";
import yuvagalam2 from "../images/allImages/Yuvagalam/Yuvagalam (2).jpg";
import yuvagalam3 from "../images/allImages/Yuvagalam/Yuvagalam (3).jpg";
import yuvagalam4 from "../images/allImages/Yuvagalam/Yuvagalam (4).jpg";
import yuvagalam5 from "../images/allImages/Yuvagalam/Yuvagalam (5).jpg";
import yuvagalam6 from "../images/allImages/Yuvagalam/Yuvagalam (6).jpg";
import yuvagalam7 from "../images/allImages/Yuvagalam/Yuvagalam (7).jpg";
import yuvagalam8 from "../images/allImages/Yuvagalam/Yuvagalam (8).jpg";
import yuvagalam9 from "../images/allImages/Yuvagalam/Yuvagalam (9).jpg";
import yuvagalam10 from "../images/allImages/Yuvagalam/Yuvagalam (10).jpg";
import yuvagalam11 from "../images/allImages/Yuvagalam/Yuvagalam (11).jpg";
import yuvagalam12 from "../images/allImages/Yuvagalam/Yuvagalam (12).jpg";
import yuvagalam13 from "../images/allImages/Yuvagalam/Yuvagalam (13).jpg";
import yuvagalam14 from "../images/allImages/Yuvagalam/Yuvagalam (14).jpg";
import yuvagalam15 from "../images/allImages/Yuvagalam/Yuvagalam (15).jpg";
import yuvagalam16 from "../images/allImages/Yuvagalam/Yuvagalam (16).jpg";
import yuvagalam17 from "../images/allImages/Yuvagalam/Yuvagalam (17).jpg";
import yuvagalam18 from "../images/allImages/Yuvagalam/Yuvagalam (18).jpg";
import yuvagalam19 from "../images/allImages/Yuvagalam/Yuvagalam (19).jpg";
import yuvagalam20 from "../images/allImages/Yuvagalam/Yuvagalam (20).jpg";
import yuvagalam21 from "../images/allImages/Yuvagalam/Yuvagalam (21).jpg";
import yuvagalam22 from "../images/allImages/Yuvagalam/Yuvagalam (22).jpg";
import yuvagalam23 from "../images/allImages/Yuvagalam/Yuvagalam (23).jpg";
import yuvagalam24 from "../images/allImages/Yuvagalam/Yuvagalam (24).jpg";
import yuvagalam25 from "../images/allImages/Yuvagalam/Yuvagalam (25).jpg";
import yuvagalam26 from "../images/allImages/Yuvagalam/Yuvagalam (26).jpg";
import yuvagalam27 from "../images/allImages/Yuvagalam/Yuvagalam (27).jpg";
import yuvagalam28 from "../images/allImages/Yuvagalam/Yuvagalam (28).jpg";
import yuvagalam29 from "../images/allImages/Yuvagalam/Yuvagalam (29).jpg";
import yuvagalam30 from "../images/allImages/Yuvagalam/Yuvagalam (30).jpg";
import yuvagalam31 from "../images/allImages/Yuvagalam/Yuvagalam (31).jpg";
import yuvagalam32 from "../images/allImages/Yuvagalam/Yuvagalam (32).jpg";
import yuvagalam33 from "../images/allImages/Yuvagalam/Yuvagalam (33).jpg";
import yuvagalam34 from "../images/allImages/Yuvagalam/Yuvagalam (34).jpg";
import yuvagalam35 from "../images/allImages/Yuvagalam/Yuvagalam (35).jpg";
import yuvagalam36 from "../images/allImages/Yuvagalam/Yuvagalam (36).jpg";
import yuvagalam37 from "../images/allImages/Yuvagalam/Yuvagalam (37).jpg";
import yuvagalam38 from "../images/allImages/Yuvagalam/Yuvagalam (38).jpg";
import yuvagalam39 from "../images/allImages/Yuvagalam/Yuvagalam (39).jpg";
import yuvagalam40 from "../images/allImages/Yuvagalam/Yuvagalam (40).jpg";
import yuvagalam41 from "../images/allImages/Yuvagalam/Yuvagalam (41).jpg";
import yuvagalam42 from "../images/allImages/Yuvagalam/Yuvagalam (42).jpg";
import yuvagalam43 from "../images/allImages/Yuvagalam/Yuvagalam (43).jpg";
import yuvagalam44 from "../images/allImages/Yuvagalam/Yuvagalam (44).jpg";
import yuvagalam45 from "../images/allImages/Yuvagalam/Yuvagalam (45).jpg";
import yuvagalam46 from "../images/allImages/Yuvagalam/Yuvagalam (46).jpg";
import yuvagalam47 from "../images/allImages/Yuvagalam/Yuvagalam (47).jpg";

//Yuvanestam
import yuvanestam1 from "../images/allImages/Yuvanestam/Yuvanestam (1).jpg";
import yuvanestam2 from "../images/allImages/Yuvanestam/Yuvanestam (2).jpg";
import yuvanestam3 from "../images/allImages/Yuvanestam/Yuvanestam (3).jpg";
import yuvanestam4 from "../images/allImages/Yuvanestam/Yuvanestam (4).jpg";
import yuvanestam5 from "../images/allImages/Yuvanestam/Yuvanestam (5).jpg";
import yuvanestam6 from "../images/allImages/Yuvanestam/Yuvanestam (6).jpg";
import yuvanestam7 from "../images/allImages/Yuvanestam/Yuvanestam (7).jpg";
import yuvanestam8 from "../images/allImages/Yuvanestam/Yuvanestam (8).jpg";
import yuvanestam9 from "../images/allImages/Yuvanestam/Yuvanestam (9).jpg";
import yuvanestam10 from "../images/allImages/Yuvanestam/Yuvanestam (10).jpg";
import yuvanestam11 from "../images/allImages/Yuvanestam/Yuvanestam (11).jpg";
import yuvanestam12 from "../images/allImages/Yuvanestam/Yuvanestam (12).jpg";
import yuvanestam13 from "../images/allImages/Yuvanestam/Yuvanestam (13).jpg";
import yuvanestam14 from "../images/allImages/Yuvanestam/Yuvanestam (14).jpg";
import yuvanestam15 from "../images/allImages/Yuvanestam/Yuvanestam (15).jpg";
import yuvanestam16 from "../images/allImages/Yuvanestam/Yuvanestam (16).jpg";
import yuvanestam17 from "../images/allImages/Yuvanestam/Yuvanestam (17).jpg";
import yuvanestam18 from "../images/allImages/Yuvanestam/Yuvanestam (18).jpg";
import yuvanestam19 from "../images/allImages/Yuvanestam/Yuvanestam (19).jpg";
import yuvanestam20 from "../images/allImages/Yuvanestam/Yuvanestam (20).jpg";
import yuvanestam21 from "../images/allImages/Yuvanestam/Yuvanestam (21).jpg";
import yuvanestam22 from "../images/allImages/Yuvanestam/Yuvanestam (22).jpg";
import yuvanestam23 from "../images/allImages/Yuvanestam/Yuvanestam (23).jpg";
import yuvanestam24 from "../images/allImages/Yuvanestam/Yuvanestam (24).jpg";
import yuvanestam25 from "../images/allImages/Yuvanestam/Yuvanestam (25).jpg";
import yuvanestam26 from "../images/allImages/Yuvanestam/Yuvanestam (26).jpg";
import yuvanestam27 from "../images/allImages/Yuvanestam/Yuvanestam (27).jpg";
import yuvanestam28 from "../images/allImages/Yuvanestam/Yuvanestam (28).jpg";
import yuvanestam29 from "../images/allImages/Yuvanestam/Yuvanestam (29).jpg";
import yuvanestam30 from "../images/allImages/Yuvanestam/Yuvanestam (30).jpg";
import yuvanestam31 from "../images/allImages/Yuvanestam/Yuvanestam (31).jpg";
import yuvanestam32 from "../images/allImages/Yuvanestam/Yuvanestam (32).jpg";
import yuvanestam33 from "../images/allImages/Yuvanestam/Yuvanestam (33).jpg";
import yuvanestam34 from "../images/allImages/Yuvanestam/Yuvanestam (34).jpg";
import yuvanestam35 from "../images/allImages/Yuvanestam/Yuvanestam (35).jpg";
import yuvanestam36 from "../images/allImages/Yuvanestam/Yuvanestam (36).jpg";
import yuvanestam37 from "../images/allImages/Yuvanestam/Yuvanestam (37).jpg";
import yuvanestam38 from "../images/allImages/Yuvanestam/Yuvanestam (38).jpg";
import yuvanestam39 from "../images/allImages/Yuvanestam/Yuvanestam (39).jpg";
import yuvanestam40 from "../images/allImages/Yuvanestam/Yuvanestam (40).jpg";
import yuvanestam41 from "../images/allImages/Yuvanestam/Yuvanestam (41).jpg";
import yuvanestam42 from "../images/allImages/Yuvanestam/Yuvanestam (42).jpg";
import yuvanestam43 from "../images/allImages/Yuvanestam/Yuvanestam (43).jpg";
import yuvanestam44 from "../images/allImages/Yuvanestam/Yuvanestam (44).jpg";
import yuvanestam45 from "../images/allImages/Yuvanestam/Yuvanestam (45).jpg";
import yuvanestam46 from "../images/allImages/Yuvanestam/Yuvanestam (46).jpg";
import yuvanestam47 from "../images/allImages/Yuvanestam/Yuvanestam (47).jpg";
import yuvanestam48 from "../images/allImages/Yuvanestam/Yuvanestam (48).jpg";
import yuvanestam49 from "../images/allImages/Yuvanestam/Yuvanestam (49).jpg";
import yuvanestam50 from "../images/allImages/Yuvanestam/Yuvanestam (50).jpg";
import yuvanestam51 from "../images/allImages/Yuvanestam/Yuvanestam (51).jpg";
import yuvanestam52 from "../images/allImages/Yuvanestam/Yuvanestam (52).jpg";
import yuvanestam53 from "../images/allImages/Yuvanestam/Yuvanestam (53).jpg";
import yuvanestam54 from "../images/allImages/Yuvanestam/Yuvanestam (54).jpg";
import yuvanestam55 from "../images/allImages/Yuvanestam/Yuvanestam (55).jpg";
import yuvanestam56 from "../images/allImages/Yuvanestam/Yuvanestam (56).jpg";
import yuvanestam57 from "../images/allImages/Yuvanestam/Yuvanestam (57).jpg";
import yuvanestam58 from "../images/allImages/Yuvanestam/Yuvanestam (58).jpg";
import yuvanestam59 from "../images/allImages/Yuvanestam/Yuvanestam (59).jpg";
import yuvanestam60 from "../images/allImages/Yuvanestam/Yuvanestam (60).jpg";
import yuvanestam61 from "../images/allImages/Yuvanestam/Yuvanestam (61).jpg";
import yuvanestam62 from "../images/allImages/Yuvanestam/Yuvanestam (62).jpg";
import yuvanestam63 from "../images/allImages/Yuvanestam/Yuvanestam (63).jpg";
import yuvanestam64 from "../images/allImages/Yuvanestam/Yuvanestam (64).jpg";
import yuvanestam65 from "../images/allImages/Yuvanestam/Yuvanestam (65).jpg";
import yuvanestam66 from "../images/allImages/Yuvanestam/Yuvanestam (66).jpg";
import yuvanestam67 from "../images/allImages/Yuvanestam/Yuvanestam (67).jpg";
import yuvanestam68 from "../images/allImages/Yuvanestam/Yuvanestam (68).jpg";
import yuvanestam69 from "../images/allImages/Yuvanestam/Yuvanestam (69).jpg";
import yuvanestam70 from "../images/allImages/Yuvanestam/Yuvanestam (70).jpg";
import yuvanestam71 from "../images/allImages/Yuvanestam/Yuvanestam (71).jpg";
import yuvanestam72 from "../images/allImages/Yuvanestam/Yuvanestam (72).jpg";
import yuvanestam73 from "../images/allImages/Yuvanestam/Yuvanestam (73).jpg";
import yuvanestam74 from "../images/allImages/Yuvanestam/Yuvanestam (74).jpg";
import yuvanestam75 from "../images/allImages/Yuvanestam/Yuvanestam (75).jpg";
import yuvanestam76 from "../images/allImages/Yuvanestam/Yuvanestam (76).jpg";
import yuvanestam77 from "../images/allImages/Yuvanestam/Yuvanestam (77).jpg";
import yuvanestam78 from "../images/allImages/Yuvanestam/Yuvanestam (78).jpg";
import yuvanestam79 from "../images/allImages/Yuvanestam/Yuvanestam (79).jpg";
import yuvanestam80 from "../images/allImages/Yuvanestam/Yuvanestam (80).jpg";
import yuvanestam81 from "../images/allImages/Yuvanestam/Yuvanestam (81).jpg";
import yuvanestam82 from "../images/allImages/Yuvanestam/Yuvanestam (82).jpg";
import yuvanestam83 from "../images/allImages/Yuvanestam/Yuvanestam (83).jpg";
import yuvanestam84 from "../images/allImages/Yuvanestam/Yuvanestam (84).jpg";
import yuvanestam85 from "../images/allImages/Yuvanestam/Yuvanestam (85).jpg";
import yuvanestam86 from "../images/allImages/Yuvanestam/Yuvanestam (86).jpg";
import yuvanestam87 from "../images/allImages/Yuvanestam/Yuvanestam (87).jpg";
import yuvanestam88 from "../images/allImages/Yuvanestam/Yuvanestam (88).jpg";
import yuvanestam89 from "../images/allImages/Yuvanestam/Yuvanestam (89).jpg";
import yuvanestam90 from "../images/allImages/Yuvanestam/Yuvanestam (90).jpg";
import yuvanestam91 from "../images/allImages/Yuvanestam/Yuvanestam (91).jpg";
import yuvanestam92 from "../images/allImages/Yuvanestam/Yuvanestam (92).jpg";
import yuvanestam93 from "../images/allImages/Yuvanestam/Yuvanestam (93).jpg";
import yuvanestam94 from "../images/allImages/Yuvanestam/Yuvanestam (94).jpg";
import yuvanestam95 from "../images/allImages/Yuvanestam/Yuvanestam (95).jpg";
import yuvanestam96 from "../images/allImages/Yuvanestam/Yuvanestam (96).jpg";
import yuvanestam97 from "../images/allImages/Yuvanestam/Yuvanestam (97).jpg";
import yuvanestam98 from "../images/allImages/Yuvanestam/Yuvanestam (98).jpg";
import yuvanestam99 from "../images/allImages/Yuvanestam/Yuvanestam (99).jpg";
import yuvanestam100 from "../images/allImages/Yuvanestam/Yuvanestam (100).jpg";
import yuvanestam101 from "../images/allImages/Yuvanestam/Yuvanestam (101).jpg";
import yuvanestam102 from "../images/allImages/Yuvanestam/Yuvanestam (102).jpg";
import yuvanestam103 from "../images/allImages/Yuvanestam/Yuvanestam (103).jpg";
import yuvanestam104 from "../images/allImages/Yuvanestam/Yuvanestam (104).jpg";
import yuvanestam105 from "../images/allImages/Yuvanestam/Yuvanestam (105).jpg";
import yuvanestam106 from "../images/allImages/Yuvanestam/Yuvanestam (106).jpg";
import yuvanestam107 from "../images/allImages/Yuvanestam/Yuvanestam (107).jpg";
import yuvanestam108 from "../images/allImages/Yuvanestam/Yuvanestam (108).jpg";
import yuvanestam109 from "../images/allImages/Yuvanestam/Yuvanestam (109).jpg";
import yuvanestam110 from "../images/allImages/Yuvanestam/Yuvanestam (110).jpg";
import yuvanestam111 from "../images/allImages/Yuvanestam/Yuvanestam (111).jpg";
import yuvanestam112 from "../images/allImages/Yuvanestam/Yuvanestam (112).jpg";
import yuvanestam113 from "../images/allImages/Yuvanestam/Yuvanestam (113).jpg";
import yuvanestam114 from "../images/allImages/Yuvanestam/Yuvanestam (114).jpg";
import yuvanestam115 from "../images/allImages/Yuvanestam/Yuvanestam (115).jpg";
import yuvanestam116 from "../images/allImages/Yuvanestam/Yuvanestam (116).jpg";
import yuvanestam117 from "../images/allImages/Yuvanestam/Yuvanestam (117).jpg";
import yuvanestam118 from "../images/allImages/Yuvanestam/Yuvanestam (118).jpg";
import yuvanestam119 from "../images/allImages/Yuvanestam/Yuvanestam (119).jpg";
import yuvanestam120 from "../images/allImages/Yuvanestam/Yuvanestam (120).jpg";
import yuvanestam121 from "../images/allImages/Yuvanestam/Yuvanestam (121).jpg";
import yuvanestam122 from "../images/allImages/Yuvanestam/Yuvanestam (122).jpg";

//Community  Section

//ABDMS 1st Anniversary
import abdms1stAnniversary1 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (1).jpg";
import abdms1stAnniversary2 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (2).jpg";
import abdms1stAnniversary3 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (3).jpg";
import abdms1stAnniversary4 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (4).jpg";
import abdms1stAnniversary5 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (5).jpg";
import abdms1stAnniversary6 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (6).jpg";
import abdms1stAnniversary7 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (7).jpg";
import abdms1stAnniversary8 from "../images/communityImgs/ABDMS 1st Anniversary/ABDMS 1st Anniversary (8).jpg";

//Collector Office NLR
import collectorOfficeNLR1 from "../images/communityImgs/Collector Office NLR/Collector Office NLR (1).jpg";
import collectorOfficeNLR2 from "../images/communityImgs/Collector Office NLR/Collector Office NLR (2).jpg";
import collectorOfficeNLR3 from "../images/communityImgs/Collector Office NLR/Collector Office NLR (3).jpg";

//Drycleaning wash Gtr Opening
import drycleaningwashGtrOpening1 from "../images/communityImgs/Drycleaning wash Gtr Opening/Drycleaning wash Gtr Opening (1).jpg";
import drycleaningwashGtrOpening2 from "../images/communityImgs/Drycleaning wash Gtr Opening/Drycleaning wash Gtr Opening (2).jpg";
import drycleaningwashGtrOpening3 from "../images/communityImgs/Drycleaning wash Gtr Opening/Drycleaning wash Gtr Opening (3).jpg";

//Guntur_Books Distribution
import gunturBooksDistribution1 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (1).jpg";
import gunturBooksDistribution2 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (2).jpg";
import gunturBooksDistribution3 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (3).jpg";
import gunturBooksDistribution4 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (4).jpg";
import gunturBooksDistribution5 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (5).jpg";
import gunturBooksDistribution6 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (6).jpg";
import gunturBooksDistribution7 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (7).jpg";
import gunturBooksDistribution8 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (8).jpg";
import gunturBooksDistribution9 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (9).jpg";
import gunturBooksDistribution10 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (10).jpg";
import gunturBooksDistribution11 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (11).jpg";
import gunturBooksDistribution12 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (12).jpg";
import gunturBooksDistribution13 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (13).jpg";
import gunturBooksDistribution14 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (14).jpg";
import gunturBooksDistribution15 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (15).jpg";
import gunturBooksDistribution16 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (16).jpg";
import gunturBooksDistribution17 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (17).jpg";
import gunturBooksDistribution18 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (18).jpg";
import gunturBooksDistribution19 from "../images/communityImgs/Guntur_Books Distribution/Guntur_Books Distribution (19).jpg";

//Helping Community

import helpingCommunity1 from "../images/communityImgs/Helping Community/Helping Community (1).jpg";
import helpingCommunity2 from "../images/communityImgs/Helping Community/Helping Community (2).jpg";
import helpingCommunity3 from "../images/communityImgs/Helping Community/Helping Community (3).jpg";

//Ilamma Vardhanthi
import ilammaVardhanthi1 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (1).jpg";
import ilammaVardhanthi2 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (2).jpg";
import ilammaVardhanthi3 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (3).jpg";
import ilammaVardhanthi4 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (4).jpg";
import ilammaVardhanthi5 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (5).jpg";
import ilammaVardhanthi6 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (6).jpg";
import ilammaVardhanthi7 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (7).jpg";
import ilammaVardhanthi8 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (8).jpg";
import ilammaVardhanthi9 from "../images/communityImgs/Ilamma Vardhanthi/Ilamma Vardhanthi (9).jpg";

//Kavali Team
import kavaliTeam1 from "../images/communityImgs/Kavali Team/Kavali Team (1).jpeg";
import kavaliTeam2 from "../images/communityImgs/Kavali Team/Kavali Team (2).jpg";
import kavaliTeam3 from "../images/communityImgs/Kavali Team/Kavali Team (3).jpg";
import kavaliTeam4 from "../images/communityImgs/Kavali Team/Kavali Team (4).jpg";
import kavaliTeam5 from "../images/communityImgs/Kavali Team/Kavali Team (5).jpg";
import kavaliTeam6 from "../images/communityImgs/Kavali Team/Kavali Team (6).jpg";
import kavaliTeam7 from "../images/communityImgs/Kavali Team/Kavali Team (7).jpg";
import kavaliTeam8 from "../images/communityImgs/Kavali Team/Kavali Team (8).jpg";
import kavaliTeam9 from "../images/communityImgs/Kavali Team/Kavali Team (9).jpg";
import kavaliTeam10 from "../images/communityImgs/Kavali Team/Kavali Team (10).jpg";
import kavaliTeam11 from "../images/communityImgs/Kavali Team/Kavali Team (11).jpeg";

//NLR_Political Apprictiations
import nlrPoliticalApprictiations1 from "../images/communityImgs/NLR_Political Apprictiations/NLR_Political Apprictiations (1).jpg";
import nlrPoliticalApprictiations2 from "../images/communityImgs/NLR_Political Apprictiations/NLR_Political Apprictiations (2).jpg";
import nlrPoliticalApprictiations3 from "../images/communityImgs/NLR_Political Apprictiations/NLR_Political Apprictiations (3).jpg";
import nlrPoliticalApprictiations4 from "../images/communityImgs/NLR_Political Apprictiations/NLR_Political Apprictiations (4).jpg";
import nlrPoliticalApprictiations5 from "../images/communityImgs/NLR_Political Apprictiations/NLR_Political Apprictiations (5).jpg";

//Round Table_RVS_Guntur
import roundTable_RVS_Guntur1 from "../images/communityImgs/Round Table_RVS_Guntur/Round Table_RVS_Guntur (1).jpg";
import roundTable_RVS_Guntur2 from "../images/communityImgs/Round Table_RVS_Guntur/Round Table_RVS_Guntur (2).jpg";
import roundTable_RVS_Guntur3 from "../images/communityImgs/Round Table_RVS_Guntur/Round Table_RVS_Guntur (3).jpg";
import roundTable_RVS_Guntur4 from "../images/communityImgs/Round Table_RVS_Guntur/Round Table_RVS_Guntur (4).jpg";

//Round Table_RVS_Vijayawada
import roundTable_RVS_Vijayawada1 from "../images/communityImgs/Round Table_RVS_Vijayawada/Round Table_RVS_Vijayawada (1).jpg";
import roundTable_RVS_Vijayawada2 from "../images/communityImgs/Round Table_RVS_Vijayawada/Round Table_RVS_Vijayawada (2).jpg";
import roundTable_RVS_Vijayawada3 from "../images/communityImgs/Round Table_RVS_Vijayawada/Round Table_RVS_Vijayawada (3).jpg";

//Round Table_Tirupati
import roundTable_Tirupati1 from "../images/communityImgs/Round Table_Tirupati/Round Table_Tirupati (1).jpg";
import roundTable_Tirupati2 from "../images/communityImgs/Round Table_Tirupati/Round Table_Tirupati (2).jpg";
import roundTable_Tirupati3 from "../images/communityImgs/Round Table_Tirupati/Round Table_Tirupati (3).jpg";
import roundTable_Tirupati4 from "../images/communityImgs/Round Table_Tirupati/Round Table_Tirupati (4).jpg";

//SSC 2021-2022
import sss2021_20221 from "../images/communityImgs/SSC 2021-2022/SSC 2021-2022 (1).jpg";
import sss2021_20222 from "../images/communityImgs/SSC 2021-2022/SSC 2021-2022 (2).jpg";
import sss2021_20223 from "../images/communityImgs/SSC 2021-2022/SSC 2021-2022 (3).jpg";
import sss2021_20224 from "../images/communityImgs/SSC 2021-2022/SSC 2021-2022 (4).jpg";
import sss2021_20225 from "../images/communityImgs/SSC 2021-2022/SSC 2021-2022 (5).jpg";

//SSC 2022-2023
import ssc2022_20231 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (1).jpg";
import ssc2022_20232 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (2).jpg";
import ssc2022_20233 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (3).jpg";
import ssc2022_20234 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (4).jpg";
import ssc2022_20235 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (5).jpg";
import ssc2022_20236 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (6).jpg";
import ssc2022_20237 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (7).jpg";
import ssc2022_20238 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (8).jpg";
import ssc2022_20239 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (9).jpg";
import ssc2022_202310 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (10).jpg";
import ssc2022_202311 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (11).jpg";
import ssc2022_202312 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (12).jpg";
import ssc2022_202313 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (13).jpg";
import ssc2022_202314 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (14).jpg";
import ssc2022_202315 from "../images/communityImgs/SSC 2022-2023/SSC 2022-2023 (15).jpg";

//TDP Meetings
import tdpMeetings1 from "../images/communityImgs/TDP Meetings/TDP Meetings (1).jpg";
import tdpMeetings2 from "../images/communityImgs/TDP Meetings/TDP Meetings (2).jpg";
import tdpMeetings3 from "../images/communityImgs/TDP Meetings/TDP Meetings (3).jpg";
import tdpMeetings4 from "../images/communityImgs/TDP Meetings/TDP Meetings (4).jpg";
import tdpMeetings5 from "../images/communityImgs/TDP Meetings/TDP Meetings (5).jpg";
import tdpMeetings6 from "../images/communityImgs/TDP Meetings/TDP Meetings (6).jpg";
import tdpMeetings7 from "../images/communityImgs/TDP Meetings/TDP Meetings (7).jpg";
import tdpMeetings8 from "../images/communityImgs/TDP Meetings/TDP Meetings (8).jpg";
import tdpMeetings9 from "../images/communityImgs/TDP Meetings/TDP Meetings (9).jpg";
import tdpMeetings10 from "../images/communityImgs/TDP Meetings/TDP Meetings (10).jpg";
import tdpMeetings11 from "../images/communityImgs/TDP Meetings/TDP Meetings (11).jpg";
import tdpMeetings12 from "../images/communityImgs/TDP Meetings/TDP Meetings (12).jpg";
import tdpMeetings13 from "../images/communityImgs/TDP Meetings/TDP Meetings (13).jpg";
import tdpMeetings14 from "../images/communityImgs/TDP Meetings/TDP Meetings (14).jpg";
import tdpMeetings15 from "../images/communityImgs/TDP Meetings/TDP Meetings (15).jpg";
import tdpMeetings16 from "../images/communityImgs/TDP Meetings/TDP Meetings (16).jpg";
import tdpMeetings17 from "../images/communityImgs/TDP Meetings/TDP Meetings (17).jpg";
import tdpMeetings18 from "../images/communityImgs/TDP Meetings/TDP Meetings (18).jpg";
import tdpMeetings19 from "../images/communityImgs/TDP Meetings/TDP Meetings (19).jpg";
import tdpMeetings20 from "../images/communityImgs/TDP Meetings/TDP Meetings (20).jpg";
import tdpMeetings21 from "../images/communityImgs/TDP Meetings/TDP Meetings (21).jpg";
import tdpMeetings22 from "../images/communityImgs/TDP Meetings/TDP Meetings (22).jpg";
import tdpMeetings23 from "../images/communityImgs/TDP Meetings/TDP Meetings (23).jpg";
import tdpMeetings24 from "../images/communityImgs/TDP Meetings/TDP Meetings (24).jpg";
import tdpMeetings25 from "../images/communityImgs/TDP Meetings/TDP Meetings (25).jpg";
import tdpMeetings26 from "../images/communityImgs/TDP Meetings/TDP Meetings (26).jpg";

//Vanabhojanalu
import vanabhojanalu1 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (1).jpg";
import vanabhojanalu2 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (2).jpg";
import vanabhojanalu3 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (3).jpg";
import vanabhojanalu4 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (4).jpg";
import vanabhojanalu5 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (5).jpg";
import vanabhojanalu6 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (6).jpg";
import vanabhojanalu7 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (7).jpg";
import vanabhojanalu8 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (8).jpg";
import vanabhojanalu9 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (9).jpg";
import vanabhojanalu10 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (10).jpg";
import vanabhojanalu11 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (11).jpg";
import vanabhojanalu12 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (12).jpg";
import vanabhojanalu13 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (13).jpg";
import vanabhojanalu14 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (14).jpg";
import vanabhojanalu15 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (15).jpg";
import vanabhojanalu16 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (16).jpg";
import vanabhojanalu17 from "../images/communityImgs/Vanabhojanalu/Vanabhojanalu (17).jpg";

//Vemavarapu Venkateswarlu
import vemavarapuVenkateswarlu1 from "../images/communityImgs/Vemavarapu Venkateswarlu/Vemavarapu Venkateswarlu (1).jpg";
import vemavarapuVenkateswarlu2 from "../images/communityImgs/Vemavarapu Venkateswarlu/Vemavarapu Venkateswarlu (2).jpg";
import vemavarapuVenkateswarlu3 from "../images/communityImgs/Vemavarapu Venkateswarlu/Vemavarapu Venkateswarlu (3).jpg";
import vemavarapuVenkateswarlu4 from "../images/communityImgs/Vemavarapu Venkateswarlu/Vemavarapu Venkateswarlu (4).jpg";

//Vijayawada_Books Distribution
import vijayawada_BooksDistribution1 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (1).jpg";
import vijayawada_BooksDistribution2 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (2).jpg";
import vijayawada_BooksDistribution3 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (3).jpg";
import vijayawada_BooksDistribution4 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (4).jpg";
import vijayawada_BooksDistribution5 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (5).jpg";
import vijayawada_BooksDistribution6 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (6).jpg";
import vijayawada_BooksDistribution7 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (7).jpg";
import vijayawada_BooksDistribution8 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (8).jpg";
import vijayawada_BooksDistribution9 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (9).jpg";
import vijayawada_BooksDistribution10 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (10).jpg";
import vijayawada_BooksDistribution11 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (11).jpg";
import vijayawada_BooksDistribution12 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (12).jpg";
import vijayawada_BooksDistribution13 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (13).jpg";
import vijayawada_BooksDistribution14 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (14).jpg";
import vijayawada_BooksDistribution15 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (15).jpg";
import vijayawada_BooksDistribution16 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (16).jpg";
import vijayawada_BooksDistribution17 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (17).jpg";
import vijayawada_BooksDistribution18 from "../images/communityImgs/Vijayawada_Books Distribution/Vijayawada_Books Distribution (18).jpg";

//Zoom Team 1st Meeting
import ZzoomTeam1stMeeting1 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (1).jpeg";
import ZzoomTeam1stMeeting2 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (2).jpeg";
import ZzoomTeam1stMeeting3 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (3).jpeg";
import ZzoomTeam1stMeeting4 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (4).jpeg";
import ZzoomTeam1stMeeting5 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (5).jpeg";
import ZzoomTeam1stMeeting6 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (6).jpeg";
import ZzoomTeam1stMeeting7 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (7).jpeg";
import ZzoomTeam1stMeeting8 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (8).jpeg";
import ZzoomTeam1stMeeting9 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (9).jpeg";
import ZzoomTeam1stMeeting10 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (10).jpeg";
import ZzoomTeam1stMeeting11 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (11).jpeg";
import ZzoomTeam1stMeeting12 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (12).jpeg";
import ZzoomTeam1stMeeting13 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (13).jpeg";
import ZzoomTeam1stMeeting14 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (14).jpeg";
import ZzoomTeam1stMeeting15 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (15).jpeg";
import ZzoomTeam1stMeeting16 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (16).jpeg";
import ZzoomTeam1stMeeting17 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (17).jpeg";
import ZzoomTeam1stMeeting18 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (18).jpeg";
import ZzoomTeam1stMeeting19 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (19).jpeg";
import ZzoomTeam1stMeeting20 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (20).jpeg";
import ZzoomTeam1stMeeting21 from "../images/communityImgs/Zoom Team 1st Meeting/Zoom Team 1st Meeting (21).jpeg";

//Foundations

//Gtr3
import gtr3_1 from "../images/foundationImgs/Gtr 3/Gtr 3 (1).jpg";
import gtr3_2 from "../images/foundationImgs/Gtr 3/Gtr 3 (2).jpg";
import gtr3_3 from "../images/foundationImgs/Gtr 3/Gtr 3 (3).jpg";

//Gtr1
import gtr1_1 from "../images/foundationImgs/Gtr1/Gtr1 (1).jpg";
import gtr1_2 from "../images/foundationImgs/Gtr1/Gtr1 (2).jpg";
import gtr1_3 from "../images/foundationImgs/Gtr1/Gtr1 (3).jpg";
import gtr1_4 from "../images/foundationImgs/Gtr1/Gtr1 (4).jpg";
import gtr1_5 from "../images/foundationImgs/Gtr1/Gtr1 (5).jpg";
import gtr1_6 from "../images/foundationImgs/Gtr1/Gtr1 (6).jpg";
import gtr1_7 from "../images/foundationImgs/Gtr1/Gtr1 (7).jpg";
import gtr1_8 from "../images/foundationImgs/Gtr1/Gtr1 (8).jpg";

//Kasturibha school
import kasturibhaschool1 from "../images/foundationImgs/Kasturibha school/Kasturibha school (1).jpg";
import kasturibhaschool2 from "../images/foundationImgs/Kasturibha school/Kasturibha school (2).jpg";
import kasturibhaschool3 from "../images/foundationImgs/Kasturibha school/Kasturibha school (3).jpg";
import kasturibhaschool4 from "../images/foundationImgs/Kasturibha school/Kasturibha school (4).jpg";
import kasturibhaschool5 from "../images/foundationImgs/Kasturibha school/Kasturibha school (5).jpg";
import kasturibhaschool6 from "../images/foundationImgs/Kasturibha school/Kasturibha school (6).jpg";
import kasturibhaschool7 from "../images/foundationImgs/Kasturibha school/Kasturibha school (7).jpg";
import kasturibhaschool8 from "../images/foundationImgs/Kasturibha school/Kasturibha school (8).jpg";
import kasturibhaschool9 from "../images/foundationImgs/Kasturibha school/Kasturibha school (9).jpg";
import kasturibhaschool10 from "../images/foundationImgs/Kasturibha school/Kasturibha school (10).jpg";

//KDP High School
import kpdHighSchool1 from "../images/foundationImgs/KDP High School/KDP High School (1).jpg";
import kpdHighSchool2 from "../images/foundationImgs/KDP High School/KDP High School (2).jpg";
import kpdHighSchool3 from "../images/foundationImgs/KDP High School/KDP High School (3).jpg";
import kpdHighSchool4 from "../images/foundationImgs/KDP High School/KDP High School (4).jpg";
import kpdHighSchool5 from "../images/foundationImgs/KDP High School/KDP High School (5).jpg";
import kpdHighSchool6 from "../images/foundationImgs/KDP High School/KDP High School (6).jpg";
import kpdHighSchool7 from "../images/foundationImgs/KDP High School/KDP High School (7).jpg";

// KDP Model School
import kdpModelSchool1 from "../images/foundationImgs/KDP Model School/KDP Model School (1).jpg";
import kdpModelSchool2 from "../images/foundationImgs/KDP Model School/KDP Model School (2).jpg";

// Kommi
import kommi1 from "../images/foundationImgs/Kommi/Kommi (1).jpg";
import kommi2 from "../images/foundationImgs/Kommi/Kommi (2).jpg";
import kommi3 from "../images/foundationImgs/Kommi/Kommi (3).jpg";
import kommi4 from "../images/foundationImgs/Kommi/Kommi (4).jpg";
import kommi5 from "../images/foundationImgs/Kommi/Kommi (5).jpg";
import kommi6 from "../images/foundationImgs/Kommi/Kommi (6).jpg";
import kommi7 from "../images/foundationImgs/Kommi/Kommi (7).jpg";
import kommi8 from "../images/foundationImgs/Kommi/Kommi (8).jpg";
import kommi9 from "../images/foundationImgs/Kommi/Kommi (9).jpg";
import kommi10 from "../images/foundationImgs/Kommi/Kommi (10).jpg";

// Kommi ZP High school
import kommiZPHighschool1 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (1).jpg";
import kommiZPHighschool2 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (2).jpg";
import kommiZPHighschool3 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (3).jpg";
import kommiZPHighschool4 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (4).jpg";
import kommiZPHighschool5 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (5).jpg";
import kommiZPHighschool6 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (6).jpg";
import kommiZPHighschool7 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (7).jpg";
import kommiZPHighschool8 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (8).jpg";
import kommiZPHighschool9 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (9).jpg";
import kommiZPHighschool10 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (10).jpg";
import kommiZPHighschool11 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (11).jpg";
import kommiZPHighschool12 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (12).jpg";
import kommiZPHighschool13 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (13).jpg";
import kommiZPHighschool14 from "../images/foundationImgs/Kommi ZP High school/Kommi ZP High school (14).jpg";

// Krakutur ZP School
import krakuturZPSchool1 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (1).jpg";
import krakuturZPSchool2 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (2).jpg";
import krakuturZPSchool3 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (3).jpg";
import krakuturZPSchool4 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (4).jpg";
import krakuturZPSchool5 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (5).jpg";
import krakuturZPSchool6 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (6).jpg";
import krakuturZPSchool7 from "../images/foundationImgs/Krakutur ZP School/Krakutur ZP School (7).jpg";

// Kummarakonduru
import kummarakonduru1 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (1).jpg";
import kummarakonduru2 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (2).jpg";
import kummarakonduru3 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (3).jpg";
import kummarakonduru4 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (4).jpg";
import kummarakonduru5 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (5).jpg";
import kummarakonduru6 from "../images/foundationImgs/Kummarakonduru/Kummarakonduru (6).jpg";

//Mike Sponser
import mikeSponser1 from "../images/foundationImgs/Mike Sponser/Mike Sponser (1).jpg";
import mikeSponser2 from "../images/foundationImgs/Mike Sponser/Mike Sponser (2).jpg";
import mikeSponser3 from "../images/foundationImgs/Mike Sponser/Mike Sponser (3).jpg";
import mikeSponser4 from "../images/foundationImgs/Mike Sponser/Mike Sponser (4).jpg";
import mikeSponser5 from "../images/foundationImgs/Mike Sponser/Mike Sponser (5).jpg";
import mikeSponser6 from "../images/foundationImgs/Mike Sponser/Mike Sponser (6).jpg";
import mikeSponser7 from "../images/foundationImgs/Mike Sponser/Mike Sponser (7).jpg";
import mikeSponser8 from "../images/foundationImgs/Mike Sponser/Mike Sponser (8).jpg";
import mikeSponser9 from "../images/foundationImgs/Mike Sponser/Mike Sponser (9).jpg";
import mikeSponser10 from "../images/foundationImgs/Mike Sponser/Mike Sponser (10).jpg";
import mikeSponser11 from "../images/foundationImgs/Mike Sponser/Mike Sponser (11).jpg";

// Saipeta
import saipeta1 from "../images/foundationImgs/Saipeta/Saipeta (1).jpg";
import saipeta2 from "../images/foundationImgs/Saipeta/Saipeta (2).jpg";
import saipeta3 from "../images/foundationImgs/Saipeta/Saipeta (3).jpg";
import saipeta4 from "../images/foundationImgs/Saipeta/Saipeta (4).jpg";
import saipeta5 from "../images/foundationImgs/Saipeta/Saipeta (5).jpg";
import saipeta6 from "../images/foundationImgs/Saipeta/Saipeta (6).jpg";
import saipeta7 from "../images/foundationImgs/Saipeta/Saipeta (7).jpg";
import saipeta8 from "../images/foundationImgs/Saipeta/Saipeta (8).jpg";

// School Anniversary
import schoolAnniversary1 from "../images/foundationImgs/School Anniversary/School Anniversary (1).jpg";
import schoolAnniversary2 from "../images/foundationImgs/School Anniversary/School Anniversary (2).jpg";
import schoolAnniversary3 from "../images/foundationImgs/School Anniversary/School Anniversary (3).jpg";
import schoolAnniversary4 from "../images/foundationImgs/School Anniversary/School Anniversary (4).jpg";
import schoolAnniversary5 from "../images/foundationImgs/School Anniversary/School Anniversary (5).jpg";
import schoolAnniversary6 from "../images/foundationImgs/School Anniversary/School Anniversary (6).jpg";
import schoolAnniversary7 from "../images/foundationImgs/School Anniversary/School Anniversary (7).jpg";
import schoolAnniversary8 from "../images/foundationImgs/School Anniversary/School Anniversary (8).jpg";
import schoolAnniversary9 from "../images/foundationImgs/School Anniversary/School Anniversary (9).jpg";
import schoolAnniversary10 from "../images/foundationImgs/School Anniversary/School Anniversary (10).jpg";
import schoolAnniversary11 from "../images/foundationImgs/School Anniversary/School Anniversary (11).jpg";
import schoolAnniversary12 from "../images/foundationImgs/School Anniversary/School Anniversary (12).jpg";
import schoolAnniversary13 from "../images/foundationImgs/School Anniversary/School Anniversary (13).jpg";
import schoolAnniversary14 from "../images/foundationImgs/School Anniversary/School Anniversary (14).jpg";
import schoolAnniversary15 from "../images/foundationImgs/School Anniversary/School Anniversary (15).jpg";
import schoolAnniversary16 from "../images/foundationImgs/School Anniversary/School Anniversary (16).jpg";
import schoolAnniversary17 from "../images/foundationImgs/School Anniversary/School Anniversary (17).jpg";
import schoolAnniversary18 from "../images/foundationImgs/School Anniversary/School Anniversary (18).jpg";
import schoolAnniversary19 from "../images/foundationImgs/School Anniversary/School Anniversary (19).jpg";
import schoolAnniversary20 from "../images/foundationImgs/School Anniversary/School Anniversary (20).jpg";
import schoolAnniversary21 from "../images/foundationImgs/School Anniversary/School Anniversary (21).jpg";
import schoolAnniversary22 from "../images/foundationImgs/School Anniversary/School Anniversary (22).jpg";
import schoolAnniversary23 from "../images/foundationImgs/School Anniversary/School Anniversary (23).jpg";
import schoolAnniversary24 from "../images/foundationImgs/School Anniversary/School Anniversary (24).jpg";
import schoolAnniversary25 from "../images/foundationImgs/School Anniversary/School Anniversary (25).jpg";
import schoolAnniversary26 from "../images/foundationImgs/School Anniversary/School Anniversary (26).jpg";
import schoolAnniversary27 from "../images/foundationImgs/School Anniversary/School Anniversary (27).jpg";

// Vijayawada
import vijayawada1 from "../images/foundationImgs/Vijayawada/Vijayawada (1).jpg";
import vijayawada2 from "../images/foundationImgs/Vijayawada/Vijayawada (2).jpg";
import vijayawada3 from "../images/foundationImgs/Vijayawada/Vijayawada (3).jpg";
import vijayawada4 from "../images/foundationImgs/Vijayawada/Vijayawada (4).jpg";
import vijayawada5 from "../images/foundationImgs/Vijayawada/Vijayawada (5).jpg";
import vijayawada6 from "../images/foundationImgs/Vijayawada/Vijayawada (6).jpg";
import vijayawada7 from "../images/foundationImgs/Vijayawada/Vijayawada (7).jpg";
import vijayawada8 from "../images/foundationImgs/Vijayawada/Vijayawada (8).jpg";
import vijayawada9 from "../images/foundationImgs/Vijayawada/Vijayawada (9).jpg";
import vijayawada10 from "../images/foundationImgs/Vijayawada/Vijayawada (10).jpg";
import vijayawada11 from "../images/foundationImgs/Vijayawada/Vijayawada (11).jpg";
import vijayawada12 from "../images/foundationImgs/Vijayawada/Vijayawada (12).jpg";
import vijayawada13 from "../images/foundationImgs/Vijayawada/Vijayawada (13).jpg";
import vijayawada14 from "../images/foundationImgs/Vijayawada/Vijayawada (14).jpg";
import vijayawada15 from "../images/foundationImgs/Vijayawada/Vijayawada (15).jpg";
import vijayawada16 from "../images/foundationImgs/Vijayawada/Vijayawada (16).jpg";
import vijayawada17 from "../images/foundationImgs/Vijayawada/Vijayawada (17).jpg";
import vijayawada18 from "../images/foundationImgs/Vijayawada/Vijayawada (18).jpg";

const politicalFoldersList = [
  {
    folderId: uuidV4(),
    folderName: "Leaders",
  },
  {
    folderId: uuidV4(),
    folderName: "Important Persons",
  },
  {
    folderId: uuidV4(),
    folderName: "2019 Election Campaign",
  },
  {
    folderId: uuidV4(),
    folderName: "Amaravathi",
  },
  {
    folderId: uuidV4(),
    folderName: "Baadude Badudu",
  },
  {
    folderId: uuidV4(),
    folderName: "BC Hostel Visit",
  },
  {
    folderId: uuidV4(),
    folderName: "BC Round Tables",
  },

  {
    folderId: uuidV4(),
    folderName: "Bhavishyat Guarantee Bus yatra",
  },

  {
    folderId: uuidV4(),
    folderName: "Bus Charges",
  },
  {
    folderId: uuidV4(),
    folderName: "CBN Dharmaporata Deeksha",
  },
  {
    folderId: uuidV4(),
    folderName: "Cycle Yatra",
  },
  {
    folderId: uuidV4(),
    folderName: "Bike Yatra",
  },

  {
    folderId: uuidV4(),
    folderName: "Idemi Karma",
  },
  {
    folderId: uuidV4(),
    folderName: "Mahanadu",
  },
  {
    folderId: uuidV4(),
    folderName: "NLR Municipality",
  },

  {
    folderId: uuidV4(),
    folderName: "Pressmeet",
  },
  {
    folderId: uuidV4(),
    folderName: "Raithu Porubata",
  },
  {
    folderId: uuidV4(),
    folderName: "Special Status",
  },
  {
    folderId: uuidV4(),
    folderName: "TPT Bi Election",
  },
  {
    folderId: uuidV4(),
    folderName: "Yuvagalam",
  },
  {
    folderId: uuidV4(),
    folderName: "Yuvanestam",
  },
  {
    folderId: uuidV4(),
    folderName: "Yuvachaithanya Yathra",
  },
  {
    folderId: uuidV4(),
    folderName: "Bring Babu Back",
  },
];

const communityFoldersList = [
  {
    folderId: uuidV4(),
    folderName: "ABDMS 1st Anniversary",
  },
  {
    folderId: uuidV4(),
    folderName: "Collector Office NLR",
  },
  {
    folderId: uuidV4(),
    folderName: "Drycleaning wash Gtr Opening",
  },
  {
    folderId: uuidV4(),
    folderName: "Guntur_Books Distribution",
  },
  {
    folderId: uuidV4(),
    folderName: "Helping Community",
  },
  {
    folderId: uuidV4(),
    folderName: "Ilamma Vardhanthi",
  },
  {
    folderId: uuidV4(),
    folderName: "Kavali Team",
  },
  {
    folderId: uuidV4(),
    folderName: "NLR_Political Apprictiations",
  },
  {
    folderId: uuidV4(),
    folderName: "Round Table_RVS_Guntur",
  },
  {
    folderId: uuidV4(),
    folderName: "Round Table_RVS_Vijayawada",
  },
  {
    folderId: uuidV4(),
    folderName: "Round Table_Tirupati",
  },
  {
    folderId: uuidV4(),
    folderName: "SSC 2021-2022",
  },
  {
    folderId: uuidV4(),
    folderName: "SSC 2022-2023",
  },
  {
    folderId: uuidV4(),
    folderName: "TDP Meetings",
  },
  {
    folderId: uuidV4(),
    folderName: "Vanabhojanalu",
  },
  {
    folderId: uuidV4(),
    folderName: "Vemavarapu Venkateswarlu",
  },
  {
    folderId: uuidV4(),
    folderName: "Vijayawada_Books Distribution",
  },
  {
    folderId: uuidV4(),
    folderName: "Zoom Team Meeting",
  },
];

const servicesFoldersList = [
  {
    folderId: uuidV4(),
    folderName: "Gtr1",
  },
  {
    folderId: uuidV4(),
    folderName: "Gtr3",
  },
  {
    folderId: uuidV4(),
    folderName: "Kasturibha school",
  },
  {
    folderId: uuidV4(),
    folderName: "KDP High School",
  },
  {
    folderId: uuidV4(),
    folderName: "KDP Model School",
  },
  {
    folderId: uuidV4(),
    folderName: "Kommi",
  },
  {
    folderId: uuidV4(),
    folderName: "Kommi ZP High school",
  },
  {
    folderId: uuidV4(),
    folderName: "Krakutur ZP School",
  },
  {
    folderId: uuidV4(),
    folderName: "Kummarakonduru",
  },
  {
    folderId: uuidV4(),
    folderName: "Mike Sponser",
  },
  {
    folderId: uuidV4(),
    folderName: "Saipeta",
  },
  {
    folderId: uuidV4(),
    folderName: "School Anniversary",
  },
  {
    folderId: uuidV4(),
    folderName: "Vijayawada",
  },
];

//IMAGES

const leadersImgs = [
  //CBN
  { imgId: uuidV4(), img: cbn1 },
  { imgId: uuidV4(), img: cbn2 },
  { imgId: uuidV4(), img: cbn3 },
  { imgId: uuidV4(), img: cbn4 },
  { imgId: uuidV4(), img: cbn5 },
  { imgId: uuidV4(), img: cbn6 },
  { imgId: uuidV4(), img: cbn7 },
  { imgId: uuidV4(), img: cbn8 },
  { imgId: uuidV4(), img: cbn9 },
  { imgId: uuidV4(), img: cbn10 },
  { imgId: uuidV4(), img: cbn11 },
  { imgId: uuidV4(), img: cbn12 },
  { imgId: uuidV4(), img: cbn13 },

  //CBN Sir
  { imgId: uuidV4(), img: cbnSir1 },
  { imgId: uuidV4(), img: cbnSir2 },
  { imgId: uuidV4(), img: cbnSir3 },
  { imgId: uuidV4(), img: cbnSir4 },
  { imgId: uuidV4(), img: cbnSir5 },
  { imgId: uuidV4(), img: cbnSir6 },
  { imgId: uuidV4(), img: cbnSir7 },
  { imgId: uuidV4(), img: cbnSir8 },
  { imgId: uuidV4(), img: cbnSir9 },
  { imgId: uuidV4(), img: cbnSir10 },
  { imgId: uuidV4(), img: cbnSir11 },
  { imgId: uuidV4(), img: cbnSir12 },
  { imgId: uuidV4(), img: cbnSir13 },
  { imgId: uuidV4(), img: cbnSir14 },
  { imgId: uuidV4(), img: cbnSir15 },
  { imgId: uuidV4(), img: cbnSir16 },
  { imgId: uuidV4(), img: cbnSir17 },
  { imgId: uuidV4(), img: cbnSir18 },
  { imgId: uuidV4(), img: cbnSir19 },
  { imgId: uuidV4(), img: cbnSir20 },
  { imgId: uuidV4(), img: cbnSir21 },
  { imgId: uuidV4(), img: cbnSir22 },
  { imgId: uuidV4(), img: cbnSir23 },
  { imgId: uuidV4(), img: cbnSir24 },
  { imgId: uuidV4(), img: cbnSir25 },
  { imgId: uuidV4(), img: cbnSir26 },

  // Lokesh
  { imgId: uuidV4(), img: lokesh1 },
  { imgId: uuidV4(), img: lokesh2 },
  { imgId: uuidV4(), img: lokesh3 },
  { imgId: uuidV4(), img: lokesh4 },
  { imgId: uuidV4(), img: lokesh5 },
  { imgId: uuidV4(), img: lokesh6 },
  { imgId: uuidV4(), img: lokesh7 },
  { imgId: uuidV4(), img: lokesh8 },
  { imgId: uuidV4(), img: lokesh9 },
  { imgId: uuidV4(), img: lokesh10 },
  { imgId: uuidV4(), img: lokesh11 },
  { imgId: uuidV4(), img: lokesh12 },
  { imgId: uuidV4(), img: lokesh13 },
  { imgId: uuidV4(), img: lokesh14 },
  { imgId: uuidV4(), img: lokesh15 },
  { imgId: uuidV4(), img: lokesh16 },
  { imgId: uuidV4(), img: lokesh17 },
  { imgId: uuidV4(), img: lokesh18 },
  { imgId: uuidV4(), img: lokesh19 },
  { imgId: uuidV4(), img: lokesh20 },
  { imgId: uuidV4(), img: lokesh21 },

  //KOLLU
  { imgId: uuidV4(), img: kollu1 },
  { imgId: uuidV4(), img: kollu2 },
  { imgId: uuidV4(), img: kollu3 },
  { imgId: uuidV4(), img: kollu4 },
  { imgId: uuidV4(), img: kollu5 },
  { imgId: uuidV4(), img: kollu6 },
  { imgId: uuidV4(), img: kollu7 },
  { imgId: uuidV4(), img: kollu8 },
  { imgId: uuidV4(), img: kollu9 },
  { imgId: uuidV4(), img: kollu10 },
  { imgId: uuidV4(), img: kollu11 },
  { imgId: uuidV4(), img: kollu12 },
  { imgId: uuidV4(), img: kollu13 },

  //Atchen Naidu
  { imgId: uuidV4(), img: atchennaidu1 },
  { imgId: uuidV4(), img: atchennaidu2 },
  { imgId: uuidV4(), img: atchennaidu3 },
  { imgId: uuidV4(), img: atchennaidu4 },
  { imgId: uuidV4(), img: atchennaidu5 },
  { imgId: uuidV4(), img: atchennaidu6 },
  { imgId: uuidV4(), img: atchennaidu7 },
  { imgId: uuidV4(), img: atchennaidu8 },
  { imgId: uuidV4(), img: atchennaidu9 },

  //BVR
  { imgId: uuidV4(), img: bvr1 },
  { imgId: uuidV4(), img: bvr2 },
  { imgId: uuidV4(), img: bvr3 },
  { imgId: uuidV4(), img: bvr4 },
  { imgId: uuidV4(), img: bvr5 },
  { imgId: uuidV4(), img: bvr6 },
  { imgId: uuidV4(), img: bvr7 },
  { imgId: uuidV4(), img: bvr8 },
  { imgId: uuidV4(), img: bvr9 },
  { imgId: uuidV4(), img: bvr10 },
  { imgId: uuidV4(), img: bvr11 },
  { imgId: uuidV4(), img: bvr12 },
  { imgId: uuidV4(), img: bvr13 },
  { imgId: uuidV4(), img: bvr14 },
  { imgId: uuidV4(), img: bvr15 },
  { imgId: uuidV4(), img: bvr16 },
  { imgId: uuidV4(), img: bvr17 },
  { imgId: uuidV4(), img: bvr18 },
  { imgId: uuidV4(), img: bvr19 },
  { imgId: uuidV4(), img: bvr20 },
  { imgId: uuidV4(), img: bvr21 },

  //BRC
  { imgId: uuidV4(), img: brc1 },
  { imgId: uuidV4(), img: brc2 },
  { imgId: uuidV4(), img: brc3 },
  { imgId: uuidV4(), img: brc4 },
  { imgId: uuidV4(), img: brc5 },
  { imgId: uuidV4(), img: brc6 },
  { imgId: uuidV4(), img: brc7 },
  { imgId: uuidV4(), img: brc8 },
  { imgId: uuidV4(), img: brc9 },
  { imgId: uuidV4(), img: brc10 },
  { imgId: uuidV4(), img: brc11 },
  { imgId: uuidV4(), img: brc12 },
  { imgId: uuidV4(), img: brc13 },
  { imgId: uuidV4(), img: brc14 },
  { imgId: uuidV4(), img: brc15 },
  { imgId: uuidV4(), img: brc16 },
  { imgId: uuidV4(), img: brc17 },
  { imgId: uuidV4(), img: brc18 },
  { imgId: uuidV4(), img: brc19 },
  { imgId: uuidV4(), img: brc20 },
  { imgId: uuidV4(), img: brc21 },
  { imgId: uuidV4(), img: brc22 },
  { imgId: uuidV4(), img: brc23 },
  { imgId: uuidV4(), img: brc24 },
  { imgId: uuidV4(), img: brc25 },
  { imgId: uuidV4(), img: brc26 },
  { imgId: uuidV4(), img: brc27 },
  { imgId: uuidV4(), img: brc28 },
  { imgId: uuidV4(), img: brc29 },
  { imgId: uuidV4(), img: brc30 },
  { imgId: uuidV4(), img: brc31 },
  { imgId: uuidV4(), img: brc32 },
  { imgId: uuidV4(), img: brc33 },
  { imgId: uuidV4(), img: brc34 },
  { imgId: uuidV4(), img: brc35 },
  { imgId: uuidV4(), img: brc36 },
  { imgId: uuidV4(), img: brc37 },
  { imgId: uuidV4(), img: brc38 },
  { imgId: uuidV4(), img: brc39 },
  { imgId: uuidV4(), img: brc40 },
  { imgId: uuidV4(), img: brc41 },
  { imgId: uuidV4(), img: brc42 },
];
const impPersonsImgs = [
  //Ambedkar
  { imgId: uuidV4(), img: brAmbedkar1 },
  { imgId: uuidV4(), img: brAmbedkar2 },
  { imgId: uuidV4(), img: brAmbedkar3 },
  { imgId: uuidV4(), img: brAmbedkar4 },
  { imgId: uuidV4(), img: brAmbedkar5 },
  { imgId: uuidV4(), img: brAmbedkar6 },

  //Vivekanandha
  { imgId: uuidV4(), img: vivekanandha1 },
  { imgId: uuidV4(), img: vivekanandha2 },

  //Poole
  { imgId: uuidV4(), img: poole1 },
  { imgId: uuidV4(), img: poole2 },
  { imgId: uuidV4(), img: poole3 },
  { imgId: uuidV4(), img: poole4 },
  { imgId: uuidV4(), img: poole5 },
  { imgId: uuidV4(), img: poole6 },
  { imgId: uuidV4(), img: poole7 },
  { imgId: uuidV4(), img: poole8 },
  { imgId: uuidV4(), img: poole9 },

  //Gandhi
  { imgId: uuidV4(), img: gandhi1 },
  { imgId: uuidV4(), img: gandhi2 },
  { imgId: uuidV4(), img: gandhi3 },
  { imgId: uuidV4(), img: gandhi4 },
  { imgId: uuidV4(), img: gandhi5 },
  { imgId: uuidV4(), img: gandhi6 },
  { imgId: uuidV4(), img: gandhi7 },

  //NTR
  { imgId: uuidV4(), img: ntr1 },
  { imgId: uuidV4(), img: ntr2 },
  { imgId: uuidV4(), img: ntr3 },
  { imgId: uuidV4(), img: ntr4 },
  { imgId: uuidV4(), img: ntr5 },
  { imgId: uuidV4(), img: ntr6 },
  { imgId: uuidV4(), img: ntr7 },
  { imgId: uuidV4(), img: ntr8 },
  { imgId: uuidV4(), img: ntr9 },
  { imgId: uuidV4(), img: ntr10 },
  { imgId: uuidV4(), img: ntr11 },
  { imgId: uuidV4(), img: ntr12 },
];

const electionCampaign2019Imgs = [
  { imgId: uuidV4(), img: electionCampaign1 },
  { imgId: uuidV4(), img: electionCampaign2 },
  { imgId: uuidV4(), img: electionCampaign3 },
  { imgId: uuidV4(), img: electionCampaign4 },
  { imgId: uuidV4(), img: electionCampaign5 },
  { imgId: uuidV4(), img: electionCampaign6 },
  { imgId: uuidV4(), img: electionCampaign7 },
  { imgId: uuidV4(), img: electionCampaign8 },
  { imgId: uuidV4(), img: electionCampaign9 },
  { imgId: uuidV4(), img: electionCampaign10 },
  { imgId: uuidV4(), img: electionCampaign11 },
  { imgId: uuidV4(), img: electionCampaign12 },
  { imgId: uuidV4(), img: electionCampaign13 },
  { imgId: uuidV4(), img: electionCampaign14 },
  { imgId: uuidV4(), img: electionCampaign15 },
  { imgId: uuidV4(), img: electionCampaign16 },
  { imgId: uuidV4(), img: electionCampaign17 },
  { imgId: uuidV4(), img: electionCampaign18 },
  { imgId: uuidV4(), img: electionCampaign19 },
  { imgId: uuidV4(), img: electionCampaign20 },
  { imgId: uuidV4(), img: electionCampaign21 },
  { imgId: uuidV4(), img: electionCampaign22 },
  { imgId: uuidV4(), img: electionCampaign23 },
  { imgId: uuidV4(), img: electionCampaign24 },
  { imgId: uuidV4(), img: electionCampaign25 },
  { imgId: uuidV4(), img: electionCampaign26 },
  { imgId: uuidV4(), img: electionCampaign27 },
  { imgId: uuidV4(), img: electionCampaign28 },
  { imgId: uuidV4(), img: electionCampaign29 },
  { imgId: uuidV4(), img: electionCampaign30 },
  { imgId: uuidV4(), img: electionCampaign31 },
  { imgId: uuidV4(), img: electionCampaign32 },
  { imgId: uuidV4(), img: electionCampaign33 },
  { imgId: uuidV4(), img: electionCampaign34 },
  { imgId: uuidV4(), img: electionCampaign35 },
  { imgId: uuidV4(), img: electionCampaign36 },
  { imgId: uuidV4(), img: electionCampaign37 },
  { imgId: uuidV4(), img: electionCampaign38 },
  { imgId: uuidV4(), img: electionCampaign39 },
  { imgId: uuidV4(), img: electionCampaign40 },
  { imgId: uuidV4(), img: electionCampaign41 },
  { imgId: uuidV4(), img: electionCampaign42 },
  { imgId: uuidV4(), img: electionCampaign43 },
  { imgId: uuidV4(), img: electionCampaign44 },
  { imgId: uuidV4(), img: electionCampaign45 },
  { imgId: uuidV4(), img: electionCampaign46 },
  { imgId: uuidV4(), img: electionCampaign47 },
  { imgId: uuidV4(), img: electionCampaign48 },
  { imgId: uuidV4(), img: electionCampaign49 },
  { imgId: uuidV4(), img: electionCampaign50 },
  { imgId: uuidV4(), img: electionCampaign51 },
  { imgId: uuidV4(), img: electionCampaign52 },
  { imgId: uuidV4(), img: electionCampaign53 },
  { imgId: uuidV4(), img: electionCampaign54 },
  { imgId: uuidV4(), img: electionCampaign55 },
  { imgId: uuidV4(), img: electionCampaign56 },
  { imgId: uuidV4(), img: electionCampaign57 },
  { imgId: uuidV4(), img: electionCampaign58 },
  { imgId: uuidV4(), img: electionCampaign59 },
  { imgId: uuidV4(), img: electionCampaign60 },
  { imgId: uuidV4(), img: electionCampaign61 },
  { imgId: uuidV4(), img: electionCampaign62 },
  { imgId: uuidV4(), img: electionCampaign63 },
  { imgId: uuidV4(), img: electionCampaign64 },
  { imgId: uuidV4(), img: electionCampaign65 },
  { imgId: uuidV4(), img: electionCampaign66 },
  { imgId: uuidV4(), img: electionCampaign67 },
  { imgId: uuidV4(), img: electionCampaign68 },
  { imgId: uuidV4(), img: electionCampaign69 },
  { imgId: uuidV4(), img: electionCampaign70 },
  { imgId: uuidV4(), img: electionCampaign71 },
  { imgId: uuidV4(), img: electionCampaign72 },
  { imgId: uuidV4(), img: electionCampaign73 },
  { imgId: uuidV4(), img: electionCampaign74 },
  { imgId: uuidV4(), img: electionCampaign75 },
  { imgId: uuidV4(), img: electionCampaign76 },
];
const amaravathiImgs = [
  { imgId: uuidV4(), img: amravathi1 },
  { imgId: uuidV4(), img: amravathi2 },
  { imgId: uuidV4(), img: amravathi3 },
  { imgId: uuidV4(), img: amravathi4 },
  { imgId: uuidV4(), img: amravathi5 },
  { imgId: uuidV4(), img: amravathi6 },
  { imgId: uuidV4(), img: amravathi7 },
  { imgId: uuidV4(), img: amravathi8 },
  { imgId: uuidV4(), img: amravathi9 },
  { imgId: uuidV4(), img: amravathi10 },
  { imgId: uuidV4(), img: amravathi11 },
  { imgId: uuidV4(), img: amravathi12 },
  { imgId: uuidV4(), img: amravathi13 },
  { imgId: uuidV4(), img: amravathi14 },
  { imgId: uuidV4(), img: amravathi15 },
  { imgId: uuidV4(), img: amravathi16 },
  { imgId: uuidV4(), img: amravathi17 },
  { imgId: uuidV4(), img: amravathi18 },
  { imgId: uuidV4(), img: amravathi19 },
  { imgId: uuidV4(), img: amravathi20 },
  { imgId: uuidV4(), img: amravathi21 },
  { imgId: uuidV4(), img: amravathi22 },
  { imgId: uuidV4(), img: amravathi23 },
  { imgId: uuidV4(), img: amravathi24 },
  { imgId: uuidV4(), img: amravathi25 },
];
const baadudeBaduduImgs = [
  { imgId: uuidV4(), img: baadudeBadudu1 },
  { imgId: uuidV4(), img: baadudeBadudu2 },
  { imgId: uuidV4(), img: baadudeBadudu3 },
  { imgId: uuidV4(), img: baadudeBadudu4 },
  { imgId: uuidV4(), img: baadudeBadudu5 },
  { imgId: uuidV4(), img: baadudeBadudu6 },
  { imgId: uuidV4(), img: baadudeBadudu7 },
  { imgId: uuidV4(), img: baadudeBadudu8 },
  { imgId: uuidV4(), img: baadudeBadudu9 },
  { imgId: uuidV4(), img: baadudeBadudu10 },
  { imgId: uuidV4(), img: baadudeBadudu11 },
  { imgId: uuidV4(), img: baadudeBadudu12 },
  { imgId: uuidV4(), img: baadudeBadudu13 },
  { imgId: uuidV4(), img: baadudeBadudu14 },
  { imgId: uuidV4(), img: baadudeBadudu15 },
  { imgId: uuidV4(), img: baadudeBadudu16 },
  { imgId: uuidV4(), img: baadudeBadudu17 },
  { imgId: uuidV4(), img: baadudeBadudu18 },
  { imgId: uuidV4(), img: baadudeBadudu19 },
  { imgId: uuidV4(), img: baadudeBadudu20 },
  { imgId: uuidV4(), img: baadudeBadudu21 },
  { imgId: uuidV4(), img: baadudeBadudu22 },
];
const bcHostelVisitImgs = [
  { imgId: uuidV4(), img: bcHostelVisit1 },
  { imgId: uuidV4(), img: bcHostelVisit2 },
  { imgId: uuidV4(), img: bcHostelVisit3 },
  { imgId: uuidV4(), img: bcHostelVisit4 },
  { imgId: uuidV4(), img: bcHostelVisit5 },
  { imgId: uuidV4(), img: bcHostelVisit6 },
  { imgId: uuidV4(), img: bcHostelVisit7 },
  { imgId: uuidV4(), img: bcHostelVisit8 },
  { imgId: uuidV4(), img: bcHostelVisit9 },
  { imgId: uuidV4(), img: bcHostelVisit10 },
  { imgId: uuidV4(), img: bcHostelVisit11 },
  { imgId: uuidV4(), img: bcHostelVisit12 },
  { imgId: uuidV4(), img: bcHostelVisit13 },
  { imgId: uuidV4(), img: bcHostelVisit14 },
  { imgId: uuidV4(), img: bcHostelVisit15 },
];
const bcRoundTablesImgs = [
  { imgId: uuidV4(), img: bcRoundTables1 },
  { imgId: uuidV4(), img: bcRoundTables2 },
  { imgId: uuidV4(), img: bcRoundTables3 },
  { imgId: uuidV4(), img: bcRoundTables4 },
  { imgId: uuidV4(), img: bcRoundTables5 },
  { imgId: uuidV4(), img: bcRoundTables6 },
  { imgId: uuidV4(), img: bcRoundTables7 },
  { imgId: uuidV4(), img: bcRoundTables8 },
  { imgId: uuidV4(), img: bcRoundTables9 },
  { imgId: uuidV4(), img: bcRoundTables10 },
  { imgId: uuidV4(), img: bcRoundTables11 },
  { imgId: uuidV4(), img: bcRoundTables12 },
  { imgId: uuidV4(), img: bcRoundTables13 },
  { imgId: uuidV4(), img: bcRoundTables14 },
  { imgId: uuidV4(), img: bcRoundTables15 },
  { imgId: uuidV4(), img: bcRoundTables16 },
  { imgId: uuidV4(), img: bcRoundTables17 },
  { imgId: uuidV4(), img: bcRoundTables18 },
  { imgId: uuidV4(), img: bcRoundTables19 },
  { imgId: uuidV4(), img: bcRoundTables20 },
  { imgId: uuidV4(), img: bcRoundTables21 },
];
const bhavishyatGuaranteeBusyatraImgs = [
  { imgId: uuidV4(), img: bhavishyatGuaranteeBusyatra1 },
  { imgId: uuidV4(), img: bhavishyatGuaranteeBusyatra2 },
  { imgId: uuidV4(), img: bhavishyatGuaranteeBusyatra3 },
  { imgId: uuidV4(), img: bhavishyatGuaranteeBusyatra4 },
  { imgId: uuidV4(), img: bhavishyatGuaranteeBusyatra5 },
];
const busChargesImgs = [
  { imgId: uuidV4(), img: busCharges1 },
  { imgId: uuidV4(), img: busCharges2 },
  { imgId: uuidV4(), img: busCharges3 },
  { imgId: uuidV4(), img: busCharges4 },
  { imgId: uuidV4(), img: busCharges5 },
  { imgId: uuidV4(), img: busCharges6 },
  { imgId: uuidV4(), img: busCharges7 },
  { imgId: uuidV4(), img: busCharges8 },
  { imgId: uuidV4(), img: busCharges9 },
  { imgId: uuidV4(), img: busCharges10 },
  { imgId: uuidV4(), img: busCharges11 },
  { imgId: uuidV4(), img: busCharges12 },
  { imgId: uuidV4(), img: busCharges13 },
];
const cbnDharmaporataDeekshaImgs = [
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha1 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha2 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha3 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha4 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha5 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha6 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha7 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha8 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha9 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha10 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha11 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha12 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha13 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha14 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha15 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha16 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha17 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha18 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha19 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha20 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha21 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha22 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha23 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha24 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha25 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha26 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha27 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha28 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha29 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha30 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha31 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha32 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha33 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha34 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha35 },
  { imgId: uuidV4(), img: cbnDharmaporataDeeksha36 },
  //CBN Programs

  { imgId: uuidV4(), img: cbnProgram1 },
  { imgId: uuidV4(), img: cbnProgram2 },
  { imgId: uuidV4(), img: cbnProgram3 },
  { imgId: uuidV4(), img: cbnProgram4 },
  { imgId: uuidV4(), img: cbnProgram5 },
  { imgId: uuidV4(), img: cbnProgram6 },
  { imgId: uuidV4(), img: cbnProgram7 },
  { imgId: uuidV4(), img: cbnProgram8 },
  { imgId: uuidV4(), img: cbnProgram9 },
  { imgId: uuidV4(), img: cbnProgram10 },
  { imgId: uuidV4(), img: cbnProgram11 },
  { imgId: uuidV4(), img: cbnProgram12 },
  { imgId: uuidV4(), img: cbnProgram13 },
  { imgId: uuidV4(), img: cbnProgram14 },
  { imgId: uuidV4(), img: cbnProgram15 },
  { imgId: uuidV4(), img: cbnProgram16 },
];
const cycleYatraImgs = [
  { imgId: uuidV4(), img: cycleYatra1 },
  { imgId: uuidV4(), img: cycleYatra2 },
  { imgId: uuidV4(), img: cycleYatra3 },
  { imgId: uuidV4(), img: cycleYatra4 },
  { imgId: uuidV4(), img: cycleYatra5 },
  { imgId: uuidV4(), img: cycleYatra6 },
  { imgId: uuidV4(), img: cycleYatra7 },
  { imgId: uuidV4(), img: cycleYatra8 },
  { imgId: uuidV4(), img: cycleYatra9 },
  { imgId: uuidV4(), img: cycleYatra10 },
  { imgId: uuidV4(), img: cycleYatra11 },
  { imgId: uuidV4(), img: cycleYatra12 },
  { imgId: uuidV4(), img: cycleYatra13 },
  { imgId: uuidV4(), img: cycleYatra14 },
  { imgId: uuidV4(), img: cycleYatra15 },
  { imgId: uuidV4(), img: cycleYatra16 },
  { imgId: uuidV4(), img: cycleYatra17 },
  { imgId: uuidV4(), img: cycleYatra18 },
  { imgId: uuidV4(), img: cycleYatra19 },
  { imgId: uuidV4(), img: cycleYatra20 },
  { imgId: uuidV4(), img: cycleYatra21 },
  { imgId: uuidV4(), img: cycleYatra22 },
  { imgId: uuidV4(), img: cycleYatra23 },
  { imgId: uuidV4(), img: cycleYatra24 },
  { imgId: uuidV4(), img: cycleYatra25 },
  { imgId: uuidV4(), img: cycleYatra26 },
  { imgId: uuidV4(), img: cycleYatra27 },
  { imgId: uuidV4(), img: cycleYatra28 },
];
const idemiKarmaImgs = [
  { imgId: uuidV4(), img: idemiKarma1 },
  { imgId: uuidV4(), img: idemiKarma2 },
  { imgId: uuidV4(), img: idemiKarma3 },
  { imgId: uuidV4(), img: idemiKarma4 },
  { imgId: uuidV4(), img: idemiKarma5 },
  { imgId: uuidV4(), img: idemiKarma6 },
  { imgId: uuidV4(), img: idemiKarma7 },
  { imgId: uuidV4(), img: idemiKarma8 },
  { imgId: uuidV4(), img: idemiKarma9 },
  { imgId: uuidV4(), img: idemiKarma10 },
  { imgId: uuidV4(), img: idemiKarma11 },
  { imgId: uuidV4(), img: idemiKarma12 },
  { imgId: uuidV4(), img: idemiKarma13 },
  { imgId: uuidV4(), img: idemiKarma14 },
  { imgId: uuidV4(), img: idemiKarma15 },
  { imgId: uuidV4(), img: idemiKarma16 },
  { imgId: uuidV4(), img: idemiKarma17 },
  { imgId: uuidV4(), img: idemiKarma18 },
  { imgId: uuidV4(), img: idemiKarma19 },
  { imgId: uuidV4(), img: idemiKarma20 },
];
const mahanaduImgs = [
  { imgId: uuidV4(), img: mahanadu1 },
  { imgId: uuidV4(), img: mahanadu2 },
  { imgId: uuidV4(), img: mahanadu3 },
  { imgId: uuidV4(), img: mahanadu4 },
  { imgId: uuidV4(), img: mahanadu5 },
  { imgId: uuidV4(), img: mahanadu6 },
  { imgId: uuidV4(), img: mahanadu7 },
  { imgId: uuidV4(), img: mahanadu8 },
  { imgId: uuidV4(), img: mahanadu9 },
  { imgId: uuidV4(), img: mahanadu10 },
  { imgId: uuidV4(), img: mahanadu11 },
  { imgId: uuidV4(), img: mahanadu12 },
  { imgId: uuidV4(), img: mahanadu13 },
  { imgId: uuidV4(), img: mahanadu14 },
  { imgId: uuidV4(), img: mahanadu15 },
  { imgId: uuidV4(), img: mahanadu16 },
  { imgId: uuidV4(), img: mahanadu17 },
  { imgId: uuidV4(), img: mahanadu18 },
  { imgId: uuidV4(), img: mahanadu19 },
  { imgId: uuidV4(), img: mahanadu20 },
  { imgId: uuidV4(), img: mahanadu21 },
  { imgId: uuidV4(), img: mahanadu22 },
  { imgId: uuidV4(), img: mahanadu23 },
  { imgId: uuidV4(), img: mahanadu24 },
  { imgId: uuidV4(), img: mahanadu25 },
  { imgId: uuidV4(), img: mahanadu26 },
  { imgId: uuidV4(), img: mahanadu27 },
  { imgId: uuidV4(), img: mahanadu28 },
  { imgId: uuidV4(), img: mahanadu29 },
  { imgId: uuidV4(), img: mahanadu30 },
  { imgId: uuidV4(), img: mahanadu31 },
  { imgId: uuidV4(), img: mahanadu32 },
  { imgId: uuidV4(), img: mahanadu33 },
];
const nlrMunicipalityImgs = [
  { imgId: uuidV4(), img: nlrMunicipality1 },
  { imgId: uuidV4(), img: nlrMunicipality2 },
  { imgId: uuidV4(), img: nlrMunicipality3 },
  { imgId: uuidV4(), img: nlrMunicipality4 },
  { imgId: uuidV4(), img: nlrMunicipality5 },
  { imgId: uuidV4(), img: nlrMunicipality6 },
  { imgId: uuidV4(), img: nlrMunicipality7 },
  { imgId: uuidV4(), img: nlrMunicipality8 },
  { imgId: uuidV4(), img: nlrMunicipality9 },
  { imgId: uuidV4(), img: nlrMunicipality10 },
  { imgId: uuidV4(), img: nlrMunicipality11 },
  { imgId: uuidV4(), img: nlrMunicipality12 },
  { imgId: uuidV4(), img: nlrMunicipality13 },
  { imgId: uuidV4(), img: nlrMunicipality14 },
  { imgId: uuidV4(), img: nlrMunicipality15 },
  { imgId: uuidV4(), img: nlrMunicipality16 },
  { imgId: uuidV4(), img: nlrMunicipality17 },
  { imgId: uuidV4(), img: nlrMunicipality18 },
  { imgId: uuidV4(), img: nlrMunicipality19 },
  { imgId: uuidV4(), img: nlrMunicipality20 },
  { imgId: uuidV4(), img: nlrMunicipality21 },
  { imgId: uuidV4(), img: nlrMunicipality22 },
  { imgId: uuidV4(), img: nlrMunicipality23 },
  { imgId: uuidV4(), img: nlrMunicipality24 },
  { imgId: uuidV4(), img: nlrMunicipality25 },
  { imgId: uuidV4(), img: nlrMunicipality26 },
];
const pressmeetImgs = [
  { imgId: uuidV4(), img: pressmeet1 },
  { imgId: uuidV4(), img: pressmeet2 },
  { imgId: uuidV4(), img: pressmeet3 },
  { imgId: uuidV4(), img: pressmeet4 },
  { imgId: uuidV4(), img: pressmeet5 },
  { imgId: uuidV4(), img: pressmeet6 },
  { imgId: uuidV4(), img: pressmeet7 },
  { imgId: uuidV4(), img: pressmeet8 },
  { imgId: uuidV4(), img: pressmeet9 },
  { imgId: uuidV4(), img: pressmeet10 },
  { imgId: uuidV4(), img: pressmeet11 },
  { imgId: uuidV4(), img: pressmeet12 },
  { imgId: uuidV4(), img: pressmeet13 },
  { imgId: uuidV4(), img: pressmeet14 },
  { imgId: uuidV4(), img: pressmeet15 },
  { imgId: uuidV4(), img: pressmeet16 },
  { imgId: uuidV4(), img: pressmeet17 },
  { imgId: uuidV4(), img: pressmeet18 },
  { imgId: uuidV4(), img: pressmeet19 },
  { imgId: uuidV4(), img: pressmeet20 },
  { imgId: uuidV4(), img: pressmeet21 },
  { imgId: uuidV4(), img: pressmeet22 },
  { imgId: uuidV4(), img: pressmeet23 },
  { imgId: uuidV4(), img: pressmeet24 },
  { imgId: uuidV4(), img: pressmeet25 },
  { imgId: uuidV4(), img: pressmeet26 },
  { imgId: uuidV4(), img: pressmeet27 },
  { imgId: uuidV4(), img: pressmeet28 },
  { imgId: uuidV4(), img: pressmeet29 },
  { imgId: uuidV4(), img: pressmeet30 },
  { imgId: uuidV4(), img: pressmeet31 },
  { imgId: uuidV4(), img: pressmeet32 },
  { imgId: uuidV4(), img: pressmeet33 },
  { imgId: uuidV4(), img: pressmeet34 },
  { imgId: uuidV4(), img: pressmeet35 },
  { imgId: uuidV4(), img: pressmeet36 },
  { imgId: uuidV4(), img: pressmeet37 },
  { imgId: uuidV4(), img: pressmeet38 },
  { imgId: uuidV4(), img: pressmeet39 },
  { imgId: uuidV4(), img: pressmeet40 },
  { imgId: uuidV4(), img: pressmeet41 },
  { imgId: uuidV4(), img: pressmeet42 },
  { imgId: uuidV4(), img: pressmeet43 },
  { imgId: uuidV4(), img: pressmeet44 },
  { imgId: uuidV4(), img: pressmeet45 },
  { imgId: uuidV4(), img: pressmeet46 },
  { imgId: uuidV4(), img: pressmeet47 },
  { imgId: uuidV4(), img: pressmeet48 },
  { imgId: uuidV4(), img: pressmeet49 },
  { imgId: uuidV4(), img: pressmeet50 },
  { imgId: uuidV4(), img: pressmeet51 },
  { imgId: uuidV4(), img: pressmeet52 },
  { imgId: uuidV4(), img: pressmeet53 },
  { imgId: uuidV4(), img: pressmeet54 },
  { imgId: uuidV4(), img: pressmeet55 },
  { imgId: uuidV4(), img: pressmeet56 },
  { imgId: uuidV4(), img: pressmeet57 },
  { imgId: uuidV4(), img: pressmeet58 },
  { imgId: uuidV4(), img: pressmeet59 },
  { imgId: uuidV4(), img: pressmeet60 },
  { imgId: uuidV4(), img: pressmeet61 },
  { imgId: uuidV4(), img: pressmeet62 },
  { imgId: uuidV4(), img: pressmeet63 },
  { imgId: uuidV4(), img: pressmeet64 },
  { imgId: uuidV4(), img: pressmeet65 },
  { imgId: uuidV4(), img: pressmeet66 },
  { imgId: uuidV4(), img: pressmeet67 },
  { imgId: uuidV4(), img: pressmeet68 },
  { imgId: uuidV4(), img: pressmeet69 },
  { imgId: uuidV4(), img: pressmeet70 },
  { imgId: uuidV4(), img: pressmeet71 },
  { imgId: uuidV4(), img: pressmeet72 },
  { imgId: uuidV4(), img: pressmeet73 },
  { imgId: uuidV4(), img: pressmeet74 },
  { imgId: uuidV4(), img: pressmeet75 },
  { imgId: uuidV4(), img: pressmeet76 },
  { imgId: uuidV4(), img: pressmeet77 },
  { imgId: uuidV4(), img: pressmeet78 },
  { imgId: uuidV4(), img: pressmeet79 },
  { imgId: uuidV4(), img: pressmeet80 },
];
const raithuPorubataImgs = [
  { imgId: uuidV4(), img: raithuPorubata1 },
  { imgId: uuidV4(), img: raithuPorubata2 },
  { imgId: uuidV4(), img: raithuPorubata3 },
  { imgId: uuidV4(), img: raithuPorubata4 },
  { imgId: uuidV4(), img: raithuPorubata5 },
  { imgId: uuidV4(), img: raithuPorubata6 },
  { imgId: uuidV4(), img: raithuPorubata7 },
  { imgId: uuidV4(), img: raithuPorubata8 },
  { imgId: uuidV4(), img: raithuPorubata9 },
  { imgId: uuidV4(), img: raithuPorubata10 },
  { imgId: uuidV4(), img: raithuPorubata11 },
  { imgId: uuidV4(), img: raithuPorubata12 },
  { imgId: uuidV4(), img: raithuPorubata13 },
  { imgId: uuidV4(), img: raithuPorubata14 },
  { imgId: uuidV4(), img: raithuPorubata15 },
  { imgId: uuidV4(), img: raithuPorubata16 },
  { imgId: uuidV4(), img: raithuPorubata17 },
  { imgId: uuidV4(), img: raithuPorubata18 },
  { imgId: uuidV4(), img: raithuPorubata19 },
  { imgId: uuidV4(), img: raithuPorubata20 },
];
const specialStatusImgs = [
  { imgId: uuidV4(), img: specialStatus1 },
  { imgId: uuidV4(), img: specialStatus2 },
  { imgId: uuidV4(), img: specialStatus3 },
  { imgId: uuidV4(), img: specialStatus4 },
  { imgId: uuidV4(), img: specialStatus5 },
  { imgId: uuidV4(), img: specialStatus6 },
  { imgId: uuidV4(), img: specialStatus7 },
  { imgId: uuidV4(), img: specialStatus8 },
  { imgId: uuidV4(), img: specialStatus9 },
  { imgId: uuidV4(), img: specialStatus10 },
  { imgId: uuidV4(), img: specialStatus11 },
  { imgId: uuidV4(), img: specialStatus12 },
  { imgId: uuidV4(), img: specialStatus13 },
  { imgId: uuidV4(), img: specialStatus14 },
  { imgId: uuidV4(), img: specialStatus15 },
  { imgId: uuidV4(), img: specialStatus16 },
  { imgId: uuidV4(), img: specialStatus17 },
  { imgId: uuidV4(), img: specialStatus18 },
  { imgId: uuidV4(), img: specialStatus19 },
  { imgId: uuidV4(), img: specialStatus20 },
  { imgId: uuidV4(), img: specialStatus21 },
  { imgId: uuidV4(), img: specialStatus22 },
  { imgId: uuidV4(), img: specialStatus23 },
  { imgId: uuidV4(), img: specialStatus24 },
  { imgId: uuidV4(), img: specialStatus25 },
  { imgId: uuidV4(), img: specialStatus26 },
  { imgId: uuidV4(), img: specialStatus27 },
  { imgId: uuidV4(), img: specialStatus28 },
  { imgId: uuidV4(), img: specialStatus29 },
  { imgId: uuidV4(), img: specialStatus30 },
  { imgId: uuidV4(), img: specialStatus31 },
  { imgId: uuidV4(), img: specialStatus32 },
  { imgId: uuidV4(), img: specialStatus33 },
  { imgId: uuidV4(), img: specialStatus34 },
  { imgId: uuidV4(), img: specialStatus35 },
  { imgId: uuidV4(), img: specialStatus36 },
  { imgId: uuidV4(), img: specialStatus37 },
  { imgId: uuidV4(), img: specialStatus38 },
  { imgId: uuidV4(), img: specialStatus39 },
  { imgId: uuidV4(), img: specialStatus40 },
  { imgId: uuidV4(), img: specialStatus41 },
  { imgId: uuidV4(), img: specialStatus42 },
  { imgId: uuidV4(), img: specialStatus43 },
  { imgId: uuidV4(), img: specialStatus44 },
  { imgId: uuidV4(), img: specialStatus45 },
  { imgId: uuidV4(), img: specialStatus46 },
  { imgId: uuidV4(), img: specialStatus47 },
  { imgId: uuidV4(), img: specialStatus48 },
  { imgId: uuidV4(), img: specialStatus49 },
  { imgId: uuidV4(), img: specialStatus50 },
  { imgId: uuidV4(), img: specialStatus51 },
  { imgId: uuidV4(), img: specialStatus52 },
  { imgId: uuidV4(), img: specialStatus53 },
  { imgId: uuidV4(), img: specialStatus54 },
  { imgId: uuidV4(), img: specialStatus55 },
  { imgId: uuidV4(), img: specialStatus56 },
  { imgId: uuidV4(), img: specialStatus57 },
  { imgId: uuidV4(), img: specialStatus58 },
  { imgId: uuidV4(), img: specialStatus59 },
  { imgId: uuidV4(), img: specialStatus60 },
  { imgId: uuidV4(), img: specialStatus61 },
  { imgId: uuidV4(), img: specialStatus62 },
  { imgId: uuidV4(), img: specialStatus63 },
  { imgId: uuidV4(), img: specialStatus64 },
  { imgId: uuidV4(), img: specialStatus65 },
  { imgId: uuidV4(), img: specialStatus66 },
  { imgId: uuidV4(), img: specialStatus67 },
  { imgId: uuidV4(), img: specialStatus68 },
  { imgId: uuidV4(), img: specialStatus69 },
  { imgId: uuidV4(), img: specialStatus70 },
  { imgId: uuidV4(), img: specialStatus71 },
  { imgId: uuidV4(), img: specialStatus72 },
  { imgId: uuidV4(), img: specialStatus73 },
  { imgId: uuidV4(), img: specialStatus74 },
  { imgId: uuidV4(), img: specialStatus75 },
  { imgId: uuidV4(), img: specialStatus76 },
  { imgId: uuidV4(), img: specialStatus77 },
  { imgId: uuidV4(), img: specialStatus78 },
  { imgId: uuidV4(), img: specialStatus79 },
  { imgId: uuidV4(), img: specialStatus80 },
  { imgId: uuidV4(), img: specialStatus81 },
  { imgId: uuidV4(), img: specialStatus82 },
  { imgId: uuidV4(), img: specialStatus83 },
  { imgId: uuidV4(), img: specialStatus84 },
  { imgId: uuidV4(), img: specialStatus85 },
  { imgId: uuidV4(), img: specialStatus86 },
];
const tptBiElectionImgs = [
  { imgId: uuidV4(), img: tptBiElection1 },
  { imgId: uuidV4(), img: tptBiElection2 },
  { imgId: uuidV4(), img: tptBiElection3 },
  { imgId: uuidV4(), img: tptBiElection4 },
  { imgId: uuidV4(), img: tptBiElection5 },
  { imgId: uuidV4(), img: tptBiElection6 },
  { imgId: uuidV4(), img: tptBiElection7 },
  { imgId: uuidV4(), img: tptBiElection8 },
  { imgId: uuidV4(), img: tptBiElection9 },
  { imgId: uuidV4(), img: tptBiElection10 },
  { imgId: uuidV4(), img: tptBiElection11 },
  { imgId: uuidV4(), img: tptBiElection12 },
  { imgId: uuidV4(), img: tptBiElection13 },
  { imgId: uuidV4(), img: tptBiElection14 },
  { imgId: uuidV4(), img: tptBiElection15 },
  { imgId: uuidV4(), img: tptBiElection16 },
  { imgId: uuidV4(), img: tptBiElection17 },
  { imgId: uuidV4(), img: tptBiElection18 },
  { imgId: uuidV4(), img: tptBiElection19 },
  { imgId: uuidV4(), img: tptBiElection20 },
  { imgId: uuidV4(), img: tptBiElection21 },
  { imgId: uuidV4(), img: tptBiElection22 },
  { imgId: uuidV4(), img: tptBiElection23 },
  { imgId: uuidV4(), img: tptBiElection24 },
  { imgId: uuidV4(), img: tptBiElection25 },
  { imgId: uuidV4(), img: tptBiElection26 },
  { imgId: uuidV4(), img: tptBiElection27 },
  { imgId: uuidV4(), img: tptBiElection28 },
  { imgId: uuidV4(), img: tptBiElection29 },
  { imgId: uuidV4(), img: tptBiElection30 },
  { imgId: uuidV4(), img: tptBiElection31 },
  { imgId: uuidV4(), img: tptBiElection32 },
  { imgId: uuidV4(), img: tptBiElection33 },
  { imgId: uuidV4(), img: tptBiElection34 },
  { imgId: uuidV4(), img: tptBiElection35 },
  { imgId: uuidV4(), img: tptBiElection36 },
  { imgId: uuidV4(), img: tptBiElection37 },
  { imgId: uuidV4(), img: tptBiElection38 },
  { imgId: uuidV4(), img: tptBiElection39 },
  { imgId: uuidV4(), img: tptBiElection40 },
  { imgId: uuidV4(), img: tptBiElection41 },
  { imgId: uuidV4(), img: tptBiElection42 },
];
const yuvagalamImgs = [
  { imgId: uuidV4(), img: yuvagalam1 },
  { imgId: uuidV4(), img: yuvagalam2 },
  { imgId: uuidV4(), img: yuvagalam3 },
  { imgId: uuidV4(), img: yuvagalam4 },
  { imgId: uuidV4(), img: yuvagalam5 },
  { imgId: uuidV4(), img: yuvagalam6 },
  { imgId: uuidV4(), img: yuvagalam7 },
  { imgId: uuidV4(), img: yuvagalam8 },
  { imgId: uuidV4(), img: yuvagalam9 },
  { imgId: uuidV4(), img: yuvagalam10 },
  { imgId: uuidV4(), img: yuvagalam11 },
  { imgId: uuidV4(), img: yuvagalam12 },
  { imgId: uuidV4(), img: yuvagalam13 },
  { imgId: uuidV4(), img: yuvagalam14 },
  { imgId: uuidV4(), img: yuvagalam15 },
  { imgId: uuidV4(), img: yuvagalam16 },
  { imgId: uuidV4(), img: yuvagalam17 },
  { imgId: uuidV4(), img: yuvagalam18 },
  { imgId: uuidV4(), img: yuvagalam19 },
  { imgId: uuidV4(), img: yuvagalam20 },
  { imgId: uuidV4(), img: yuvagalam21 },
  { imgId: uuidV4(), img: yuvagalam22 },
  { imgId: uuidV4(), img: yuvagalam23 },
  { imgId: uuidV4(), img: yuvagalam24 },
  { imgId: uuidV4(), img: yuvagalam25 },
  { imgId: uuidV4(), img: yuvagalam26 },
  { imgId: uuidV4(), img: yuvagalam27 },
  { imgId: uuidV4(), img: yuvagalam28 },
  { imgId: uuidV4(), img: yuvagalam29 },
  { imgId: uuidV4(), img: yuvagalam30 },
  { imgId: uuidV4(), img: yuvagalam31 },
  { imgId: uuidV4(), img: yuvagalam32 },
  { imgId: uuidV4(), img: yuvagalam33 },
  { imgId: uuidV4(), img: yuvagalam34 },
  { imgId: uuidV4(), img: yuvagalam35 },
  { imgId: uuidV4(), img: yuvagalam36 },
  { imgId: uuidV4(), img: yuvagalam37 },
  { imgId: uuidV4(), img: yuvagalam38 },
  { imgId: uuidV4(), img: yuvagalam39 },
  { imgId: uuidV4(), img: yuvagalam40 },
  { imgId: uuidV4(), img: yuvagalam41 },
  { imgId: uuidV4(), img: yuvagalam42 },
  { imgId: uuidV4(), img: yuvagalam43 },
  { imgId: uuidV4(), img: yuvagalam44 },
  { imgId: uuidV4(), img: yuvagalam45 },
  { imgId: uuidV4(), img: yuvagalam46 },
  { imgId: uuidV4(), img: yuvagalam47 },
];
const yuvanestamImgs = [
  { imgId: uuidV4(), img: yuvanestam1 },
  { imgId: uuidV4(), img: yuvanestam2 },
  { imgId: uuidV4(), img: yuvanestam3 },
  { imgId: uuidV4(), img: yuvanestam4 },
  { imgId: uuidV4(), img: yuvanestam5 },
  { imgId: uuidV4(), img: yuvanestam6 },
  { imgId: uuidV4(), img: yuvanestam7 },
  { imgId: uuidV4(), img: yuvanestam8 },
  { imgId: uuidV4(), img: yuvanestam9 },
  { imgId: uuidV4(), img: yuvanestam10 },
  { imgId: uuidV4(), img: yuvanestam11 },
  { imgId: uuidV4(), img: yuvanestam12 },
  { imgId: uuidV4(), img: yuvanestam13 },
  { imgId: uuidV4(), img: yuvanestam14 },
  { imgId: uuidV4(), img: yuvanestam15 },
  { imgId: uuidV4(), img: yuvanestam16 },
  { imgId: uuidV4(), img: yuvanestam17 },
  { imgId: uuidV4(), img: yuvanestam18 },
  { imgId: uuidV4(), img: yuvanestam19 },
  { imgId: uuidV4(), img: yuvanestam20 },
  { imgId: uuidV4(), img: yuvanestam21 },
  { imgId: uuidV4(), img: yuvanestam22 },
  { imgId: uuidV4(), img: yuvanestam23 },
  { imgId: uuidV4(), img: yuvanestam24 },
  { imgId: uuidV4(), img: yuvanestam25 },
  { imgId: uuidV4(), img: yuvanestam26 },
  { imgId: uuidV4(), img: yuvanestam27 },
  { imgId: uuidV4(), img: yuvanestam28 },
  { imgId: uuidV4(), img: yuvanestam29 },
  { imgId: uuidV4(), img: yuvanestam30 },
  { imgId: uuidV4(), img: yuvanestam31 },
  { imgId: uuidV4(), img: yuvanestam32 },
  { imgId: uuidV4(), img: yuvanestam33 },
  { imgId: uuidV4(), img: yuvanestam34 },
  { imgId: uuidV4(), img: yuvanestam35 },
  { imgId: uuidV4(), img: yuvanestam36 },
  { imgId: uuidV4(), img: yuvanestam37 },
  { imgId: uuidV4(), img: yuvanestam38 },
  { imgId: uuidV4(), img: yuvanestam39 },
  { imgId: uuidV4(), img: yuvanestam40 },
  { imgId: uuidV4(), img: yuvanestam41 },
  { imgId: uuidV4(), img: yuvanestam42 },
  { imgId: uuidV4(), img: yuvanestam43 },
  { imgId: uuidV4(), img: yuvanestam44 },
  { imgId: uuidV4(), img: yuvanestam45 },
  { imgId: uuidV4(), img: yuvanestam46 },
  { imgId: uuidV4(), img: yuvanestam47 },
  { imgId: uuidV4(), img: yuvanestam48 },
  { imgId: uuidV4(), img: yuvanestam49 },
  { imgId: uuidV4(), img: yuvanestam50 },
  { imgId: uuidV4(), img: yuvanestam51 },
  { imgId: uuidV4(), img: yuvanestam52 },
  { imgId: uuidV4(), img: yuvanestam53 },
  { imgId: uuidV4(), img: yuvanestam54 },
  { imgId: uuidV4(), img: yuvanestam55 },
  { imgId: uuidV4(), img: yuvanestam56 },
  { imgId: uuidV4(), img: yuvanestam57 },
  { imgId: uuidV4(), img: yuvanestam58 },
  { imgId: uuidV4(), img: yuvanestam59 },
  { imgId: uuidV4(), img: yuvanestam60 },
  { imgId: uuidV4(), img: yuvanestam61 },
  { imgId: uuidV4(), img: yuvanestam62 },
  { imgId: uuidV4(), img: yuvanestam63 },
  { imgId: uuidV4(), img: yuvanestam64 },
  { imgId: uuidV4(), img: yuvanestam65 },
  { imgId: uuidV4(), img: yuvanestam66 },
  { imgId: uuidV4(), img: yuvanestam67 },
  { imgId: uuidV4(), img: yuvanestam68 },
  { imgId: uuidV4(), img: yuvanestam69 },
  { imgId: uuidV4(), img: yuvanestam70 },
  { imgId: uuidV4(), img: yuvanestam71 },
  { imgId: uuidV4(), img: yuvanestam72 },
  { imgId: uuidV4(), img: yuvanestam73 },
  { imgId: uuidV4(), img: yuvanestam74 },
  { imgId: uuidV4(), img: yuvanestam75 },
  { imgId: uuidV4(), img: yuvanestam76 },
  { imgId: uuidV4(), img: yuvanestam77 },
  { imgId: uuidV4(), img: yuvanestam78 },
  { imgId: uuidV4(), img: yuvanestam79 },
  { imgId: uuidV4(), img: yuvanestam80 },
  { imgId: uuidV4(), img: yuvanestam81 },
  { imgId: uuidV4(), img: yuvanestam82 },
  { imgId: uuidV4(), img: yuvanestam83 },
  { imgId: uuidV4(), img: yuvanestam84 },
  { imgId: uuidV4(), img: yuvanestam85 },
  { imgId: uuidV4(), img: yuvanestam86 },
  { imgId: uuidV4(), img: yuvanestam87 },
  { imgId: uuidV4(), img: yuvanestam88 },
  { imgId: uuidV4(), img: yuvanestam89 },
  { imgId: uuidV4(), img: yuvanestam90 },
  { imgId: uuidV4(), img: yuvanestam91 },
  { imgId: uuidV4(), img: yuvanestam92 },
  { imgId: uuidV4(), img: yuvanestam93 },
  { imgId: uuidV4(), img: yuvanestam94 },
  { imgId: uuidV4(), img: yuvanestam95 },
  { imgId: uuidV4(), img: yuvanestam96 },
  { imgId: uuidV4(), img: yuvanestam97 },
  { imgId: uuidV4(), img: yuvanestam98 },
  { imgId: uuidV4(), img: yuvanestam99 },
  { imgId: uuidV4(), img: yuvanestam100 },
  { imgId: uuidV4(), img: yuvanestam101 },
  { imgId: uuidV4(), img: yuvanestam102 },
  { imgId: uuidV4(), img: yuvanestam103 },
  { imgId: uuidV4(), img: yuvanestam104 },
  { imgId: uuidV4(), img: yuvanestam105 },
  { imgId: uuidV4(), img: yuvanestam106 },
  { imgId: uuidV4(), img: yuvanestam107 },
  { imgId: uuidV4(), img: yuvanestam108 },
  { imgId: uuidV4(), img: yuvanestam109 },
  { imgId: uuidV4(), img: yuvanestam110 },
  { imgId: uuidV4(), img: yuvanestam111 },
  { imgId: uuidV4(), img: yuvanestam112 },
  { imgId: uuidV4(), img: yuvanestam113 },
  { imgId: uuidV4(), img: yuvanestam114 },
  { imgId: uuidV4(), img: yuvanestam115 },
  { imgId: uuidV4(), img: yuvanestam116 },
  { imgId: uuidV4(), img: yuvanestam117 },
  { imgId: uuidV4(), img: yuvanestam118 },
  { imgId: uuidV4(), img: yuvanestam119 },
  { imgId: uuidV4(), img: yuvanestam120 },
  { imgId: uuidV4(), img: yuvanestam121 },
  { imgId: uuidV4(), img: yuvanestam122 },
];
const bbbImages = [
  { imgId: uuidV4(), img: bb1 },
  { imgId: uuidV4(), img: bb2 },
  { imgId: uuidV4(), img: bb3 },
  { imgId: uuidV4(), img: bb4 },
  { imgId: uuidV4(), img: bb5 },
  { imgId: uuidV4(), img: bb6 },
  { imgId: uuidV4(), img: bb7 },
  { imgId: uuidV4(), img: bb8 },
  { imgId: uuidV4(), img: bb9 },
  { imgId: uuidV4(), img: bb10 },
  { imgId: uuidV4(), img: bb11 },
  { imgId: uuidV4(), img: bb12 },
  { imgId: uuidV4(), img: bb13 },
  { imgId: uuidV4(), img: bb14 },
  { imgId: uuidV4(), img: bb15 },
  { imgId: uuidV4(), img: bb16 },
  { imgId: uuidV4(), img: bb17 },
  { imgId: uuidV4(), img: bb18 },
  { imgId: uuidV4(), img: bb19 },
  { imgId: uuidV4(), img: bb20 },
  { imgId: uuidV4(), img: bb21 },
  { imgId: uuidV4(), img: bb22 },
  { imgId: uuidV4(), img: bb23 },
  { imgId: uuidV4(), img: bb24 },
  { imgId: uuidV4(), img: bb25 },
  { imgId: uuidV4(), img: bb26 },
  { imgId: uuidV4(), img: bb27 },
  { imgId: uuidV4(), img: bb28 },
  { imgId: uuidV4(), img: bb29 },
];
const yuvachaithanyaYathraImgs = [
  { imgId: uuidV4(), img: yuvachaithanyaYathra1 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra2 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra3 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra4 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra5 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra6 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra7 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra8 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra9 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra10 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra11 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra12 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra13 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra14 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra15 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra16 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra17 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra18 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra19 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra20 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra21 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra22 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra23 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra24 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra25 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra26 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra27 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra28 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra29 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra30 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra31 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra32 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra33 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra34 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra35 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra36 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra37 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra38 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra39 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra40 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra41 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra42 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra43 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra44 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra45 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra46 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra47 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra48 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra49 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra50 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra51 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra52 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra53 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra54 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra55 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra56 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra57 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra58 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra59 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra60 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra61 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra62 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra63 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra64 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra65 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra66 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra67 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra68 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra69 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra70 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra71 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra72 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra73 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra74 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra75 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra76 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra77 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra78 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra79 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra80 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra81 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra82 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra83 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra84 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra85 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra86 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra87 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra88 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra89 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra90 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra91 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra92 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra93 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra94 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra95 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra96 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra97 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra98 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra99 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra100 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra101 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra102 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra103 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra104 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra105 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra106 },
  { imgId: uuidV4(), img: yuvachaithanyaYathra107 },
];

//Community Imgs
const abdms1stAnniversaryImgs = [
  { imgId: uuidV4(), img: abdms1stAnniversary1 },
  { imgId: uuidV4(), img: abdms1stAnniversary2 },
  { imgId: uuidV4(), img: abdms1stAnniversary3 },
  { imgId: uuidV4(), img: abdms1stAnniversary4 },
  { imgId: uuidV4(), img: abdms1stAnniversary5 },
  { imgId: uuidV4(), img: abdms1stAnniversary6 },
  { imgId: uuidV4(), img: abdms1stAnniversary7 },
  { imgId: uuidV4(), img: abdms1stAnniversary8 },
];
const collectorOfficeNLRImgs = [
  { imgId: uuidV4(), img: collectorOfficeNLR1 },
  { imgId: uuidV4(), img: collectorOfficeNLR2 },
  { imgId: uuidV4(), img: collectorOfficeNLR3 },
];
const drycleaningwashGtrOpeningImgs = [
  { imgId: uuidV4(), img: drycleaningwashGtrOpening1 },
  { imgId: uuidV4(), img: drycleaningwashGtrOpening2 },
  { imgId: uuidV4(), img: drycleaningwashGtrOpening3 },
];
const gunturBooksDistributionImgs = [
  { imgId: uuidV4(), img: gunturBooksDistribution1 },
  { imgId: uuidV4(), img: gunturBooksDistribution2 },
  { imgId: uuidV4(), img: gunturBooksDistribution3 },
  { imgId: uuidV4(), img: gunturBooksDistribution4 },
  { imgId: uuidV4(), img: gunturBooksDistribution5 },
  { imgId: uuidV4(), img: gunturBooksDistribution6 },
  { imgId: uuidV4(), img: gunturBooksDistribution7 },
  { imgId: uuidV4(), img: gunturBooksDistribution8 },
  { imgId: uuidV4(), img: gunturBooksDistribution9 },
  { imgId: uuidV4(), img: gunturBooksDistribution10 },
  { imgId: uuidV4(), img: gunturBooksDistribution11 },
  { imgId: uuidV4(), img: gunturBooksDistribution12 },
  { imgId: uuidV4(), img: gunturBooksDistribution13 },
  { imgId: uuidV4(), img: gunturBooksDistribution14 },
  { imgId: uuidV4(), img: gunturBooksDistribution15 },
  { imgId: uuidV4(), img: gunturBooksDistribution16 },
  { imgId: uuidV4(), img: gunturBooksDistribution17 },
  { imgId: uuidV4(), img: gunturBooksDistribution18 },
  { imgId: uuidV4(), img: gunturBooksDistribution19 },
];
const helpingCommunityImgs = [
  { imgId: uuidV4(), img: helpingCommunity1 },
  { imgId: uuidV4(), img: helpingCommunity2 },
  { imgId: uuidV4(), img: helpingCommunity3 },
];
const ilammaVardhanthiImgs = [
  { imgId: uuidV4(), img: ilammaVardhanthi1 },
  { imgId: uuidV4(), img: ilammaVardhanthi2 },
  { imgId: uuidV4(), img: ilammaVardhanthi3 },
  { imgId: uuidV4(), img: ilammaVardhanthi4 },
  { imgId: uuidV4(), img: ilammaVardhanthi5 },
  { imgId: uuidV4(), img: ilammaVardhanthi6 },
  { imgId: uuidV4(), img: ilammaVardhanthi7 },
  { imgId: uuidV4(), img: ilammaVardhanthi8 },
  { imgId: uuidV4(), img: ilammaVardhanthi9 },
];
const kavaliTeamImgs = [
  { imgId: uuidV4(), img: kavaliTeam1 },
  { imgId: uuidV4(), img: kavaliTeam2 },
  { imgId: uuidV4(), img: kavaliTeam3 },
  { imgId: uuidV4(), img: kavaliTeam4 },
  { imgId: uuidV4(), img: kavaliTeam5 },
  { imgId: uuidV4(), img: kavaliTeam6 },
  { imgId: uuidV4(), img: kavaliTeam7 },
  { imgId: uuidV4(), img: kavaliTeam8 },
  { imgId: uuidV4(), img: kavaliTeam9 },
  { imgId: uuidV4(), img: kavaliTeam10 },
  { imgId: uuidV4(), img: kavaliTeam11 },
];
const nlrPoliticalApprictiationsImgs = [
  { imgId: uuidV4(), img: nlrPoliticalApprictiations1 },
  { imgId: uuidV4(), img: nlrPoliticalApprictiations2 },
  { imgId: uuidV4(), img: nlrPoliticalApprictiations3 },
  { imgId: uuidV4(), img: nlrPoliticalApprictiations4 },
  { imgId: uuidV4(), img: nlrPoliticalApprictiations5 },
];
const roundTable_RVS_GunturImgs = [
  { imgId: uuidV4(), img: roundTable_RVS_Guntur1 },
  { imgId: uuidV4(), img: roundTable_RVS_Guntur2 },
  { imgId: uuidV4(), img: roundTable_RVS_Guntur3 },
  { imgId: uuidV4(), img: roundTable_RVS_Guntur4 },
];
const roundTable_RVS_VijayawadaImgs = [
  { imgId: uuidV4(), img: roundTable_RVS_Vijayawada1 },
  { imgId: uuidV4(), img: roundTable_RVS_Vijayawada2 },
  { imgId: uuidV4(), img: roundTable_RVS_Vijayawada3 },
];
const roundTable_TirupatiImgs = [
  { imgId: uuidV4(), img: roundTable_Tirupati1 },
  { imgId: uuidV4(), img: roundTable_Tirupati2 },
  { imgId: uuidV4(), img: roundTable_Tirupati3 },
  { imgId: uuidV4(), img: roundTable_Tirupati4 },
];
const sss2021_2022Imgs = [
  { imgId: uuidV4(), img: sss2021_20221 },
  { imgId: uuidV4(), img: sss2021_20222 },
  { imgId: uuidV4(), img: sss2021_20223 },
  { imgId: uuidV4(), img: sss2021_20224 },
  { imgId: uuidV4(), img: sss2021_20225 },
];
const ssc2022_2023Imgs = [
  { imgId: uuidV4(), img: ssc2022_20231 },
  { imgId: uuidV4(), img: ssc2022_20232 },
  { imgId: uuidV4(), img: ssc2022_20233 },
  { imgId: uuidV4(), img: ssc2022_20234 },
  { imgId: uuidV4(), img: ssc2022_20235 },
  { imgId: uuidV4(), img: ssc2022_20236 },
  { imgId: uuidV4(), img: ssc2022_20237 },
  { imgId: uuidV4(), img: ssc2022_20238 },
  { imgId: uuidV4(), img: ssc2022_20239 },
  { imgId: uuidV4(), img: ssc2022_202310 },
  { imgId: uuidV4(), img: ssc2022_202311 },
  { imgId: uuidV4(), img: ssc2022_202312 },
  { imgId: uuidV4(), img: ssc2022_202313 },
  { imgId: uuidV4(), img: ssc2022_202314 },
  { imgId: uuidV4(), img: ssc2022_202315 },
];
const tdpMeetingsImgs = [
  { imgId: uuidV4(), img: tdpMeetings1 },
  { imgId: uuidV4(), img: tdpMeetings2 },
  { imgId: uuidV4(), img: tdpMeetings3 },
  { imgId: uuidV4(), img: tdpMeetings4 },
  { imgId: uuidV4(), img: tdpMeetings5 },
  { imgId: uuidV4(), img: tdpMeetings6 },
  { imgId: uuidV4(), img: tdpMeetings7 },
  { imgId: uuidV4(), img: tdpMeetings8 },
  { imgId: uuidV4(), img: tdpMeetings9 },
  { imgId: uuidV4(), img: tdpMeetings10 },
  { imgId: uuidV4(), img: tdpMeetings11 },
  { imgId: uuidV4(), img: tdpMeetings12 },
  { imgId: uuidV4(), img: tdpMeetings13 },
  { imgId: uuidV4(), img: tdpMeetings14 },
  { imgId: uuidV4(), img: tdpMeetings15 },
  { imgId: uuidV4(), img: tdpMeetings16 },
  { imgId: uuidV4(), img: tdpMeetings17 },
  { imgId: uuidV4(), img: tdpMeetings18 },
  { imgId: uuidV4(), img: tdpMeetings19 },
  { imgId: uuidV4(), img: tdpMeetings20 },
  { imgId: uuidV4(), img: tdpMeetings21 },
  { imgId: uuidV4(), img: tdpMeetings22 },
  { imgId: uuidV4(), img: tdpMeetings23 },
  { imgId: uuidV4(), img: tdpMeetings24 },
  { imgId: uuidV4(), img: tdpMeetings25 },
  { imgId: uuidV4(), img: tdpMeetings26 },
];
const vanabhojanaluImgs = [
  { imgId: uuidV4(), img: vanabhojanalu1 },
  { imgId: uuidV4(), img: vanabhojanalu2 },
  { imgId: uuidV4(), img: vanabhojanalu3 },
  { imgId: uuidV4(), img: vanabhojanalu4 },
  { imgId: uuidV4(), img: vanabhojanalu5 },
  { imgId: uuidV4(), img: vanabhojanalu6 },
  { imgId: uuidV4(), img: vanabhojanalu7 },
  { imgId: uuidV4(), img: vanabhojanalu8 },
  { imgId: uuidV4(), img: vanabhojanalu9 },
  { imgId: uuidV4(), img: vanabhojanalu10 },
  { imgId: uuidV4(), img: vanabhojanalu11 },
  { imgId: uuidV4(), img: vanabhojanalu12 },
  { imgId: uuidV4(), img: vanabhojanalu13 },
  { imgId: uuidV4(), img: vanabhojanalu14 },
  { imgId: uuidV4(), img: vanabhojanalu15 },
  { imgId: uuidV4(), img: vanabhojanalu16 },
  { imgId: uuidV4(), img: vanabhojanalu17 },
];
const vemavarapuVenkateswarluImgs = [
  { imgId: uuidV4(), img: vemavarapuVenkateswarlu1 },
  { imgId: uuidV4(), img: vemavarapuVenkateswarlu2 },
  { imgId: uuidV4(), img: vemavarapuVenkateswarlu3 },
  { imgId: uuidV4(), img: vemavarapuVenkateswarlu4 },
];
const vijayawada_BooksDistributionImgs = [
  { imgId: uuidV4(), img: vijayawada_BooksDistribution1 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution2 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution3 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution4 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution5 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution6 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution7 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution8 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution9 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution10 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution11 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution12 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution13 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution14 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution15 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution16 },
  { imgId: uuidV4(), img: vijayawada_BooksDistribution17 },
];
const ZzoomTeam1stMeetingImgs = [
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting1 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting2 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting3 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting4 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting5 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting6 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting7 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting8 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting9 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting10 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting11 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting12 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting13 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting14 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting15 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting16 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting17 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting18 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting19 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting20 },
  { imgId: uuidV4(), img: ZzoomTeam1stMeeting21 },
];

//Foundations
const gtr1Imgs = [
  { imgId: uuidV4(), img: gtr1_1 },
  { imgId: uuidV4(), img: gtr1_2 },
  { imgId: uuidV4(), img: gtr1_3 },
  { imgId: uuidV4(), img: gtr1_4 },
  { imgId: uuidV4(), img: gtr1_5 },
  { imgId: uuidV4(), img: gtr1_6 },
  { imgId: uuidV4(), img: gtr1_7 },
  { imgId: uuidV4(), img: gtr1_8 },
];
const gtr3Imgs = [
  { imgId: uuidV4(), img: gtr3_1 },
  { imgId: uuidV4(), img: gtr3_2 },
  { imgId: uuidV4(), img: gtr3_3 },
];
const kasturibhaschoolImgs = [
  { imgId: uuidV4(), img: kasturibhaschool1 },
  { imgId: uuidV4(), img: kasturibhaschool2 },
  { imgId: uuidV4(), img: kasturibhaschool3 },
  { imgId: uuidV4(), img: kasturibhaschool4 },
  { imgId: uuidV4(), img: kasturibhaschool5 },
  { imgId: uuidV4(), img: kasturibhaschool6 },
  { imgId: uuidV4(), img: kasturibhaschool7 },
  { imgId: uuidV4(), img: kasturibhaschool8 },
  { imgId: uuidV4(), img: kasturibhaschool9 },
  { imgId: uuidV4(), img: kasturibhaschool10 },
];
const kpdHighSchoolImgs = [
  { imgId: uuidV4(), img: kpdHighSchool1 },
  { imgId: uuidV4(), img: kpdHighSchool2 },
  { imgId: uuidV4(), img: kpdHighSchool3 },
  { imgId: uuidV4(), img: kpdHighSchool4 },
  { imgId: uuidV4(), img: kpdHighSchool5 },
  { imgId: uuidV4(), img: kpdHighSchool6 },
  { imgId: uuidV4(), img: kpdHighSchool7 },
];
const kdpModelSchoolImgs = [
  { imgId: uuidV4(), img: kdpModelSchool1 },
  { imgId: uuidV4(), img: kdpModelSchool2 },
];
const kommiImgs = [
  { imgId: uuidV4(), img: kommi1 },
  { imgId: uuidV4(), img: kommi2 },
  { imgId: uuidV4(), img: kommi3 },
  { imgId: uuidV4(), img: kommi4 },
  { imgId: uuidV4(), img: kommi5 },
  { imgId: uuidV4(), img: kommi6 },
  { imgId: uuidV4(), img: kommi7 },
  { imgId: uuidV4(), img: kommi8 },
  { imgId: uuidV4(), img: kommi9 },
  { imgId: uuidV4(), img: kommi10 },
];
const kommiZPHighschoolImgs = [
  { imgId: uuidV4(), img: kommiZPHighschool1 },
  { imgId: uuidV4(), img: kommiZPHighschool2 },
  { imgId: uuidV4(), img: kommiZPHighschool3 },
  { imgId: uuidV4(), img: kommiZPHighschool4 },
  { imgId: uuidV4(), img: kommiZPHighschool5 },
  { imgId: uuidV4(), img: kommiZPHighschool6 },
  { imgId: uuidV4(), img: kommiZPHighschool7 },
  { imgId: uuidV4(), img: kommiZPHighschool8 },
  { imgId: uuidV4(), img: kommiZPHighschool9 },
  { imgId: uuidV4(), img: kommiZPHighschool10 },
  { imgId: uuidV4(), img: kommiZPHighschool11 },
  { imgId: uuidV4(), img: kommiZPHighschool12 },
  { imgId: uuidV4(), img: kommiZPHighschool13 },
  { imgId: uuidV4(), img: kommiZPHighschool14 },
];
const krakuturZPSchoolImgs = [
  { imgId: uuidV4(), img: krakuturZPSchool1 },
  { imgId: uuidV4(), img: krakuturZPSchool2 },
  { imgId: uuidV4(), img: krakuturZPSchool3 },
  { imgId: uuidV4(), img: krakuturZPSchool4 },
  { imgId: uuidV4(), img: krakuturZPSchool5 },
  { imgId: uuidV4(), img: krakuturZPSchool6 },
  { imgId: uuidV4(), img: krakuturZPSchool7 },
];
const kummarakonduruImgs = [
  { imgId: uuidV4(), img: kummarakonduru1 },
  { imgId: uuidV4(), img: kummarakonduru2 },
  { imgId: uuidV4(), img: kummarakonduru3 },
  { imgId: uuidV4(), img: kummarakonduru4 },
  { imgId: uuidV4(), img: kummarakonduru5 },
  { imgId: uuidV4(), img: kummarakonduru6 },
];
const mikeSponserImgs = [
  { imgId: uuidV4(), img: mikeSponser1 },
  { imgId: uuidV4(), img: mikeSponser2 },
  { imgId: uuidV4(), img: mikeSponser3 },
  { imgId: uuidV4(), img: mikeSponser4 },
  { imgId: uuidV4(), img: mikeSponser5 },
  { imgId: uuidV4(), img: mikeSponser6 },
  { imgId: uuidV4(), img: mikeSponser7 },
  { imgId: uuidV4(), img: mikeSponser8 },
  { imgId: uuidV4(), img: mikeSponser9 },
  { imgId: uuidV4(), img: mikeSponser10 },
  { imgId: uuidV4(), img: mikeSponser11 },
];
const saipetaImgs = [
  { imgId: uuidV4(), img: saipeta1 },
  { imgId: uuidV4(), img: saipeta2 },
  { imgId: uuidV4(), img: saipeta3 },
  { imgId: uuidV4(), img: saipeta4 },
  { imgId: uuidV4(), img: saipeta5 },
  { imgId: uuidV4(), img: saipeta6 },
  { imgId: uuidV4(), img: saipeta7 },
  { imgId: uuidV4(), img: saipeta8 },
];
const schoolAnniversaryImgs = [
  { imgId: uuidV4(), img: schoolAnniversary1 },
  { imgId: uuidV4(), img: schoolAnniversary2 },
  { imgId: uuidV4(), img: schoolAnniversary3 },
  { imgId: uuidV4(), img: schoolAnniversary4 },
  { imgId: uuidV4(), img: schoolAnniversary5 },
  { imgId: uuidV4(), img: schoolAnniversary6 },
  { imgId: uuidV4(), img: schoolAnniversary7 },
  { imgId: uuidV4(), img: schoolAnniversary8 },
  { imgId: uuidV4(), img: schoolAnniversary9 },
  { imgId: uuidV4(), img: schoolAnniversary10 },
  { imgId: uuidV4(), img: schoolAnniversary11 },
  { imgId: uuidV4(), img: schoolAnniversary12 },
  { imgId: uuidV4(), img: schoolAnniversary13 },
  { imgId: uuidV4(), img: schoolAnniversary14 },
  { imgId: uuidV4(), img: schoolAnniversary15 },
  { imgId: uuidV4(), img: schoolAnniversary16 },
  { imgId: uuidV4(), img: schoolAnniversary17 },
  { imgId: uuidV4(), img: schoolAnniversary18 },
  { imgId: uuidV4(), img: schoolAnniversary19 },
  { imgId: uuidV4(), img: schoolAnniversary20 },
  { imgId: uuidV4(), img: schoolAnniversary21 },
  { imgId: uuidV4(), img: schoolAnniversary22 },
  { imgId: uuidV4(), img: schoolAnniversary23 },
  { imgId: uuidV4(), img: schoolAnniversary24 },
  { imgId: uuidV4(), img: schoolAnniversary25 },
  { imgId: uuidV4(), img: schoolAnniversary26 },
  { imgId: uuidV4(), img: schoolAnniversary27 },
];
const vijayawadaImgs = [
  { imgId: uuidV4(), img: vijayawada1 },
  { imgId: uuidV4(), img: vijayawada2 },
  { imgId: uuidV4(), img: vijayawada3 },
  { imgId: uuidV4(), img: vijayawada4 },
  { imgId: uuidV4(), img: vijayawada5 },
  { imgId: uuidV4(), img: vijayawada6 },
  { imgId: uuidV4(), img: vijayawada7 },
  { imgId: uuidV4(), img: vijayawada8 },
  { imgId: uuidV4(), img: vijayawada9 },
  { imgId: uuidV4(), img: vijayawada10 },
  { imgId: uuidV4(), img: vijayawada11 },
  { imgId: uuidV4(), img: vijayawada12 },
  { imgId: uuidV4(), img: vijayawada13 },
  { imgId: uuidV4(), img: vijayawada14 },
  { imgId: uuidV4(), img: vijayawada15 },
  { imgId: uuidV4(), img: vijayawada16 },
  { imgId: uuidV4(), img: vijayawada17 },
  { imgId: uuidV4(), img: vijayawada18 },
];

function Gallery() {
  const [seclectedTab, setSeclectedTab] = useState("Political");
  const [foldersList, setFoldersList] = useState(politicalFoldersList);

  const [imagesList, setImagesList] = useState(bbbImages);

  const [openedFolderName, setOpenedFolderName] = useState("");

  const [displayFolders, setDisplayFolders] = useState(true);
  const [displayImages, setDisplayImages] = useState(false);

  const handleClickImgsFolders = (value) => {
    setSeclectedTab(value);
    if (value === "Political") {
      setFoldersList(politicalFoldersList);
      setDisplayImages(false);
      setDisplayFolders(true);
    } else if (value === "Community") {
      setFoldersList(communityFoldersList);
      setDisplayImages(false);
      setDisplayFolders(true);
    } else if (value === "Services") {
      setFoldersList(servicesFoldersList);
      setDisplayImages(false);
      setDisplayFolders(true);
    }
    const wow = new WOW.WOW();
    wow.init();
  };

  const handleClickOnImagesFolder = (fldrName) => {
    const wow = new WOW.WOW();
    wow.init();
    setOpenedFolderName(fldrName);
    //Political
    if (fldrName === "Leaders") {
      setImagesList(leadersImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Important Persons") {
      setImagesList(impPersonsImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "2019 Election Campaign") {
      setImagesList(electionCampaign2019Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Amaravathi") {
      setImagesList(amaravathiImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Baadude Badudu") {
      setImagesList(baadudeBaduduImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "BC Hostel Visit") {
      setImagesList(bcHostelVisitImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "BC Round Tables") {
      setImagesList(bcRoundTablesImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Bhavishyat Guarantee Bus yatra") {
      setImagesList(bhavishyatGuaranteeBusyatraImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Bus Charges") {
      setImagesList(busChargesImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "CBN Dharmaporata Deeksha") {
      setImagesList(cbnDharmaporataDeekshaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Cycle Yatra") {
      setImagesList(cycleYatraImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Idemi Karma") {
      setImagesList(idemiKarmaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Mahanadu") {
      setImagesList(mahanaduImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "NLR Municipality") {
      setImagesList(nlrMunicipalityImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Pressmeet") {
      setImagesList(pressmeetImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Raithu Porubata") {
      setImagesList(raithuPorubataImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Special Status") {
      setImagesList(specialStatusImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "TPT Bi Election") {
      setImagesList(tptBiElectionImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Yuvagalam") {
      setImagesList(yuvagalamImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Yuvanestam") {
      setImagesList(yuvanestamImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Bring Babu Back") {
      setImagesList(bbbImages);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Yuvachaithanya Yathra") {
      setImagesList(yuvachaithanyaYathraImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    }

    //Community
    if (fldrName === "ABDMS 1st Anniversary") {
      setImagesList(abdms1stAnniversaryImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Collector Office NLR") {
      setImagesList(collectorOfficeNLRImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Drycleaning wash Gtr Opening") {
      setImagesList(drycleaningwashGtrOpeningImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Guntur_Books Distribution") {
      setImagesList(roundTable_RVS_GunturImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Helping Community") {
      setImagesList(helpingCommunityImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Ilamma Vardhanthi") {
      setImagesList(ilammaVardhanthiImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Kavali Team") {
      setImagesList(kavaliTeamImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "NLR_Political Apprictiations") {
      setImagesList(nlrPoliticalApprictiationsImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Round Table_RVS_Guntur") {
      setImagesList(roundTable_RVS_VijayawadaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Round Table_RVS_Vijayawada") {
      setImagesList(roundTable_RVS_VijayawadaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Round Table_Tirupati") {
      setImagesList(roundTable_TirupatiImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "SSC 2021-2022") {
      setImagesList(sss2021_2022Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "SSC 2022-2023") {
      setImagesList(ssc2022_2023Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "TDP Meetings") {
      setImagesList(tdpMeetingsImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Vanabhojanalu") {
      setImagesList(vanabhojanaluImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Vemavarapu Venkateswarlu") {
      setImagesList(vemavarapuVenkateswarluImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Vijayawada_Books Distribution") {
      setImagesList(vijayawada_BooksDistributionImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "ABDMS 1st Anniversary") {
      setImagesList(abdms1stAnniversaryImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Zoom Team Meeting") {
      setImagesList(ZzoomTeam1stMeetingImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    }

    // Foundations
    if (fldrName === "Gtr1") {
      setImagesList(gtr1Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Gtr3") {
      setImagesList(gtr3Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Kasturibha school") {
      setImagesList(kasturibhaschoolImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "KDP High School") {
      setImagesList(kpdHighSchoolImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "KDP Model School") {
      setImagesList(kdpModelSchoolImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Kommi") {
      setImagesList(kommiImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Kommi ZP High school") {
      setImagesList(kommiZPHighschoolImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Krakutur ZP School") {
      setImagesList(krakuturZPSchoolImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Kummarakonduru") {
      setImagesList(kummarakonduruImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Mike Sponser") {
      setImagesList(mikeSponserImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Saipeta") {
      setImagesList(saipetaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "School Anniversary") {
      setImagesList(schoolAnniversaryImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Vijayawada") {
      setImagesList(vijayawadaImgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    } else if (fldrName === "Gtr3") {
      setImagesList(gtr3Imgs);
      setDisplayImages(true);
      setDisplayFolders(false);
    }
  };

  const handleClickBackBtn = () => {
    setDisplayImages(false);
    setDisplayFolders(true);
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, [seclectedTab, imagesList]);

  return (
    <div>
      <HeaderAbout />
      <Header />

      <ul className="images-items">
        <li
          onClick={() => handleClickImgsFolders("Political")}
          className={seclectedTab === "Political" ? "images-items__active" : ""}
        >
          {/* "images-items__active" */}
          Political
        </li>
        <li
          className={seclectedTab === "Community" ? "images-items__active" : ""}
          onClick={() => handleClickImgsFolders("Community")}
        >
          Community
        </li>
        <li
          className={seclectedTab === "Services" ? "images-items__active" : ""}
          onClick={() => handleClickImgsFolders("Services")}
        >
          Foundations
        </li>
      </ul>

      {displayFolders && (
        <div className="center-div">
          <div className="folders-cont">
            {foldersList.map((eachFolder) => (
              <div
                key={eachFolder.folderId}
                onClick={() => handleClickOnImagesFolder(eachFolder.folderName)}
                className="folders-cont__folder"
              >
                <img
                  src={folder}
                  className="folders-cont__folder__img wow animate__jackInTheBox"
                  alt="folder"
                />
                <p>{eachFolder.folderName}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {displayImages && (
        <>
          <div className="center-div">
            <div className="try1">
              <img
                src={prevBtn}
                onClick={handleClickBackBtn}
                className="back-icon"
                alt="back-icon"
              />
              <div>
                <p className="selected-tab-text">
                  {" "}
                  {seclectedTab} / <span> {openedFolderName} </span>
                </p>
              </div>
              <div>
                <p>.</p>
              </div>
            </div>
          </div>

          <div className="center-div">
            <div className="images-container">
              {imagesList.map((eachImg) => (
                <div className="images-container__img-box wow animate__zoomIn">
                  <img
                    className="images-container__img-box__img "
                    loading="lazy"
                    src={eachImg.img}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
}

export default Gallery;
