import { Link } from "react-router-dom";
import img1 from "../../assets/1.jpg";
import img4 from "../../assets/4.jpg";
import img6 from "../../assets/6.jpg";
import mahanudu from "../../assets/mahanudu.jpg";
import yuvaghalam from "../../assets/yuvaghalam.png";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import HeaderAbout from "../../headerAbout/headerAbout";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import yuvaNestham1 from "./politicalImgs/yuvanestham1.jpeg";
// import yuvaNestham2 from "./politicalImgs/yuvanestham2.jpeg";
// import yuvaNestham3 from "./politicalImgs/yuvanestham3.jpeg";

// import specialStatus1 from "./politicalImgs/speshal-status1.jpeg";
// import specialStatus2 from "./politicalImgs/speshal-status2.jpeg";
// import specialStatus3 from "./politicalImgs/speshal-status3.jpeg";

//BBB

import bbb1 from "./politicalImgs/bbb (1).jpg";
import bbb2 from "./politicalImgs/bbb (3).JPG";
import bbb3 from "./politicalImgs/bbb (8).JPG";
import bbb4 from "./politicalImgs/bbb (14).JPG";
import bbb5 from "./politicalImgs/bbb (24).jpg";

//Palle Palliki Yuva Chaithanyayatra

import ppyy1 from "./politicalImgs/yuvachaithanya-yathra (1).jpg";
import ppyy2 from "./politicalImgs/yuvachaithanya-yathra (2).jpg";
import ppyy3 from "./politicalImgs/yuvachaithanya-yathra (8).jpg";
import ppyy4 from "./politicalImgs/yuvachaithanya-yathra (28).jpg";

//Yuva Galam

import yuvagalam1 from "./Yuvagalam/Yuvagalam (1).jpg";
import yuvagalam2 from "./Yuvagalam/Yuvagalam (2).jpg";
import yuvagalam3 from "./Yuvagalam/Yuvagalam (3).jpg";
import yuvagalam4 from "./Yuvagalam/Yuvagalam (4).jpg";
import yuvagalam5 from "./Yuvagalam/Yuvagalam (5).jpg";
import yuvagalam6 from "./Yuvagalam/Yuvagalam (6).jpg";
import yuvagalam7 from "./Yuvagalam/Yuvagalam (7).jpg";
import yuvagalam8 from "./Yuvagalam/Yuvagalam (8).jpg";

//Yuva Nestham

import yuvanestam1 from "./Yuvanestam/Yuvanestam (1).jpg";
import yuvanestam2 from "./Yuvanestam/Yuvanestam (2).jpg";
import yuvanestam3 from "./Yuvanestam/Yuvanestam (3).jpg";
import yuvanestam4 from "./Yuvanestam/Yuvanestam (4).jpg";
import yuvanestam5 from "./Yuvanestam/Yuvanestam (5).jpg";
import yuvanestam6 from "./Yuvanestam/Yuvanestam (6).jpg";
import yuvanestam7 from "./Yuvanestam/Yuvanestam (7).jpg";
import yuvanestam8 from "./Yuvanestam/Yuvanestam (8).jpg";

//Special Status

import specialStatus1 from "./Special Status/Special Status (1).jpg";
import specialStatus2 from "./Special Status/Special Status (2).jpg";
import specialStatus3 from "./Special Status/Special Status (3).jpg";
import specialStatus4 from "./Special Status/Special Status (4).jpg";
import specialStatus5 from "./Special Status/Special Status (5).jpg";
import specialStatus6 from "./Special Status/Special Status (6).jpg";
import specialStatus7 from "./Special Status/Special Status (7).jpg";
import specialStatus8 from "./Special Status/Special Status (8).jpg";

//Mahanadu

import mahanadu1 from "./Mahanadu/Mahanadu (1).jpg";
import mahanadu2 from "./Mahanadu/Mahanadu (2).jpg";
import mahanadu3 from "./Mahanadu/Mahanadu (3).jpg";
import mahanadu4 from "./Mahanadu/Mahanadu (4).jpg";
import mahanadu5 from "./Mahanadu/Mahanadu (5).jpg";
import mahanadu6 from "./Mahanadu/Mahanadu (6).jpg";
import mahanadu7 from "./Mahanadu/Mahanadu (7).JPG";
import mahanadu8 from "./Mahanadu/Mahanadu (8).jpg";

//Press Meet

import pressmeet1 from "./Pressmeet/Pressmeet (1).jpg";
import pressmeet2 from "./Pressmeet/Pressmeet (2).jpg";
import pressmeet3 from "./Pressmeet/Pressmeet (3).jpg";
import pressmeet4 from "./Pressmeet/Pressmeet (4).jpg";
import pressmeet5 from "./Pressmeet/Pressmeet (5).jpg";
import pressmeet6 from "./Pressmeet/Pressmeet (6).jpg";
import pressmeet7 from "./Pressmeet/Pressmeet (7).jpg";
import pressmeet8 from "./Pressmeet/Pressmeet (8).jpg";

//Badude Badudu
import baadudeBadudu1 from "./Baadude Badudu/baadude-badudu (1).jpg";
import baadudeBadudu2 from "./Baadude Badudu/baadude-badudu (2).jpg";
import baadudeBadudu3 from "./Baadude Badudu/baadude-badudu (3).jpg";
import baadudeBadudu4 from "./Baadude Badudu/baadude-badudu (4).jpg";

//Idemi Karma

import idemiKarma1 from "./IdemiKarma/IdemiKarma (1).jpg";
import idemiKarma2 from "./IdemiKarma/IdemiKarma (2).jpg";
import idemiKarma3 from "./IdemiKarma/IdemiKarma (3).jpg";
import idemiKarma4 from "./IdemiKarma/IdemiKarma (4).jpg";

import WOW from "wowjs";
import { useEffect } from "react";

function Political() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="heading-direction-cont">
        <h1 className="heading-primary">Political</h1>
      </div>
      <div className="events">
        <div className="">
          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={yuvagalam1} />
                  </div>
                  <div>
                    <img className="" src={yuvagalam2} />
                  </div>
                  <div>
                    <img className="" src={yuvagalam3} />
                  </div>

                  <div>
                    <img className="" src={yuvagalam4} />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>TDP Yuvaghalam</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={yuvagalam1} />
                  </div>
                  <div>
                    <img className="" src={yuvagalam2} />
                  </div>
                  <div>
                    <img className="" src={yuvagalam3} />
                  </div>

                  <div>
                    <img className="" src={yuvagalam4} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Yuva Galam (Lit: ‘Voice of the Youth’), is a mass contact
                movement led by Telugu Desam Party popularly known as TDP.
                National General Secretary of TDP Nara Lokesh is undertaking the
                walkathon to engage the youth of Andhra Pradesh, encourage them
                to become active participants in the state's political process
                and join in him in raising their voice fearlessly.
              </p>
            </div>
          </div>

          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Yuva Nestham</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={yuvanestam1} />
                  </div>
                  <div>
                    <img className="" src={yuvanestam2} />
                  </div>
                  <div>
                    <img className="" src={yuvanestam3} />
                  </div>

                  <div>
                    <img className="" src={yuvanestam4} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                "TDP National president N. Chandrababu Naidu said that NTR
                proved that a common man can reach great heights".The Telugu
                Desam Party (TDP) rank and file paid rich tributes to party’s
                founder president N.T. Rama Rao on the occasion of his 26th
                death anniversary on Tuesday. TDP Andhra Pradesh State president
                K. Atchannaidu garlanded the statue of NTR at the party office
                at Mangalagiri near Vijayawada on Tuesday.In a statement, TDP
                National president N. Chandrababu Naidu said that NTR proved
                that a common man can reach great heights and achieve success.
                Born in a farmer’s family, NTR brought laurels to the Telugus.
                He was the uncrowned king in the film industry, and matchless
                political leader. His journey with a slogan- pride of Telugus
                was unforgettable. NTR came to power within 9 months of founding
                the party. NTR has shown the power was not for enjoyment but to
                serve the people. The power which was limited to only a few
                sections of the society was brought to the reach of downtrodden.
                NTR laid foundations for the welfare schemes, he recalled.
              </p>
            </div>
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={yuvanestam1} />
                  </div>
                  <div>
                    <img className="" src={yuvanestam2} />
                  </div>
                  <div>
                    <img className="" src={yuvanestam3} />
                  </div>

                  <div>
                    <img className="" src={yuvanestam4} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={specialStatus1} />
                  </div>
                  <div>
                    <img className="" src={specialStatus2} />
                  </div>
                  <div>
                    <img className="" src={specialStatus3} />
                  </div>

                  <div>
                    <img className="" src={specialStatus4} />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>Special Status for Andhra Pradesh</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={specialStatus1} />
                    </div>
                    <div>
                      <img className="" src={specialStatus2} />
                    </div>
                    <div>
                      <img className="" src={specialStatus3} />
                    </div>

                    <div>
                      <img className="" src={specialStatus4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                He talk about how to use these platforms effectively for
                outreach and promotion of protest actions. In addition, He give
                practical advise on how to work around censorship and shadow
                bans.Connectivity is among the most significant benefits of
                social media. It can link countless users at any time,
                everywhere. Information could be spread globally through social
                media and its connectedness, making it simple for people to
                interact with one another. It results in global relationships.
              </p>
            </div>
          </div>

          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Bring Babu Back</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={bbb1} />
                    </div>
                    <div>
                      <img className="" src={bbb2} />
                    </div>
                    <div>
                      <img className="" src={bbb3} />
                    </div>

                    <div>
                      <img className="" src={bbb4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                The Bring Babu Back programme was organized under the aegis of
                TSNV for rallies and meetings with the support of youth in all
                the mandals of the constituency. Under the aegis of TSNV, the
                voter registration programme was conducted from the village
                level to the constituency nthai and new voters were registered.
                This contributed to the 2014 party's victory in the
                constituency. As per the instructions of Srinara Lokesh, we have
                been able to take up the party's membership drive in the
                constituency and include more people as members.
              </p>
            </div>
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={bbb1} />
                  </div>
                  <div>
                    <img className="" src={bbb2} />
                  </div>
                  <div>
                    <img className="" src={bbb3} />
                  </div>

                  <div>
                    <img className="" src={bbb4} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={ppyy1} />
                  </div>
                  <div>
                    <img className="" src={ppyy2} />
                  </div>
                  <div>
                    <img className="" src={ppyy3} />
                  </div>

                  <div>
                    <img className="" src={ppyy4} />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>Palle Palliki Yuva Chaithanyayatra</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={ppyy1} />
                    </div>
                    <div>
                      <img className="" src={ppyy2} />
                    </div>
                    <div>
                      <img className="" src={ppyy3} />
                    </div>

                    <div>
                      <img className="" src={ppyy4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                Raghavendra Ghanjam In the 2014 general elections, with the aim
                of attracting the youth and the poor from the village level to
                the Telugu Desam Party, we undertook a program called "Palle
                Palleki Yuva Chaitanya Yatra" and were able to bring about a
                change in the people by going to every door of the constituency,
                distributing pamphlets, explaining the governance and schemes of
                the Telugu Desam Party to every door of the constituency. The
                programme titled "Yuva Chaitanya Yatra to Palle Palliki", party
                president Gou! It was brought to the notice of Shri N.
                Chandrababu Naidu and with his approval and blessings, it was
                launched for the first time in the state in Udayagiri
                constituency. The programme was conducted from 04-01-2014 to
                04-04-2014.
              </p>
            </div>
          </div>
          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>TDP Press Meetings</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={pressmeet1} />
                    </div>
                    <div>
                      <img className="" src={pressmeet2} />
                    </div>
                    <div>
                      <img className="" src={pressmeet3} />
                    </div>

                    <div>
                      <img className="" src={pressmeet4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                The stage is set for the two-day Telugu Desham Party's(TDP)
                Mahanadu. and birth centenary of party founder and actor
                N.T.Rama Rao, at vemagiri on the outskrits of Rajamahendravaram
                on May 27. Chandrababu Naidu holds meeting with TDP MPs for
                upcoming parliament session
              </p>
            </div>
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={pressmeet1} />
                  </div>
                  <div>
                    <img className="" src={pressmeet2} />
                  </div>
                  <div>
                    <img className="" src={pressmeet3} />
                  </div>

                  <div>
                    <img className="" src={pressmeet4} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={mahanadu1} />
                  </div>
                  <div>
                    <img className="" src={mahanadu2} />
                  </div>
                  <div>
                    <img className="" src={mahanadu3} />
                  </div>

                  <div>
                    <img className="" src={mahanadu4} />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>TDP Mahanadu</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={mahanadu1} />
                    </div>
                    <div>
                      <img className="" src={mahanadu2} />
                    </div>
                    <div>
                      <img className="" src={mahanadu3} />
                    </div>

                    <div>
                      <img className="" src={mahanadu4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                The Telugu Desam Party (TDP) is gearing up to organise from
                March 28 a series of programmes to “take forward the spirit and
                enthusiasm apparently generated following the winning of an MLC
                seat under MLA quota. The party will organise victory
                celebrations and follow it up with agitations on people’s
                issues. In his inaugural address at the TDP's annual conclave
                Mahanadu here, Naidu gave the call "Quit Jagan - Save Andhra
                Pradesh", exhorting people to vote out the "scandalous and
                ruinous" regime in the state.
              </p>
            </div>
          </div>

          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Baadude Badudu</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={baadudeBadudu1} />
                    </div>
                    <div>
                      <img className="" src={baadudeBadudu2} />
                    </div>
                    <div>
                      <img className="" src={baadudeBadudu3} />
                    </div>

                    <div>
                      <img className="" src={baadudeBadudu4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p className="u-margin-bottom-small">
                The stage is set for the two-day Telugu Desham Party's(TDP)
                Mahanudu and birth centenary of party founder and actor N.T.Rama
                Rao, at vemagiri on the outskrits of Rajamahendravaram on May
                27. Chandrababu Naidu holds meeting with TDP MPs for upcoming
                parliament session
              </p>
            </div>
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={baadudeBadudu1} />
                  </div>
                  <div>
                    <img className="" src={baadudeBadudu2} />
                  </div>
                  <div>
                    <img className="" src={baadudeBadudu3} />
                  </div>

                  <div>
                    <img className="" src={baadudeBadudu4} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <div>
                <Slider {...settings}>
                  <div>
                    <img className="" src={idemiKarma1} />
                  </div>
                  <div>
                    <img className="" src={idemiKarma2} />
                  </div>
                  <div>
                    <img className="" src={idemiKarma3} />
                  </div>

                  <div>
                    <img className="" src={idemiKarma4} />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>Idemi Karma</h1>
              <div className="sm-fade-slider">
                <div>
                  <Slider {...settings}>
                    <div>
                      <img className="" src={idemiKarma1} />
                    </div>
                    <div>
                      <img className="" src={idemiKarma2} />
                    </div>
                    <div>
                      <img className="" src={idemiKarma3} />
                    </div>

                    <div>
                      <img className="" src={idemiKarma4} />
                    </div>
                  </Slider>
                </div>
              </div>
              <p>
                The Telugu Desam Party (TDP) is gearing up to organise from
                March 28 a series of programmes to “take forward the spirit and
                enthusiasm apparently generated following the winning of an MLC
                seat under MLA quota. The party will organise victory
                celebrations and follow it up with agitations on people’s
                issues. In his inaugural address at the TDP's annual conclave
                Mahanadu here, Naidu gave the call "Quit Jagan - Save Andhra
                Pradesh", exhorting people to vote out the "scandalous and
                ruinous" regime in the state.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Political;
