import "./App.css";
import "./sass/main.scss";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import "./animate.css";
import HomePage from "./components/homePage/homepage";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutMe from "./components/aboutMe/aboutMe";
import NewsMedia from "./components/newsMedia/news-media";
import Events from "./components/events/events";
import Gallery from "./components/images/images";
import Videos from "./components/vedios/vedios";
import Political from "./components/activities/political/political";
import Community from "./components/activities/community/community";
import Trust from "./components/activities/Trust/trust";
import Contact from "./components/contact/contact";
import MyLeaders from "./components/myLeaders/myLeaders";

import { useEffect } from "react";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

function App() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className="">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/aboutMe" Component={AboutMe} />
          <Route path="/services" Component={NewsMedia} />
          <Route path="/events" Component={Events} />
          <Route path="/images" Component={Gallery} />
          <Route path="/videos" Component={Videos} />
          <Route path="/activities/political" Component={Political} />
          <Route path="/activities/Community" Component={Community} />
          <Route path="/activities/trust" Component={Trust} />
          <Route path="/contact" Component={Contact} />
          <Route path="/leaders" Component={MyLeaders} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// // <section className="grid-test">
// // <div className="row">
// //   <div className="col-1-of-2">Col 1 of 2</div>
// //   <div className="col-1-of-2">Col 1 of 2</div>
// // </div>
// // <div className="row">
// //   <div className="col-1-of-3">Col 1 of 3</div>
// //   <div className="col-1-of-3">Col 1 of 3</div>
// //   <div className="col-1-of-3">Col 1 of 3</div>
// // </div>
// // <div className="row">
// //   <div className="col-1-of-3">Col 1 of 3</div>
// //   <div className="col-2-of-3">Col 2 of 3</div>
// // </div>
// // <div className="row">
// //   <div className="col-1-of-4">Col 1 of 4</div>
// //   <div className="col-1-of-4">Col 1 of 4</div>
// //   <div className="col-1-of-4">Col 1 of 4</div>
// //   <div className="col-1-of-4">Col 1 of 4</div>
// // </div>
// // <div className="row">
// //   <div className="col-1-of-4">Col 1 of 4</div>
// //   <div className="col-1-of-4">Col 1 of 4</div>
// //   <div className="col-2-of-4">Col 2 of 4</div>
// // </div>
// // <div className="row">
// //   <div className="col-1-of-4">Col 1 of 4</div>

// //   <div className="col-3-of-4">Col 3 of 4</div>
// // </div>
// // </section>
// import React, { useEffect } from "react";
// import WOW from "wowjs";
// import "animate.css/animate.min.css";
// import "./animate.css";

// function AnimatedComponent() {
//   useEffect(() => {
//     const wow = new WOW.WOW();
//     wow.init();
//   }, []);

//   return (
//     <div>
//       <h1 className="wow fadeIn" data-wow-duration="2s" data-wow-delay="0.5s">
//         Welcome to My React App
//       </h1>
//       <p className="wow bounce" data-wow-iteration="3">
//         This paragraph will bounce three times.
//       </p>
//     </div>
//   );
// }

// export default AnimatedComponent;
