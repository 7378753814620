import { Link } from "react-router-dom";
import img1 from "../../assets/1.jpg";
import img4 from "../../assets/4.jpg";
import img6 from "../../assets/6.jpg";
import mahanudu from "../../assets/mahanudu.jpg";
import yuvaghalam from "../../assets/yuvaghalam.png";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import HeaderAbout from "../../headerAbout/headerAbout";

function Trust() {
  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="heading-direction-cont">
        <h1 className="heading-primary ">Trust</h1>
      </div>
      <div className="events">
        <div className="row">
          <div className="events__card-and-details">
            <div>
              <img
                className="events__card-and-details__img"
                src={yuvaghalam}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              />
            </div>
            <div className="events__card-and-details__card-content">
              <h1>TDP Yuvaghalam</h1>
              <p>
                Yuva Galam (Lit: ‘Voice of the Youth’), is a mass contact
                movement led by Telugu Desam Party popularly known as TDP.
                National General Secretary of TDP Nara Lokesh is undertaking the
                walkathon to engage the youth of Andhra Pradesh, encourage them
                to become active participants in the state's political process
                and join in him in raising their voice fearlessly.
              </p>
            </div>
          </div>
          <div className="events__card-and-details2">
            <div className="events__card-and-details__card-content">
              <h1>TDP Press Meetings</h1>
              <p>
                The stage is set for the two-day Telugu Desham Party's(TDP)
                Mahanudu and birth centenary of party founder and actor N.T.Rama
                Rao, at vemagiri on the outskrits of Rajamahendravaram on May
                27. Chandrababu Naidu holds meeting with TDP MPs for upcoming
                parliament session
              </p>
            </div>
            <div>
              <img
                className="events__card-and-details__img"
                src={img4}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div className="events__card-and-details">
            <div>
              <img
                className="events__card-and-details__img"
                src={mahanudu}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              />
            </div>
            <div className="events__card-and-details__card-content">
              <h1>TDP Mahanudu</h1>
              <p>
                The Telugu Desam Party (TDP) is gearing up to organise from
                March 28 a series of programmes to “take forward the spirit and
                enthusiasm apparently generated following the winning of an MLC
                seat under MLA quota. The party will organise victory
                celebrations and follow it up with agitations on people’s
                issues.
              </p>
              <p>
                In his inaugural address at the TDP's annual conclave Mahanadu
                here, Naidu gave the call "Quit Jagan - Save Andhra Pradesh",
                exhorting people to vote out the "scandalous and ruinous" regime
                in the state.
              </p>
            </div>
          </div>
          <div className="events__card-and-details2">
            <div className="events__card-and-details__card-content">
              <h1>TDP Meetings</h1>
              <p>
                Chandra Babu Naidu being welcomed on his arrival for the party’s
                Mahanadu, in Rajamahendravaram on Friday. The stage is set for
                the two-day Telugu Desam Party’s (TDP) Mahanadu and birth
                centenary celebrations of party founder and actor N.T. Rama Rao,
                at Vemagiri on the outskirts of Rajamahendravaram on May 27.
              </p>
            </div>
            <div>
              <img
                className="events__card-and-details__img"
                src={img6}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Trust;
