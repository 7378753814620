import "../../sass/main.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer">
      <div className="row u-text-center">
        <h1 className="footer__heading">Raghavendra Ghanjam</h1>
        <div className="footer__social-icons">
          <div className="footer__social-icons__icon">
            <a href="#">
              <FaInstagram />
            </a>
          </div>
          <div className="footer__social-icons__icon">
            <a href="#">
              {/* <FontAwesomeIcon icon={faUser} /> */}
              <FaLinkedin />
            </a>
          </div>
          <div className="footer__social-icons__icon">
            <a href="#">
              {/* <FontAwesomeIcon icon={faUser} /> */}
              <FaFacebook />
            </a>
          </div>
          <div className="footer__social-icons__icon">
            <a href="#">
              {/* <FontAwesomeIcon icon={faUser} /> */}
              <FaTwitter />
            </a>
          </div>
        </div>
        <hr />
        <p className="u-text-center">Designed by Shiva Reddy & Shiva Aade</p>
      </div>
    </div>
  );
}

export default Footer;

{
  /* <div className="row">
        <div className="col-1-of-4">
          <h1>Xtyle</h1>
          <p>Enter some details Enter some details Enter some details </p>
        </div>
        <div className="col-1-of-4">
          <h3 className="footer__headings">
            Office{" "}
            <div className="underline">
              
            </div>
          </h3>
          <p>ITPL Road</p>
          <p>Whitefeild,Banglore</p>
          <p>Karnataka, 560066,India</p>
          <p className="footer__email">email</p>
          <p>+91 123456789</p>
        </div>
        <div className="col-1-of-4">
          <h3 className="footer__headings">
            Office{" "}
            <div className="underline">
              
            </div>
          </h3>
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">Home</a>
            </li>
          </ul>
        </div>
        <div className="col-1-of-4">
          <h3 className="footer__headings">
            Office
            <div className="underline">
              
            </div>
          </h3>
          <form className="footer__form">
            <p>Icon</p>
            <input type="text" placeholder="Enter your email id" required />
            <button type="submit">
              <p>icon</p>
            </button>
          </form>
          <div className="footer__social-icons">
            <div className="footer__social-icons__icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="footer__social-icons__icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="footer__social-icons__icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="footer__social-icons__icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
        </div>
      </div> */
}
