import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";

function HeaderAbout() {
  return (
    <div className="top-header">
      <h3>
        Raghavendra Ghanjam - State Official Spokes Person & Social Media
        Convener , TDP BC Cell
      </h3>
      <div className="top-header__social-icons">
        <h3>Follow</h3>
        <div className="top-header__social-icons__cont">
          <FaInstagram className="top-header__social-icons__cont__icon" />
          <FaLinkedin className="top-header__social-icons__cont__icon" />
          <FaFacebook className="top-header__social-icons__cont__icon" />
          <FaTwitter className="top-header__social-icons__cont__icon" />
        </div>
      </div>
    </div>
  );
}

export default HeaderAbout;
