import React, { useEffect, useRef } from "react";
import "./myleaders.css";
import Header from "../header/header";
import HeaderAbout from "../headerAbout/headerAbout";
import Footer from "../footer/footer.js";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// blog/src/components/myLeaders/leadersPhotos/yuvachaithanya-yathra (5).jpg
// import cbn1 from "./leadersPhotos/yuvachaithanya-yathra (5).jpg";
// import cbn2 from "./leadersPhotos/yuvachaithanya-yathra (7).jpg";
// import cbn3 from "./leadersPhotos/yuvachaithanya-yathra (99).JPG";
// import cbn4 from "./leadersPhotos/yuvachaithanya-yathra (100).jpg";

import cbn1 from "./leadersPhotos/CBN (1).jpg";
import cbn2 from "./leadersPhotos/CBN (4).jpg";
import cbn3 from "./leadersPhotos/CBN (6).jpg";
import cbn4 from "./leadersPhotos/CBN (9).jpg";
import cbn5 from "./leadersPhotos/CBN (12).jpg";
import cbn6 from "./leadersPhotos/CBN (13).jpg";
// import cbn7 from "./leadersPhotos/CBN (1).jpg";

// import lokesh1 from "./leadersPhotos/yuvachaithanya-yathra (101).jpg";
// import lokesh2 from "./leadersPhotos/yuvachaithanya-yathra (102).jpg";
// import lokesh3 from "./leadersPhotos/lokesh1.jpeg";
// import lokesh4 from "./leadersPhotos/lokesh2.jpeg";
// import lokesh5 from "./leadersPhotos/lokesh3.jpeg";
// import lokesh6 from "./leadersPhotos/lokesh4.jpeg";
import lokesh1 from "./leadersPhotos/Lokesh (1).jpg";
import lokesh2 from "./leadersPhotos/Lokesh (4).jpg";
import lokesh3 from "./leadersPhotos/Lokesh (7).jpg";
import lokesh4 from "./leadersPhotos/Lokesh (11).jpg";
import lokesh5 from "./leadersPhotos/Lokesh (13).jpg";
import lokesh6 from "./leadersPhotos/Lokesh (16).jpg";
import lokesh7 from "./leadersPhotos/Lokesh (17).jpg";
import lokesh8 from "./leadersPhotos/Lokesh (21).jpg";
// import lokesh9 from './leadersPhotos/Lokesh (1).jpg'
// import lokesh10 from './leadersPhotos/Lokesh (1).jpg'
// import lokesh11 from './leadersPhotos/Lokesh (1).jpg'
// import lokesh12 from './leadersPhotos/Lokesh (1).jpg'

import Atchannaidu1 from "./leadersPhotos/achan-naidu1.jpeg";
import Atchannaidu2 from "./leadersPhotos/achan-naidu2.jpeg";
import Atchannaidu3 from "./leadersPhotos/atchennaidu (2).jpg";
import Atchannaidu4 from "./leadersPhotos/atchennaidu (3).jpg";
import Atchannaidu5 from "./leadersPhotos/atchennaidu (7).jpg";

import bollineni1 from "./leadersPhotos/bollineni1.jpeg";
import bollineni2 from "./leadersPhotos/bollineni2.jpeg";
import bollineni3 from "./leadersPhotos/bollineni3.jpeg";
import bollineni4 from "./leadersPhotos/bollineni4.jpeg";
import bollineni5 from "./leadersPhotos/bollineni5.jpeg";
import bollineni6 from "./leadersPhotos/BVR (1).jpg";
import bollineni7 from "./leadersPhotos/BVR (2).jpg";
import bollineni8 from "./leadersPhotos/BVR (5).jpg";
import bollineni9 from "./leadersPhotos/BVR (8).jpg";
import bollineni10 from "./leadersPhotos/BVR (13).jpg";
import bollineni11 from "./leadersPhotos/BVR (17).jpg";

import Ravichandra1 from "./leadersPhotos/yuvanestham1.jpeg";
import Ravichandra2 from "./leadersPhotos/ravi-chandra1.jpeg";
import Ravichandra3 from "./leadersPhotos/BRC (3).jpg";
import Ravichandra4 from "./leadersPhotos/BRC (4).jpg";
import Ravichandra5 from "./leadersPhotos/BRC (7).jpg";
import Ravichandra6 from "./leadersPhotos/BRC (9).jpg";
import Ravichandra7 from "./leadersPhotos/BRC (13).jpg";
import Ravichandra8 from "./leadersPhotos/BRC (15).jpg";
import Ravichandra9 from "./leadersPhotos/BRC (19).jpg";
import Ravichandra10 from "./leadersPhotos/BRC (21).jpg";
import Ravichandra11 from "./leadersPhotos/BRC (23).jpg";

import kolluRavindra1 from "./leadersPhotos/kollu-ravindra1.jpeg";
import kolluRavindra2 from "./leadersPhotos/kollu-ravindra2.jpeg";
import kolluRavindra3 from "./leadersPhotos/kollu-ravindra3.jpeg";
import kolluRavindra4 from "./leadersPhotos/kollu-ravindra4.jpeg";
import kolluRavindra5 from "./leadersPhotos/Kollu (1).jpg";
import kolluRavindra6 from "./leadersPhotos/Kollu (3).jpg";
import kolluRavindra7 from "./leadersPhotos/Kollu (5).jpg";
import kolluRavindra8 from "./leadersPhotos/Kollu (13).jpg";

import WOW from "wowjs";

const MyLeaders = () => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
      <HeaderAbout />
      <Header />

      <div className="heading-direction-cont u-margin-bottom-medium2">
        <h1 className="heading-primary u-margin-bottom-small">My Leaders</h1>
      </div>

      <div className="bg-special-cont-all  u-special-margin-top-large u-special-padding-top-medium">
        <div className="my-leaders-cont">
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomLeft">
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn6} />
                  </div>
                </Slider>
              </div>
            </div>
            <div>
              <h1 className="special-thanks-heading">
                SRI NARA CHANDRABABU NAIDU GARU
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn6} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Nara Chandrababu Naidu (born 20 April 1950), also known as
                Chandrababu Naidu or CBN, is an Indian politician and the
                current Leader of Opposition in the Andhra Pradesh Legislative
                Assembly. Naidu served as Chief Minister from 1995 to 2004 and
                as Opposition Leader from 2004 to 2014 in the erstwhile Andhra
                Pradesh. He held the position of Chief Minister for the third
                time in the bifurcated Andhra Pradesh from 2014 to 2019. He is
                the longest-tenured Chief Minister in United Andhra Pradesh. He
                is the National President of the Telugu Desam Party (TDP). He
                also served as the convenor of National Democratic Alliance
                (NDA).
              </p>
            </div>
          </div>

          {/* <div className="special-thanks-head-img-cont">
            <div className="width">
              <Slider {...settings}>
                <div>
                  <img className="leaders-slider-imgs" src={cbn1} />
                </div>
                <div>
                  <img className="leaders-slider-imgs" src={cbn2} />
                </div>
                <div>
                  <img className="leaders-slider-imgs" src={cbn3} />
                </div>
                <div>
                  <img className="leaders-slider-imgs" src={cbn4} />
                </div>
                <div>
                  <img className="leaders-slider-imgs" src={cbn5} />
                </div>
                <div>
                  <img className="leaders-slider-imgs" src={cbn6} />
                </div>
              </Slider>
            </div>

            <div>
              <h1 className="special-thanks-heading">
                SRI NARA CHANDRABABU NAIDU GARU
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={cbn6} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Nara Chandrababu Naidu (born 20 April 1950), also known as
                Chandrababu Naidu or CBN, is an Indian politician and the
                current Leader of Opposition in the Andhra Pradesh Legislative
                Assembly. Naidu served as Chief Minister from 1995 to 2004 and
                as Opposition Leader from 2004 to 2014 in the erstwhile Andhra
                Pradesh. He held the position of Chief Minister for the third
                time in the bifurcated Andhra Pradesh from 2014 to 2019. He is
                the longest-tenured Chief Minister in United Andhra Pradesh. He
                is the National President of the Telugu Desam Party (TDP). He
                also served as the convenor of National Democratic Alliance
                (NDA).
              </p>
            </div>
          </div> */}
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomRight">
            <div>
              <h1 className="special-thanks-heading">SRI NARA LOKESH GARU</h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh8} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Yuva Galam (Voice of the Youth) is a mass contact movement led
                by the Telugu Desam Party (TDP) and spearheaded by Nara Lokesh,
                the National General Secretary of TDP. The primary objective of
                this movement is to engage the youth of Andhra Pradesh and
                encourage them to actively participate in the state's political
                process. Nara Lokesh is undertaking a walkathon named Yuva Galam
                Padayatra, which commenced on January 27, 2023, with the
                ambitious plan to cover 100 Assembly constituencies in Andhra
                Pradesh, spanning a distance of 4,000 kilometers from Kuppam to
                Srikakulam.
              </p>
            </div>
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={lokesh8} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomLeft">
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu5} />
                  </div>
                </Slider>
              </div>
            </div>
            <div>
              <h1 className="special-thanks-heading">
                SRI Kinjarapu Atchannaidu Garu
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Atchannaidu5} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Atchannaidu was elected as an MLA from the Harishchandrapuram
                constituency in three consecutive elections: 1996 by-poll, 1999
                and 2004. Following the reorganization and dissolution of the
                Harishchandrapuram constituency, he contested and lost the 2009
                elections for the Tekkali constituency. He also lost the
                immediate by-poll in 2009 that occurred due to the death of the
                elected MLA. He was elected back in the 2014 and 2019 elections
                from the Tekkali constituency.
              </p>
            </div>
          </div>
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomRight">
            <div>
              <h1 className="special-thanks-heading">
                SRI Beeda Ravichandra Garu
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra8} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra9} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra10} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra11} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Beeda Ravichandra is a Member of the Legislative Council(MLC)
                from Nellore in Andhra Pradesh. He was born on 29-07-1969 to
                Raghuramaiah and JayaLakshmi in Iskapalli. He completed M.Com.
                Ravichandra married Jyothi. He was a Businessman. He started his
                political journey with the Telugu Desam Party(TDP) and was the
                TDP President of Nellore District. He worked as President of
                State Telugu Yuvata and Vice-President Aqua Association State,
                Andhra Pradesh. His Vision is to transform Andhra Pradesh into
                the heaven of prosperity by adopting a better commercial and
                corporate style of management of the human and material
                resources of the State. His mission is to make the state of
                Andhra Pradesh a leading investment destination in the country
                through various reformist policies. In 2015, He was elected as a
                Member of the Legislative Council(MLC) from Nellore in Andhra
                Pradesh.
              </p>
            </div>
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra1} />
                  </div>
                  {/* <div>
                    <img className="leaders-slider-imgs" src={Ravichandra2} />
                  </div> */}
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra8} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra9} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra10} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={Ravichandra11} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomLeft">
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni8} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni9} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni10} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni11} />
                  </div>
                </Slider>
              </div>
            </div>
            <div>
              <h1 className="special-thanks-heading">
                SRI BOLLINENI VENKATA RAMARAO
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni3} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni5} />
                  </div>

                  <div>
                    <img className="leaders-slider-imgs" src={bollineni6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni7} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni8} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni9} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni10} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={bollineni11} />
                  </div>
                </Slider>
              </div>
              <p className="special-para ">
                BOLLINENI VENKATA RAMARAO MLA elected from UDAYAGIRI of Andhra
                Pradesh state as a member of Telugu Desam Party. BOLLINENI
                VENKATA RAMARAO was born to Kondaiah Naidu Bollineni
              </p>
            </div>
          </div>
          <div className="special-thanks-head-img-cont wow animate__fadeInBottomRight">
            <div>
              <h1 className="special-thanks-heading">
                SRI KOLLU RAVINDRA GARU
              </h1>
              <div className="sm-sliders">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra3} />
                  </div>

                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra7} />
                  </div>

                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra8} />
                  </div>
                </Slider>
              </div>
              <p className="special-para">
                Kollu Ravindra entered politics in 1998 as Youth President of
                the Telugu Desam Party in Machilipatnam, a position he held for
                ten years. He was then given the TDP ticket in Machilipatnam for
                the May 2009 Andhra Pradesh Assembly elections. He lost that
                election by 9,300 votes to the Congress candidate Perni
                Venkataramaiah. Five years later in 2014, he defeated the same
                Perni Venkataramaiah by 15,800 votes. He then entered the Andhra
                Pradesh Cabinet with two portfolios: Handlooms & Excise and BC
                Welfare and Empowerment. Following a cabinet reshuffle in April
                2017, he was appointed Minister for Law & Justice, Skill
                Development, Youth, Sports, Unemployment Benefits, NRI
                Empowerment and Relations. In March 2015, Kollu Ravindra started
                Sparsha, a charity dedicated to the education of poor people in
                Machilipatnam
              </p>
            </div>
            <div>
              <div className="width">
                <Slider {...settings}>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra1} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra2} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra3} />
                  </div>

                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra4} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra5} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra6} />
                  </div>
                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra7} />
                  </div>

                  <div>
                    <img className="leaders-slider-imgs" src={kolluRavindra8} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyLeaders;
