import img1 from "../../components/assets/1.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img12 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";
import img5 from "../assets/5.jpg";

import raghavendra from "../assets/raghavendra2.png";

import Header from "../header/header.js";
import HeaderSlider from "../slider/slider";
import Footer from "../footer/footer.js";

import HeaderAbout from "../headerAbout/headerAbout";

import { useEffect } from "react";
import WOW from "wowjs";

function HomePage() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <section>
      <HeaderAbout />
      <Header />
      <HeaderSlider />
      <div className="home">
        <div className="u-text-center">
          <h1 className="heading-primary ">About Me</h1>
          <div className="row">
            <p className="paragraph u-margin-bottom-small">
              Raghavendra Ghanjam was born on 04 Feb at Kavali, Nellore
              district, Andhra Pradesh. He is working as "TDP Official
              Spokesperson & Social Media convener for State B.C Cell Andhra
              Pradesh". He have 15year's Experiencs in TDP party as a Senior
              Member.
            </p>
          </div>
        </div>
        <div className="row2">
          <div
            className="col-1-of-22 wow animate__bounceInLeft"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-iteration="1"
          >
            <img src={raghavendra} alt="person" className="home__about-img" />
          </div>
          <div
            className="col-1-of-22 wow animate__bounceInRight"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-iteration="1"
          >
            <p className="paragraph u-margin-bottom-small">
              According to his activities in TDP Party Media
            </p>
            <ul>
              <li>Official TDP Mettings</li>
              <li>Election Campanion</li>
              <li>TDP Media Mettings</li>
            </ul>
            <p className="paragraph u-margin-bottom-small">
              He talk about how to use these platforms effectively for outreach
              and promotion of protest actions. In addition, He give practical
              advise on how to work around censorship and shadow bans.
            </p>
            <p className="paragraph u-margin-bottom-small">
              Connectivity is among the most significant benefits of social
              media. It can link countless users at any time, everywhere.
              Information could be spread globally through social media and its
              connectedness, making it simple for people to interact with one
              another. It results in global relationships.
            </p>
            <button className="more-btn">Know More</button>
          </div>
        </div>
        <div className="u-text-center">
          <h1 className="heading-primary u-margin-bottom-medium">
            Recent Event Photos
          </h1>
          <div className="row wow animate__bounceInLeft">
            <Slider {...settings}>
              <div>
                {/* <h3>1</h3> */}
                <img className="home__slider-img" src={img1} />
              </div>
              <div>
                {/* <h3>2</h3> */}
                <img className="home__slider-img" src={img2} />
              </div>
              <div>
                {/* <h3>3</h3> */}
                <img className="home__slider-img" src={img3} />
              </div>

              <div>
                <img className="home__slider-img" src={img4} />
              </div>
              <div>
                {/* <h3>6</h3> */}
                <img className="home__slider-img" src={img5} />
              </div>
              {/* <div>
              
              <img className="our-expertise-slider__image" src={react} />
            </div> */}
              <div>
                {/* <h3>8</h3> */}
                <img className="home__slider-img" src={img12} />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <section className="section-services">
        <div className="u-text-center">
          <h1 className="heading-primary u-margin-top-medium">Services</h1>
        </div>
        <div className="container">
          <div
            className="card wow animate__rotateInDownLeft"
            data-wow-delay="0.5s"
            style={{ color: "#009688" }}
          >
            <div className="imgBx">
              <img src={img2} />
            </div>
            <div className="content">
              <h2>Service Poor People</h2>
              <p>
                Lokesh recently toured Mangalagiri constituency extensively. Met
                people directly. Asked about their problems. His tour went on
                crossing chaotic mud and mud roads and streets.
              </p>
            </div>
          </div>
          <div
            className="card wow animate__zoomIn"
            data-wow-delay="0.5s"
            style={{ color: "#ff3e7f" }}
          >
            <div className="imgBx">
              <img src={img4} />
            </div>
            <div className="content">
              <h2>Media Coverage in Party Meetings</h2>
              <p>
                Media coverage refers to the attention and exposure received by
                a person, brand, event, or topic in various forms of media,
                including print, broadcast, and digital platforms.
              </p>
            </div>
          </div>
          <div
            className="card wow animate__rotateInDownRight"
            data-wow-delay="0.5s"
            style={{ color: "#03a9f4" }}
          >
            <div className="imgBx">
              <img src={img3} />
            </div>
            <div className="content">
              <h2>Serve Food For Poor People</h2>
              <p>
                Food is a basic need for every human being In March 2016, the
                first 'Anna Canteen' named after TDP founder-president NT Rama
                Rao, was opened on Andhra Pradesh. serve free food for poor
                peoples.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-contact">
        <div className="contact">
          <div className="u-text-center">
            <h1 className="heading-primary u-margin-bottom-medium">Contact</h1>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.6042164533246!2d80.58867899461163!3d16.44158174637774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f16278d72bb9%3A0x81527adac604b7b6!2sTelugu%20Desam%20Party%20Central%20Office!5e0!3m2!1sen!2sin!4v1691412942792!5m2!1sen!2sin"
            className="contactt__map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <Footer />
    </section>
  );
}

export default HomePage;

{
  /* <p>రాష్ట్ర అధికార ప్రతినిధి</p> */
}
