import { Link } from "react-router-dom";
import img1 from "../../assets/1.jpg";
import img4 from "../../assets/4.jpg";
import img6 from "../../assets/6.jpg";
import mahanudu from "../../assets/mahanudu.jpg";
import yuvaghalam from "../../assets/yuvaghalam.png";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import HeaderAbout from "../../headerAbout/headerAbout";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import collectorOfficeNLR1 from "./Collector Office NLR/Collector Office NLR (1).jpg";
import collectorOfficeNLR2 from "./Collector Office NLR/Collector Office NLR (2).jpg";
import collectorOfficeNLR3 from "./Collector Office NLR/Collector Office NLR (3).jpg";

import gunturBooksDistribution1 from "./Guntur_Books Distribution/Guntur_Books Distribution (1).jpg";
import gunturBooksDistribution2 from "./Guntur_Books Distribution/Guntur_Books Distribution (2).jpg";
import vijayawada_BooksDistribution1 from "./Vijayawada_Books Distribution/Vijayawada_Books Distribution (1).jpg";
import vijayawada_BooksDistribution2 from "./Vijayawada_Books Distribution/Vijayawada_Books Distribution (2).jpg";

import sss2021_20221 from "./SSC 2021-2022/SSC 2021-2022 (1).jpg";
import sss2021_20222 from "./SSC 2021-2022/SSC 2021-2022 (2).jpg";
import ssc2022_20231 from "./SSC 2022-2023/SSC 2022-2023 (1).jpg";
import ssc2022_20232 from "./SSC 2022-2023/SSC 2022-2023 (2).jpg";

import helpingCommunity1 from "./Helping Community/Helping Community (1).jpg";
import helpingCommunity2 from "./Helping Community/Helping Community (2).jpg";
import helpingCommunity3 from "./Helping Community/Helping Community (3).jpg";

import ilammaVardhanthi1 from "./Ilamma Vardhanthi/Ilamma Vardhanthi (1).jpg";
import ilammaVardhanthi2 from "./Ilamma Vardhanthi/Ilamma Vardhanthi (2).jpg";
import ilammaVardhanthi3 from "./Ilamma Vardhanthi/Ilamma Vardhanthi (3).jpg";
import ilammaVardhanthi4 from "./Ilamma Vardhanthi/Ilamma Vardhanthi (4).jpg";

import roundTable_RVS_Guntur1 from "./Round Table_RVS_Guntur/Round Table_RVS_Guntur (1).jpg";
import roundTable_RVS_Vijayawada1 from "./Round Table_RVS_Vijayawada/Round Table_RVS_Vijayawada (1).jpg";
import roundTable_Tirupati1 from "./Round Table_Tirupati/Round Table_Tirupati (1).jpg";

import tdpMeetings1 from "./TDP Meetings/TDP Meetings (1).jpg";
import tdpMeetings2 from "./TDP Meetings/TDP Meetings (2).jpg";
import tdpMeetings3 from "./TDP Meetings/TDP Meetings (3).jpg";
import { useEffect } from "react";
import WOW from "wowjs";

function Political() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="heading-direction-cont">
        <h1 className="heading-primary ">Community</h1>
      </div>
      <div className="events">
        <div className="">
          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={collectorOfficeNLR1} />
                </div>
                <div>
                  <img className="" src={collectorOfficeNLR2} />
                </div>
                <div>
                  <img className="" src={collectorOfficeNLR3} />
                </div>
              </Slider>
            </div>

            <div className="events__card-and-details__card-content">
              <h1>Dharna in front of collector office Nellore</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={collectorOfficeNLR1} />
                  </div>
                  <div>
                    <img className="" src={collectorOfficeNLR2} />
                  </div>
                  <div>
                    <img className="" src={collectorOfficeNLR3} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Dharna in front of collector office Nellore
              </p>
            </div>
          </div>

          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Note Books Sponsored and Distributed</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={gunturBooksDistribution1} />
                  </div>
                  <div>
                    <img className="" src={gunturBooksDistribution2} />
                  </div>
                  <div>
                    <img className="" src={vijayawada_BooksDistribution1} />
                  </div>

                  <div>
                    <img className="" src={vijayawada_BooksDistribution2} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Note Books Sponsored and Distributed in Vijayawada & Guntur for
                students as part of Developing education In our Rajaka community{" "}
              </p>
            </div>

            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={gunturBooksDistribution1} />
                </div>
                <div>
                  <img className="" src={gunturBooksDistribution2} />
                </div>
                <div>
                  <img className="" src={vijayawada_BooksDistribution1} />
                </div>

                <div>
                  <img className="" src={vijayawada_BooksDistribution2} />
                </div>
              </Slider>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={sss2021_20221} />
                </div>
                <div>
                  <img className="" src={sss2021_20222} />
                </div>
                <div>
                  <img className="" src={ssc2022_20231} />
                </div>

                <div>
                  <img className="" src={ssc2022_20232} />
                </div>
              </Slider>
            </div>

            <div className="events__card-and-details__card-content">
              <h1>Gifts Sponsored for SSC Students</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={sss2021_20221} />
                  </div>
                  <div>
                    <img className="" src={sss2021_20222} />
                  </div>
                  <div>
                    <img className="" src={ssc2022_20231} />
                  </div>

                  <div>
                    <img className="" src={ssc2022_20232} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Prize Money , Gifts Sponsored and Distributed for the students
                who completed 10th class with good Rank & Marks as part of
                Encouraging education in the Rajaka community in Nellore ,
                Prakasam , Guntur , Chittoor , Krishna Districts.
              </p>
            </div>
          </div>

          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Distributing grocery & helping money</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={helpingCommunity1} />
                  </div>
                  <div>
                    <img className="" src={helpingCommunity2} />
                  </div>
                  <div>
                    <img className="" src={helpingCommunity3} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Distributing grocery & helping money for hospital medical
                expenses for the poor & old age Rajaka community people who
                really need our support in Guntur , Nellore and other districts.
              </p>
            </div>
            <div>
              <div className="fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={helpingCommunity1} />
                  </div>
                  <div>
                    <img className="" src={helpingCommunity2} />
                  </div>
                  <div>
                    <img className="" src={helpingCommunity3} />
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={tdpMeetings1} />
                </div>
                <div>
                  <img className="" src={tdpMeetings2} />
                </div>
                <div>
                  <img className="" src={tdpMeetings3} />
                </div>
              </Slider>
            </div>

            <div className="events__card-and-details__card-content">
              <h1>Need of TDP for the Rajaka Community</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={tdpMeetings1} />
                  </div>
                  <div>
                    <img className="" src={tdpMeetings2} />
                  </div>
                  <div>
                    <img className="" src={tdpMeetings3} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Motivating & Encouraging & Explaing need of TDP for the Rajaka
                Community in Andhra Pradesh of all parliaments .
              </p>
            </div>
          </div>
          <div className="events__card-and-details2 wow animate__bounceInRight">
            <div className="events__card-and-details__card-content">
              <h1>Attending different Meetings</h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={roundTable_RVS_Guntur1} />
                  </div>
                  <div>
                    <img className="" src={roundTable_RVS_Vijayawada1} />
                  </div>
                  <div>
                    <img className="" src={roundTable_Tirupati1} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Conducting , Attending different meetings with Rajaka community
                people & Leaders and demanding for SC Status, Reservations in
                Politics , Jobs in temples , hospitals , all other govt Jobs. ,
              </p>
            </div>

            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={roundTable_RVS_Guntur1} />
                </div>
                <div>
                  <img className="" src={roundTable_RVS_Vijayawada1} />
                </div>
                <div>
                  <img className="" src={roundTable_Tirupati1} />
                </div>
              </Slider>
            </div>
          </div>

          <div className="events__card-and-details wow animate__bounceInLeft">
            <div className="fade-slider">
              <Slider {...settings}>
                <div>
                  <img className="" src={ilammaVardhanthi1} />
                </div>
                <div>
                  <img className="" src={ilammaVardhanthi2} />
                </div>
                <div>
                  <img className="" src={ilammaVardhanthi3} />
                </div>

                <div>
                  <img className="" src={ilammaVardhanthi4} />
                </div>
              </Slider>
            </div>
            <div className="events__card-and-details__card-content">
              <h1>Attending Rajaka Community Leaders Jayanti & Vardhanti </h1>
              <div className="sm-fade-slider">
                <Slider {...settings}>
                  <div>
                    <img className="" src={ilammaVardhanthi1} />
                  </div>
                  <div>
                    <img className="" src={ilammaVardhanthi2} />
                  </div>
                  <div>
                    <img className="" src={ilammaVardhanthi3} />
                  </div>

                  <div>
                    <img className="" src={ilammaVardhanthi4} />
                  </div>
                </Slider>
              </div>
              <p className="u-margin-bottom-small">
                Attending Rajaka Community Leaders Jayanti & Vardhanti
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Political;
