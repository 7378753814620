import { Link } from "react-router-dom";
import img1 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";
import Footer from "../footer/footer";
import Header from "../header/header";

import news1 from "../assets/news1.jpg";
import news2 from "../assets/news2.jpg";
import news3 from "../assets/news3.jpg";
import news4 from "../assets/news4.jpg";
import news5 from "../assets/news5.jpg";
// import news6 from "../assets/news6.jpg";

import top3news1 from "../assets/top3news1.jpg";
import top3news2 from "../assets/top3news2.jpg";

import newsAndMedia from "../assets/news-media.jpg";
import HeaderAbout from "../headerAbout/headerAbout";
import { useState } from "react";
// blog/src/components/assets/news-media.jpg

import ReactPaginate from "react-paginate";

import yuvaChaithanya1 from "../newsMedia/newsPapers/R1.jpg";
import yuvaChaithanya2 from "../newsMedia/newsPapers/R2.jpg";
import yuvaChaithanya3 from "../newsMedia/newsPapers/R3.jpg";
import yuvaChaithanya4 from "../newsMedia/newsPapers/R4.jpg";
import yuvaChaithanya5 from "../newsMedia/newsPapers/R5.jpg";
import yuvaChaithanya6 from "../newsMedia/newsPapers/R6.jpg";
import yuvaChaithanya7 from "../newsMedia/newsPapers/R7.jpg";
import yuvaChaithanya8 from "../newsMedia/newsPapers/R8.jpg";
import yuvaChaithanya9 from "../newsMedia/newsPapers/R9.jpg";
import yuvaChaithanya10 from "../newsMedia/newsPapers/R10.jpg";
import yuvaChaithanya11 from "../newsMedia/newsPapers/R11.jpg";
import yuvaChaithanya12 from "../newsMedia/newsPapers/R12.jpg";
import yuvaChaithanya13 from "../newsMedia/newsPapers/R13.jpg";
import yuvaChaithanya14 from "../newsMedia/newsPapers/R14.jpg";
import yuvaChaithanya15 from "../newsMedia/newsPapers/R15.jpg";
import yuvaChaithanya16 from "../newsMedia/newsPapers/R16.jpg";
import yuvaChaithanya17 from "../newsMedia/newsPapers/R17.jpg";
import yuvaChaithanya18 from "../newsMedia/newsPapers/R18.jpg";
import yuvaChaithanya19 from "../newsMedia/newsPapers/R19.jpg";
import yuvaChaithanya20 from "../newsMedia/newsPapers/R20.jpg";
import yuvaChaithanya21 from "../newsMedia/newsPapers/R21.jpg";
import yuvaChaithanya22 from "../newsMedia/newsPapers/R22.jpg";
import yuvaChaithanya23 from "../newsMedia/newsPapers/R23.jpg";
import yuvaChaithanya24 from "../newsMedia/newsPapers/R24.jpg";
import yuvaChaithanya25 from "../newsMedia/newsPapers/R25.jpg";
import yuvaChaithanya26 from "../newsMedia/newsPapers/R26.jpg";
import yuvaChaithanya27 from "../newsMedia/newsPapers/R27.jpg";
import yuvaChaithanya28 from "../newsMedia/newsPapers/R28.jpg";
import yuvaChaithanya29 from "../newsMedia/newsPapers/R29.jpg";
import yuvaChaithanya30 from "../newsMedia/newsPapers/R30.jpg";
import yuvaChaithanya31 from "../newsMedia/newsPapers/R31.jpg";
import yuvaChaithanya32 from "../newsMedia/newsPapers/R32.jpg";
import yuvaChaithanya33 from "../newsMedia/newsPapers/R33.jpg";
import yuvaChaithanya34 from "../newsMedia/newsPapers/R34.jpg";
import yuvaChaithanya35 from "../newsMedia/newsPapers/R35.jpg";
import yuvaChaithanya36 from "../newsMedia/newsPapers/R36.jpg";
import yuvaChaithanya37 from "../newsMedia/newsPapers/R37.jpg";
import yuvaChaithanya38 from "../newsMedia/newsPapers/R38.jpeg";
import yuvaChaithanya39 from "../newsMedia/newsPapers/R39.jpg";
import yuvaChaithanya40 from "../newsMedia/newsPapers/R40.jpg";
import yuvaChaithanya41 from "../newsMedia/newsPapers/R41.jpg";

import yuvaChaithanya42 from "../newsMedia/newsPapers/youvachaitanya1.jpg";
import yuvaChaithanya43 from "../newsMedia/newsPapers/youvachaitanya2.jpg";
import yuvaChaithanya44 from "../newsMedia/newsPapers/youvachaitanya3.jpeg";
import yuvaChaithanya45 from "../newsMedia/newsPapers/youvachaitanya4.jpeg";
import yuvaChaithanya46 from "../newsMedia/newsPapers/youvachaitanya5.jpeg";
import yuvaChaithanya47 from "../newsMedia/newsPapers/youvachaitanya6.jpeg";
import yuvaChaithanya48 from "../newsMedia/newsPapers/youvachaitanya7.jpg";
import yuvaChaithanya49 from "../newsMedia/newsPapers/youvachaitanya8.jpeg";
import yuvaChaithanya50 from "../newsMedia/newsPapers/youvachaitanya9.jpeg";
// import yuvaChaithanya51 from "../newsMedia/newsPapers/youvachaitanya10.jpeg";
import yuvaChaithanya52 from "../newsMedia/newsPapers/youvachaitanya11.jpeg";
import yuvaChaithanya53 from "../newsMedia/newsPapers/youvachaitanya12.jpeg";
import yuvaChaithanya54 from "../newsMedia/newsPapers/youvachaitanya13.jpeg";
import yuvaChaithanya55 from "../newsMedia/newsPapers/youvachaitanya14.jpeg";
import yuvaChaithanya56 from "../newsMedia/newsPapers/youvachaitanya15.jpg";
import yuvaChaithanya57 from "../newsMedia/newsPapers/youvachaitanya16.jpg";
import yuvaChaithanya58 from "../newsMedia/newsPapers/youvachaitanya17.jpg";
import yuvaChaithanya59 from "../newsMedia/newsPapers/youvachaitanya18.jpg";
import yuvaChaithanya60 from "../newsMedia/newsPapers/youvachaitanya19.jpg";
import yuvaChaithanya61 from "../newsMedia/newsPapers/youvachaitanya20.jpg";
import yuvaChaithanya62 from "../newsMedia/newsPapers/youvachaitanya21.jpg";
import yuvaChaithanya63 from "../newsMedia/newsPapers/youvachaitanya22.jpg";
import yuvaChaithanya64 from "../newsMedia/newsPapers/youvachaitanya23.jpg";
import yuvaChaithanya65 from "../newsMedia/newsPapers/youvachaitanya24.jpg";
import yuvaChaithanya66 from "../newsMedia/newsPapers/youvachaitanya25.jpg";
import yuvaChaithanya67 from "../newsMedia/newsPapers/youvachaitanya26.jpg";
import yuvaChaithanya68 from "../newsMedia/newsPapers/youvachaitanya27.jpg";
import yuvaChaithanya69 from "../newsMedia/newsPapers/youvachaitanya28.jpg";
import yuvaChaithanya70 from "../newsMedia/newsPapers/youvachaitanya29.jpg";
import yuvaChaithanya71 from "../newsMedia/newsPapers/youvachaitanya30.jpg";
import yuvaChaithanya72 from "../newsMedia/newsPapers/youvachaitanya31.jpg";
import yuvaChaithanya73 from "../newsMedia/newsPapers/youvachaitanya32.jpg";
import yuvaChaithanya74 from "../newsMedia/newsPapers/youvachaitanya33.jpg";
import yuvaChaithanya75 from "../newsMedia/newsPapers/youvachaitanya34.jpg";
import yuvaChaithanya76 from "../newsMedia/newsPapers/youvachaitanya35.jpg";
import yuvaChaithanya77 from "../newsMedia/newsPapers/youvachaitanya36.jpg";
import yuvaChaithanya78 from "../newsMedia/newsPapers/youvachaitanya37.jpg";

//SPESHAL STATUS

import speshalStatus1 from "../newsMedia/newsPapers/speshalStatus1.jpeg";
import speshalStatus2 from "../newsMedia/newsPapers/speshalStatus2.jpeg";
import speshalStatus3 from "../newsMedia/newsPapers/speshalStatus3.jpeg";
import speshalStatus4 from "../newsMedia/newsPapers/speshalStatus4.jpeg";
import speshalStatus5 from "../newsMedia/newsPapers/speshalStatus5.jpeg";
import speshalStatus6 from "../newsMedia/newsPapers/speshalStatus6.jpeg";
import speshalStatus7 from "../newsMedia/newsPapers/speshalStatus7.jpeg";
import speshalStatus8 from "../newsMedia/newsPapers/speshalStatus8.jpeg";
import speshalStatus9 from "../newsMedia/newsPapers/speshalStatus9.jpeg";
import speshalStatus10 from "../newsMedia/newsPapers/speshalStatus10.jpeg";
import speshalStatus11 from "../newsMedia/newsPapers/speshalStatus11.jpeg";
import speshalStatus12 from "../newsMedia/newsPapers/speshalStatus12.jpeg";
import speshalStatus13 from "../newsMedia/newsPapers/speshalStatus13.jpeg";
import speshalStatus14 from "../newsMedia/newsPapers/speshalStatus14.jpeg";
import speshalStatus15 from "../newsMedia/newsPapers/speshalStatus15.jpeg";
import speshalStatus16 from "../newsMedia/newsPapers/speshalStatus16.jpeg";
import speshalStatus17 from "../newsMedia/newsPapers/speshalStatus17.jpeg";
import speshalStatus18 from "../newsMedia/newsPapers/speshalStatus18.jpeg";
import speshalStatus19 from "../newsMedia/newsPapers/speshalStatus19.jpeg";
import speshalStatus20 from "../newsMedia/newsPapers/speshalStatus20.jpeg";
import speshalStatus21 from "../newsMedia/newsPapers/speshalStatus21.jpeg";
import speshalStatus22 from "../newsMedia/newsPapers/speshalStatus22.jpeg";
import speshalStatus23 from "../newsMedia/newsPapers/speshalStatus23.jpeg";
import speshalStatus24 from "../newsMedia/newsPapers/speshalStatus24.jpeg";
import speshalStatus25 from "../newsMedia/newsPapers/speshalStatus25.jpeg";
import speshalStatus26 from "../newsMedia/newsPapers/speshalStatus26.jpeg";
import speshalStatus27 from "../newsMedia/newsPapers/speshalStatus27.jpeg";
import speshalStatus28 from "../newsMedia/newsPapers/speshalStatus28.jpeg";
import speshalStatus29 from "../newsMedia/newsPapers/speshalStatus29.jpeg";
import speshalStatus30 from "../newsMedia/newsPapers/speshalStatus30.jpeg";
import speshalStatus31 from "../newsMedia/newsPapers/speshalStatus31.jpeg";
import speshalStatus32 from "../newsMedia/newsPapers/speshalStatus32.jpeg";
import speshalStatus33 from "../newsMedia/newsPapers/speshalStatus33.jpeg";
import speshalStatus34 from "../newsMedia/newsPapers/speshalStatus34.jpeg";
import speshalStatus35 from "../newsMedia/newsPapers/speshalStatus35.jpeg";
import speshalStatus36 from "../newsMedia/newsPapers/speshalStatus36.jpeg";
import speshalStatus37 from "../newsMedia/newsPapers/speshalStatus37.jpeg";
import speshalStatus38 from "../newsMedia/newsPapers/speshalStatus38.jpeg";
import speshalStatus39 from "../newsMedia/newsPapers/speshalStatus39.jpeg";
import speshalStatus40 from "../newsMedia/newsPapers/speshalStatus40.jpeg";
import speshalStatus41 from "../newsMedia/newsPapers/speshalStatus41.jpeg";
import speshalStatus42 from "../newsMedia/newsPapers/speshalStatus42.jpeg";
import speshalStatus43 from "../newsMedia/newsPapers/speshalStatus43.jpeg";
import speshalStatus44 from "../newsMedia/newsPapers/speshalStatus44.jpeg";
import speshalStatus45 from "../newsMedia/newsPapers/speshalStatus45.jpeg";
import speshalStatus46 from "../newsMedia/newsPapers/speshalStatus46.jpeg";
import speshalStatus47 from "../newsMedia/newsPapers/speshalStatus47.jpeg";
import speshalStatus48 from "../newsMedia/newsPapers/speshalStatus48.jpeg";
import speshalStatus49 from "../newsMedia/newsPapers/speshalStatus49.jpeg";
import speshalStatus50 from "../newsMedia/newsPapers/speshalStatus50.jpeg";
import speshalStatus51 from "../newsMedia/newsPapers/speshalStatus51.jpeg";
import speshalStatus52 from "../newsMedia/newsPapers/speshalStatus52.jpeg";
import speshalStatus53 from "../newsMedia/newsPapers/speshalStatus53.jpeg";
import speshalStatus54 from "../newsMedia/newsPapers/speshalStatus54.jpeg";
import speshalStatus55 from "../newsMedia/newsPapers/speshalStatus55.jpeg";
import speshalStatus56 from "../newsMedia/newsPapers/speshalStatus56.jpeg";
import speshalStatus57 from "../newsMedia/newsPapers/speshalStatus57.jpeg";
import speshalStatus58 from "../newsMedia/newsPapers/speshalStatus58.jpeg";
import speshalStatus59 from "../newsMedia/newsPapers/speshalStatus59.jpeg";
import speshalStatus60 from "../newsMedia/newsPapers/speshalStatus60.jpeg";
import speshalStatus61 from "../newsMedia/newsPapers/speshalStatus61.jpeg";
import speshalStatus62 from "../newsMedia/newsPapers/speshalStatus62.jpeg";
import speshalStatus63 from "../newsMedia/newsPapers/speshalStatus63.jpeg";
import speshalStatus64 from "../newsMedia/newsPapers/speshalStatus64.jpeg";
import speshalStatus65 from "../newsMedia/newsPapers/speshalStatus65.jpeg";
import speshalStatus66 from "../newsMedia/newsPapers/speshalStatus66.jpeg";
import speshalStatus67 from "../newsMedia/newsPapers/speshalStatus67.jpeg";
import speshalStatus68 from "../newsMedia/newsPapers/speshalStatus68.jpeg";
import speshalStatus69 from "../newsMedia/newsPapers/speshalStatus69.jpeg";
import speshalStatus70 from "../newsMedia/newsPapers/speshalStatus70.jpeg";
import speshalStatus71 from "../newsMedia/newsPapers/speshalStatus71.jpeg";
import speshalStatus72 from "../newsMedia/newsPapers/speshalStatus72.jpeg";
import speshalStatus73 from "../newsMedia/newsPapers/speshalStatus73.jpeg";
import speshalStatus74 from "../newsMedia/newsPapers/speshalStatus74.jpeg";
import speshalStatus75 from "../newsMedia/newsPapers/speshalStatus75.jpeg";
import speshalStatus76 from "../newsMedia/newsPapers/speshalStatus76.jpeg";
import speshalStatus77 from "../newsMedia/newsPapers/speshalStatus77.jpeg";
import speshalStatus78 from "../newsMedia/newsPapers/speshalStatus78.jpeg";
import speshalStatus79 from "../newsMedia/newsPapers/speshalStatus79.jpeg";

//YUVA NESTHAM

import yuvanestham1 from "../newsMedia/newsPapers/yuvanestham1.jpg";
import yuvanestham2 from "../newsMedia/newsPapers/yuvanestham2.jpg";
import yuvanestham3 from "../newsMedia/newsPapers/yuvanestham3.JPG";
import yuvanestham4 from "../newsMedia/newsPapers/yuvanestham4.JPG";
import yuvanestham5 from "../newsMedia/newsPapers/yuvanestham5.JPG";
import yuvanestham6 from "../newsMedia/newsPapers/yuvanestham6.jpg";
import yuvanestham7 from "../newsMedia/newsPapers/yuvanestham7.jpg";
import yuvanestham8 from "../newsMedia/newsPapers/yuvanestham8.jpg";
import yuvanestham9 from "../newsMedia/newsPapers/yuvanestham9.jpg";
import yuvanestham10 from "../newsMedia/newsPapers/yuvanestham10.jpg";
import yuvanestham11 from "../newsMedia/newsPapers/yuvanestham11.jpg";
import yuvanestham12 from "../newsMedia/newsPapers/yuvanestham12.jpg";
import yuvanestham13 from "../newsMedia/newsPapers/yuvanestham13.jpg";
import yuvanestham14 from "../newsMedia/newsPapers/yuvanestham14.jpg";
import yuvanestham15 from "../newsMedia/newsPapers/yuvanestham15.jpg";
import yuvanestham16 from "../newsMedia/newsPapers/yuvanestham16.jpg";
import yuvanestham17 from "../newsMedia/newsPapers/yuvanestham17.jpg";
import yuvanestham18 from "../newsMedia/newsPapers/yuvanestham18.jpg";
import yuvanestham19 from "../newsMedia/newsPapers/yuvanestham19.jpg";
import yuvanestham20 from "../newsMedia/newsPapers/yuvanestham20.jpg";
import yuvanestham21 from "../newsMedia/newsPapers/yuvanestham21.jpg";
import yuvanestham22 from "../newsMedia/newsPapers/yuvanestham22.jpg";
import yuvanestham23 from "../newsMedia/newsPapers/yuvanestham23.jpg";
import yuvanestham24 from "../newsMedia/newsPapers/yuvanestham24.jpg";
import yuvanestham25 from "../newsMedia/newsPapers/yuvanestham25.jpg";
import yuvanestham26 from "../newsMedia/newsPapers/yuvanestham26.jpg";
import yuvanestham27 from "../newsMedia/newsPapers/yuvanestham27.jpg";
import yuvanestham28 from "../newsMedia/newsPapers/yuvanestham28.jpg";
import yuvanestham29 from "../newsMedia/newsPapers/yuvanestham29.jpg";
import yuvanestham30 from "../newsMedia/newsPapers/yuvanestham30.jpg";
import yuvanestham31 from "../newsMedia/newsPapers/yuvanestham31.jpg";
import yuvanestham32 from "../newsMedia/newsPapers/yuvanestham32.jpg";
import yuvanestham33 from "../newsMedia/newsPapers/yuvanestham33.jpg";
import yuvanestham34 from "../newsMedia/newsPapers/yuvanestham34.jpg";
import yuvanestham35 from "../newsMedia/newsPapers/yuvanestham35.jpg";
import yuvanestham36 from "../newsMedia/newsPapers/yuvanestham36.jpg";
import yuvanestham37 from "../newsMedia/newsPapers/yuvanestham37.jpg";
import yuvanestham38 from "../newsMedia/newsPapers/yuvanestham38.jpg";
import yuvanestham39 from "../newsMedia/newsPapers/yuvanestham39.jpg";
import yuvanestham40 from "../newsMedia/newsPapers/yuvanestham40.jpg";
import yuvanestham41 from "../newsMedia/newsPapers/yuvanestham41.jpg";
import yuvanestham42 from "../newsMedia/newsPapers/yuvanestham42.jpg";
import yuvanestham43 from "../newsMedia/newsPapers/yuvanestham43.jpg";
import yuvanestham44 from "../newsMedia/newsPapers/yuvanestham44.jpg";
import yuvanestham45 from "../newsMedia/newsPapers/yuvanestham45.jpg";
import yuvanestham46 from "../newsMedia/newsPapers/yuvanestham46.jpg";
import yuvanestham47 from "../newsMedia/newsPapers/yuvanestham47.jpg";
import yuvanestham48 from "../newsMedia/newsPapers/yuvanestham48.jpg";
import yuvanestham49 from "../newsMedia/newsPapers/yuvanestham49.jpg";
import yuvanestham50 from "../newsMedia/newsPapers/yuvanestham50.jpg";
import yuvanestham51 from "../newsMedia/newsPapers/yuvanestham51.jpg";
import yuvanestham52 from "../newsMedia/newsPapers/yuvanestham52.jpg";
import yuvanestham53 from "../newsMedia/newsPapers/yuvanestham53.jpg";
import yuvanestham54 from "../newsMedia/newsPapers/yuvanestham54.jpg";
import yuvanestham55 from "../newsMedia/newsPapers/yuvanestham55.jpg";
import yuvanestham56 from "../newsMedia/newsPapers/yuvanestham56.jpg";
import yuvanestham57 from "../newsMedia/newsPapers/yuvanestham57.jpg";
import yuvanestham58 from "../newsMedia/newsPapers/yuvanestham58.jpg";
import yuvanestham59 from "../newsMedia/newsPapers/yuvanestham59.jpg";
import yuvanestham60 from "../newsMedia/newsPapers/yuvanestham60.jpg";
import yuvanestham61 from "../newsMedia/newsPapers/yuvanestham61.jpg";
import yuvanestham62 from "../newsMedia/newsPapers/yuvanestham62.jpg";
import yuvanestham63 from "../newsMedia/newsPapers/yuvanestham63.jpg";
import yuvanestham64 from "../newsMedia/newsPapers/yuvanestham64.jpg";
import yuvanestham65 from "../newsMedia/newsPapers/yuvanestham65.jpg";
import yuvanestham66 from "../newsMedia/newsPapers/yuvanestham66.jpg";
import yuvanestham67 from "../newsMedia/newsPapers/yuvanestham67.jpg";
import yuvanestham68 from "../newsMedia/newsPapers/yuvanestham68.jpg";
import yuvanestham69 from "../newsMedia/newsPapers/yuvanestham69.jpg";
import yuvanestham70 from "../newsMedia/newsPapers/yuvanestham70.jpg";
import yuvanestham71 from "../newsMedia/newsPapers/yuvanestham71.jpg";
import yuvanestham72 from "../newsMedia/newsPapers/yuvanestham72.jpg";
import yuvanestham73 from "../newsMedia/newsPapers/yuvanestham73.JPG";
import yuvanestham74 from "../newsMedia/newsPapers/yuvanestham74.jpg";
import yuvanestham75 from "../newsMedia/newsPapers/yuvanestham75.jpg";
import yuvanestham76 from "../newsMedia/newsPapers/yuvanestham76.jpg";
import yuvanestham77 from "../newsMedia/newsPapers/yuvanestham77.jpg";
import yuvanestham78 from "../newsMedia/newsPapers/yuvanestham78.jpg";
import yuvanestham79 from "../newsMedia/newsPapers/yuvanestham79.jpg";
import yuvanestham80 from "../newsMedia/newsPapers/yuvanestham80.JPG";
import yuvanestham81 from "../newsMedia/newsPapers/yuvanestham81.jpg";
import yuvanestham82 from "../newsMedia/newsPapers/yuvanestham82.JPG";
import yuvanestham83 from "../newsMedia/newsPapers/yuvanestham83.jpg";
import yuvanestham84 from "../newsMedia/newsPapers/yuvanestham84.jpg";
import yuvanestham85 from "../newsMedia/newsPapers/yuvanestham85.JPG";
import yuvanestham86 from "../newsMedia/newsPapers/yuvanestham86.jpg";
import yuvanestham87 from "../newsMedia/newsPapers/yuvanestham87.jpg";
import yuvanestham88 from "../newsMedia/newsPapers/yuvanestham88.JPG";
import yuvanestham89 from "../newsMedia/newsPapers/yuvanestham89.jpg";
import yuvanestham90 from "../newsMedia/newsPapers/yuvanestham90.JPG";
import yuvanestham91 from "../newsMedia/newsPapers/yuvanestham91.JPG";
import yuvanestham92 from "../newsMedia/newsPapers/yuvanestham92.jpg";
import yuvanestham93 from "../newsMedia/newsPapers/yuvanestham93.jpg";
import yuvanestham94 from "../newsMedia/newsPapers/yuvanestham94.JPG";
import yuvanestham95 from "../newsMedia/newsPapers/yuvanestham95.jpg";
import yuvanestham96 from "../newsMedia/newsPapers/yuvanestham96.jpg";
import yuvanestham97 from "../newsMedia/newsPapers/yuvanestham97.jpg";
import yuvanestham98 from "../newsMedia/newsPapers/yuvanestham98.jpg";
import yuvanestham99 from "../newsMedia/newsPapers/yuvanestham99.JPG";
import yuvanestham100 from "../newsMedia/newsPapers/yuvanestham100.JPG";
import yuvanestham101 from "../newsMedia/newsPapers/yuvanestham101.jpg";
import yuvanestham102 from "../newsMedia/newsPapers/yuvanestham102.JPG";
import yuvanestham103 from "../newsMedia/newsPapers/yuvanestham103.JPG";
import yuvanestham104 from "../newsMedia/newsPapers/yuvanestham104.jpg";
import yuvanestham105 from "../newsMedia/newsPapers/yuvanestham105.jpg";
import yuvanestham106 from "../newsMedia/newsPapers/yuvanestham106.jpg";
import yuvanestham107 from "../newsMedia/newsPapers/yuvanestham107.jpg";
import yuvanestham108 from "../newsMedia/newsPapers/yuvanestham108.jpg";
import yuvanestham109 from "../newsMedia/newsPapers/yuvanestham109.jpg";
import yuvanestham110 from "../newsMedia/newsPapers/yuvanestham110.jpg";
import yuvanestham111 from "../newsMedia/newsPapers/yuvanestham111.JPG";
import yuvanestham112 from "../newsMedia/newsPapers/yuvanestham112.JPG";
import yuvanestham113 from "../newsMedia/newsPapers/yuvanestham113.jpg";
import yuvanestham114 from "../newsMedia/newsPapers/yuvanestham114.jpg";
import yuvanestham115 from "../newsMedia/newsPapers/yuvanestham115.jpg";
import yuvanestham116 from "../newsMedia/newsPapers/yuvanestham116.jpg";
import yuvanestham117 from "../newsMedia/newsPapers/yuvanestham117.jpg";
import yuvanestham118 from "../newsMedia/newsPapers/yuvanestham118.jpg";
import yuvanestham119 from "../newsMedia/newsPapers/yuvanestham119.jpg";
import yuvanestham120 from "../newsMedia/newsPapers/yuvanestham120.jpg";
import yuvanestham121 from "../newsMedia/newsPapers/yuvanestham121.jpg";
import yuvanestham122 from "../newsMedia/newsPapers/yuvanestham122.jpg";
import yuvanestham123 from "../newsMedia/newsPapers/yuvanestham123.jpg";
import yuvanestham124 from "../newsMedia/newsPapers/yuvanestham124.jpg";
import yuvanestham125 from "../newsMedia/newsPapers/yuvanestham125.jpg";
import yuvanestham126 from "../newsMedia/newsPapers/yuvanestham126.jpg";
import yuvanestham127 from "../newsMedia/newsPapers/yuvanestham127.jpg";
import yuvanestham128 from "../newsMedia/newsPapers/yuvanestham128.jpg";
import yuvanestham129 from "../newsMedia/newsPapers/yuvanestham129.jpg";
import yuvanestham130 from "../newsMedia/newsPapers/yuvanestham130.jpg";
import yuvanestham131 from "../newsMedia/newsPapers/yuvanestham131.jpg";
import yuvanestham132 from "../newsMedia/newsPapers/yuvanestham132.jpg";
import yuvanestham133 from "../newsMedia/newsPapers/yuvanestham133.jpg";
import yuvanestham134 from "../newsMedia/newsPapers/yuvanestham134.jpg";
import yuvanestham135 from "../newsMedia/newsPapers/yuvanestham135.jpg";
import yuvanestham136 from "../newsMedia/newsPapers/yuvanestham136.jpg";
import yuvanestham137 from "../newsMedia/newsPapers/yuvanestham137.jpg";
import yuvanestham138 from "../newsMedia/newsPapers/yuvanestham138.jpg";
import yuvanestham139 from "../newsMedia/newsPapers/yuvanestham139.jpg";
import yuvanestham140 from "../newsMedia/newsPapers/yuvanestham140.jpg";
import yuvanestham141 from "../newsMedia/newsPapers/yuvanestham141.JPG";
import yuvanestham142 from "../newsMedia/newsPapers/yuvanestham142.jpg";

const papersPerPage = 20;

function NewsMedia() {
  const newsPapers = [
    //YUVA NESTHAM
    yuvanestham1,
    yuvanestham2,
    yuvanestham3,
    yuvanestham4,
    yuvanestham5,
    yuvanestham6,
    yuvanestham7,
    yuvanestham8,
    yuvanestham9,
    yuvanestham10,
    yuvanestham11,
    yuvanestham12,
    yuvanestham13,
    yuvanestham14,
    yuvanestham15,
    yuvanestham16,
    yuvanestham17,
    yuvanestham18,
    yuvanestham19,
    yuvanestham20,
    yuvanestham21,
    yuvanestham22,
    yuvanestham23,
    yuvanestham24,
    yuvanestham25,
    yuvanestham26,
    yuvanestham27,
    yuvanestham28,
    yuvanestham29,
    yuvanestham30,
    yuvanestham31,
    yuvanestham32,
    yuvanestham33,
    yuvanestham34,
    yuvanestham35,
    yuvanestham36,
    yuvanestham37,
    yuvanestham38,
    yuvanestham39,
    yuvanestham40,
    yuvanestham41,
    yuvanestham42,
    yuvanestham43,
    yuvanestham44,
    yuvanestham45,
    yuvanestham46,
    yuvanestham47,
    yuvanestham48,
    yuvanestham49,
    yuvanestham50,
    yuvanestham51,
    yuvanestham52,
    yuvanestham53,
    yuvanestham54,
    yuvanestham55,
    yuvanestham56,
    yuvanestham57,
    yuvanestham58,
    yuvanestham59,
    yuvanestham60,
    yuvanestham61,
    yuvanestham62,
    yuvanestham63,
    yuvanestham64,
    yuvanestham65,
    yuvanestham66,
    yuvanestham67,
    yuvanestham68,
    yuvanestham69,
    yuvanestham70,
    yuvanestham71,
    yuvanestham72,
    yuvanestham73,
    yuvanestham74,
    yuvanestham75,
    yuvanestham76,
    yuvanestham77,
    yuvanestham78,
    yuvanestham79,
    yuvanestham80,
    yuvanestham81,
    yuvanestham82,
    yuvanestham83,
    yuvanestham84,
    yuvanestham85,
    yuvanestham86,
    yuvanestham87,
    yuvanestham88,
    yuvanestham89,
    yuvanestham90,
    yuvanestham91,
    yuvanestham92,
    yuvanestham93,
    yuvanestham94,
    yuvanestham95,
    yuvanestham96,
    yuvanestham97,
    yuvanestham98,
    yuvanestham99,
    yuvanestham100,
    yuvanestham101,
    yuvanestham102,
    yuvanestham103,
    yuvanestham104,
    yuvanestham105,
    yuvanestham106,
    yuvanestham107,
    yuvanestham108,
    yuvanestham109,
    yuvanestham110,
    yuvanestham111,
    yuvanestham112,
    yuvanestham113,
    yuvanestham114,
    yuvanestham115,
    yuvanestham116,
    yuvanestham117,
    yuvanestham118,
    yuvanestham119,
    yuvanestham120,
    yuvanestham121,
    yuvanestham122,
    yuvanestham123,
    yuvanestham124,
    yuvanestham125,
    yuvanestham126,
    yuvanestham127,
    yuvanestham128,
    yuvanestham129,
    yuvanestham130,
    yuvanestham131,
    yuvanestham132,
    yuvanestham133,
    yuvanestham134,
    yuvanestham135,
    yuvanestham136,
    yuvanestham137,
    yuvanestham138,
    yuvanestham139,
    yuvanestham140,
    yuvanestham141,
    yuvanestham142,

    //SPESHAL STATUS
    speshalStatus1,
    speshalStatus2,
    speshalStatus3,
    speshalStatus4,
    speshalStatus5,
    speshalStatus6,
    speshalStatus7,
    speshalStatus8,
    speshalStatus9,
    speshalStatus10,
    speshalStatus11,
    speshalStatus12,
    speshalStatus13,
    speshalStatus14,
    speshalStatus15,
    speshalStatus16,
    speshalStatus17,
    speshalStatus18,
    speshalStatus19,
    speshalStatus20,
    speshalStatus21,
    speshalStatus22,
    speshalStatus23,
    speshalStatus24,
    speshalStatus25,
    speshalStatus26,
    speshalStatus27,
    speshalStatus28,
    speshalStatus29,
    speshalStatus30,
    speshalStatus31,
    speshalStatus32,
    speshalStatus33,
    speshalStatus34,
    speshalStatus35,
    speshalStatus36,
    speshalStatus37,
    speshalStatus38,
    speshalStatus39,
    speshalStatus40,
    speshalStatus41,
    speshalStatus42,
    speshalStatus43,
    speshalStatus44,
    speshalStatus45,
    speshalStatus46,
    speshalStatus47,
    speshalStatus48,
    speshalStatus49,
    speshalStatus50,
    speshalStatus51,
    speshalStatus52,
    speshalStatus53,
    speshalStatus54,
    speshalStatus55,
    speshalStatus56,
    speshalStatus57,
    speshalStatus58,
    speshalStatus59,
    speshalStatus60,
    speshalStatus61,
    speshalStatus62,
    speshalStatus63,
    speshalStatus64,
    speshalStatus65,
    speshalStatus66,
    speshalStatus67,
    speshalStatus68,
    speshalStatus69,
    speshalStatus70,
    speshalStatus71,
    speshalStatus72,
    speshalStatus73,
    speshalStatus74,
    speshalStatus75,
    speshalStatus76,
    speshalStatus77,
    speshalStatus78,
    speshalStatus79,

    //YUVA CHAITHANYA
    yuvaChaithanya1,
    yuvaChaithanya2,
    yuvaChaithanya3,
    yuvaChaithanya4,
    yuvaChaithanya5,
    yuvaChaithanya6,
    yuvaChaithanya7,
    yuvaChaithanya8,
    yuvaChaithanya9,
    yuvaChaithanya10,
    yuvaChaithanya11,
    yuvaChaithanya12,
    yuvaChaithanya13,
    yuvaChaithanya14,
    yuvaChaithanya15,
    yuvaChaithanya16,
    yuvaChaithanya17,
    yuvaChaithanya18,
    yuvaChaithanya19,
    yuvaChaithanya20,
    yuvaChaithanya21,
    yuvaChaithanya22,
    yuvaChaithanya23,
    yuvaChaithanya24,
    yuvaChaithanya25,
    yuvaChaithanya26,
    yuvaChaithanya27,
    yuvaChaithanya28,
    yuvaChaithanya29,
    yuvaChaithanya30,
    yuvaChaithanya31,
    yuvaChaithanya32,
    yuvaChaithanya33,
    yuvaChaithanya34,
    yuvaChaithanya35,
    yuvaChaithanya36,
    yuvaChaithanya37,
    yuvaChaithanya38,
    yuvaChaithanya39,
    yuvaChaithanya40,
    yuvaChaithanya41,
    yuvaChaithanya42,
    yuvaChaithanya43,
    yuvaChaithanya44,
    yuvaChaithanya45,
    yuvaChaithanya46,
    yuvaChaithanya47,
    yuvaChaithanya48,
    yuvaChaithanya49,
    yuvaChaithanya50,
    // yuvaChaithanya51,
    yuvaChaithanya52,
    yuvaChaithanya53,
    yuvaChaithanya54,
    yuvaChaithanya55,
    yuvaChaithanya56,
    yuvaChaithanya57,
    yuvaChaithanya58,
    yuvaChaithanya59,
    yuvaChaithanya60,
    yuvaChaithanya61,
    yuvaChaithanya62,
    yuvaChaithanya63,
    yuvaChaithanya64,
    yuvaChaithanya65,
    yuvaChaithanya66,
    yuvaChaithanya67,
    yuvaChaithanya68,
    yuvaChaithanya69,
    yuvaChaithanya70,
    yuvaChaithanya71,
    yuvaChaithanya72,
    yuvaChaithanya73,
    yuvaChaithanya74,
    yuvaChaithanya75,
    yuvaChaithanya76,
    yuvaChaithanya77,
    yuvaChaithanya78,
  ];
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const pagesCount = Math.ceil(newsPapers.length / papersPerPage);
  const offset = currentPage * papersPerPage;
  const currentPapers = newsPapers.slice(offset, offset + papersPerPage);

  console.log("current papers", currentPapers);

  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="heading-direction-cont">
        <h1 className="heading-primary">News & Media</h1>
      </div>

      {/* <div className="row"> */}
      <img className="services__top-img" src={newsAndMedia} />
      {/* </div> */}

      {/* <div className="services">
        <div className="row">
          <div className="col-1-of-3" data-aos="zoom-in">
            <img src={top3news1} alt="person" className="services__img" />
            <div className="services__new-card-content">
              <h1>Spokes about Andhra Pradesh Bank Loans</h1>
              <p className="u-margin-bottom-very-small">
                In the past, if they gave loans and did not pay their debts,
                their assets were confiscated. Today our state is in the same
                situation. The state has sunk into debt due to the vagaries of
                the YCP government.
              </p>
              <p>
                In the future, no banks will come forward to give loans to the
                state. There is a possibility of turning into Sri Lanka if the
                debts are exceeded. Jadooreddy should go..Chandranna should
                come. - Ganjam Raghavendra, State Spokesperson & Social Media
                Coordinator, Telugu Desam Party BC Federation
              </p>
              
            </div>
          </div>
          <div className="col-1-of-3" data-aos="zoom-in">
            <img src={img4} alt="person" className="services__img" />
            <div className="services__new-card-content">
              <h1>Media Coverage in Party Meetings</h1>
              <p className="">
                Lands of the poor in the hands of cultivators - Ganjam
                Raghavendra, State Spokesperson & Social Media Coordinator,
                Telugu Desam Party BC Federation
              </p>
            </div>
          </div>
          <div className="col-1-of-3" data-aos="zoom-in">
            <img src={top3news2} alt="person" className="services__img" />
            <div className="services__new-card-content">
              <h1>News about democracy, public leaders</h1>
              <p className="">
                If you are in a situation where you are afraid of the trip and
                bring life, it means that the victory belongs to Chandrababu
                Naidu.. In a democracy, public leaders can go anywhere and
                anytime. Such state terrorism is running in Jagan Reddy's regime
                in the state to stop the tours undertaken by the Telugu Desam
                Party. - Ganjam Raghavendra, State Spokesperson & Social Media
                Coordinator, Telugu Desam Party BC Federation.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="news-sticky-header">
        <div className="news">
          <h1 className="heading-primary u-text-center">Paper Cuttings</h1>
        </div>
        <div className="row">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pagesCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            nextClassName={"next-link"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />

          {/* pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-link"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"} */}
        </div>
      </div>
      <div className="news__cards-cont">
        <div>
          {currentPapers.map((eachPaper) => (
            // <div className="news__card">
            <img src={eachPaper} className="news__card" />
            // </div>
          ))}
        </div>

        {/* <div className="news__card">
          <img src={news1} />
        </div>
        <div className="news__card">
          <img src={news2} />
        </div>
        <div className="news__card">
          <img src={news3} />
        </div>
        <div className="news__card">
          <img src={news4} />
        </div>
        <div className="news__card">
          <img src={news5} />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default NewsMedia;

{
  /* <div className="services__cards-cont">
  <div className="services__cards-cont__card" style={{ color: "#009688" }}>
    <img src={img1} />
    <div className="services__cards-cont__card__content ">
      <h2>Service Poor People</h2>
      <p>
        Lokesh recently toured Mangalagiri constituency extensively. Met people
        directly. Asked about their problems. His tour went on crossing chaotic
        mud and mud roads and streets.
      </p>
    </div>
  </div>
  <div className="services__cards-cont__card" style={{ color: "#009688" }}>
    <img src={img1} />
    <div className="services__cards-cont__card__content ">
      <h2>Service Poor People</h2>
      <p>
        Lokesh recently toured Mangalagiri constituency extensively. Met people
        directly. Asked about their problems. His tour went on crossing chaotic
        mud and mud roads and streets.
      </p>
    </div>
  </div>
  <div className="services__cards-cont__card" style={{ color: "#009688" }}>
    <img src={img1} />
    <div className="services__cards-cont__card__content ">
      <h2>Service Poor People</h2>
      <p>
        Lokesh recently toured Mangalagiri constituency extensively. Met people
        directly. Asked about their problems. His tour went on crossing chaotic
        mud and mud roads and streets.
      </p>
    </div>
  </div>
</div>; */
}

// <div className="services__cards-cont">
//           <div className="services__cards-cont__card">
//             <div className="services__cards-cont__card__img-box">
//               <img src={img1} />
//             </div>
//             <div className="services__cards-cont__card__content">
//               <h1>TDP Yuvagalam</h1>
//               <p className="paragraph">
//                 Nara Lokesh started the Yuvagalam padayathra with a plan to
//                 cover 100 assembly constituencies in the state of Andhra Pradesh
//                 with a road map to walk 4,000 km form kuppam to srikakulam. The
//                 on going padayathra is planned to be the longest in the
//                 political historey of andhra pradesh.
//               </p>
//             </div>
//           </div>
//           <div className="services__cards-cont__card">
//             <div className="services__cards-cont__card__img-box">
//               <img src={img1} />
//             </div>
//             <div className="services__cards-cont__card__content">
//               <h1>TDP Yuvagalam</h1>
//               <p className="paragraph">
//                 Nara Lokesh started the Yuvagalam padayathra with a plan to
//                 cover 100 assembly constituencies in the state of Andhra Pradesh
//                 with a road map to walk 4,000 km form kuppam to srikakulam. The
//                 on going padayathra is planned to be the longest in the
//                 political historey of andhra pradesh.
//               </p>
//             </div>
//           </div>
//           <div className="services__cards-cont__card">
//             <div className="services__cards-cont__card__img-box">
//               <img src={img1} />
//             </div>
//             <div className="services__cards-cont__card__content">
//               <h1>TDP Yuvagalam</h1>
//               <p className="paragraph">
//                 Nara Lokesh started the Yuvagalam padayathra with a plan to
//                 cover 100 assembly constituencies in the state of Andhra Pradesh
//                 with a road map to walk 4,000 km form kuppam to srikakulam. The
//                 on going padayathra is planned to be the longest in the
//                 political historey of andhra pradesh.
//               </p>
//             </div>
//           </div>
//           <div className="services__cards-cont__card">
//             <div className="services__cards-cont__card__img-box">
//               <img src={img1} />
//             </div>
//             <div className="services__cards-cont__card__content">
//               <h1>TDP Yuvagalam</h1>
//               <p className="paragraph">
//                 Nara Lokesh started the Yuvagalam padayathra with a plan to
//                 cover 100 assembly constituencies in the state of Andhra Pradesh
//                 with a road map to walk 4,000 km form kuppam to srikakulam. The
//                 on going padayathra is planned to be the longest in the
//                 political historey of andhra pradesh.
//               </p>
//             </div>
//           </div>
//         </div>
