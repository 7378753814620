import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import raghavendraLogo from "../assets/raghavendra-logo.png";
import { useEffect, useState } from "react";

function Header() {
  const activePage = window.location.pathname;
  const [activeItem, setActiveItem] = useState("home");

  const [navItems, setNavItems] = useState({ display: "none" });
  const [openNavBtn, setOpenNavBtn] = useState({ display: "inline-block" });
  const [closeNavBtn, setCloseNavBtn] = useState({ display: "none" });
  const handleClickOpen = () => {
    setNavItems({ display: "flex" });
    setOpenNavBtn({ display: "none" });
    setCloseNavBtn({ display: "inline-block" });
  };
  const handleClickClose = () => {
    setNavItems({ display: "none" });
    setOpenNavBtn({ display: "inline-block" });
    setCloseNavBtn({ display: "none" });
  };

  //SCROLL
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    setActiveItem(activePage);
  }, []);

  //SCROLL BEHAVIOR

  const handleScroll = () => {
    const pos = window.scrollY;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const newValue = Math.round((pos / calcHeight) * 100);

    setScrollValue(newValue);

    const scrollProgress = document.getElementById("progress");
    if (pos > 100) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
  };

  const handleScrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollProgress = document.getElementById("progress");

    scrollProgress.style.backgroundImage = `conic-gradient(#da3925 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
  }, [scrollValue]);

  useEffect(() => {
    handleScroll(); // Call the initial scroll handler to set the initial state
  }, []); // Empty dependency array to run once on mount

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="header2">
      <div id="progress">
        <span id="progress-value" onClick={scrollToTop}>
          {/* &#x1F815;  */}
          <FontAwesomeIcon className="scroll-icon" icon={faArrowUp} />
        </span>
      </div>
      <header className="header">
        <a href="#" className="header__logo">
          <img className="header__logo__img" src={raghavendraLogo} />
          <Link to="/aboutMe">
            {" "}
            <span> Raghavendra </span>{" "}
          </Link>
        </a>
        <ul className="header__navlist">
          <Link to="/">
            <li>
              <a
                href="#"
                className={activeItem === "/" ? "active" : ""}
                style={{ "--i": 1 }}
              >
                Home
              </a>
            </li>
          </Link>
          <Link to="/aboutMe">
            <li>
              <a
                href="#"
                className={activeItem === "/aboutMe" ? "active" : ""}
                style={{ "--i": 2 }}
              >
                About
              </a>
            </li>
          </Link>
          <li>
            <a
              className={activeItem === "/activities/political" ? "active" : ""}
              style={{ "--i": 3 }}
            >
              Activities{" "}
              <div className="header__navlist__sub-list">
                <ul>
                  <Link to="/activities/political">
                    <li> Political</li>
                  </Link>
                  <Link to="/activities/community">
                    <li>Community</li>{" "}
                  </Link>
                  <Link to="/activities/trust">
                    <li>Trust</li>
                  </Link>
                </ul>
              </div>
            </a>
          </li>

          <Link to="/services">
            <li>
              <a
                href="#"
                className={activeItem === "/services" ? "active" : ""}
                style={{ "--i": 4 }}
              >
                News & Media
              </a>
            </li>
          </Link>

          <li>
            <a
              className={activeItem === "/images" ? "active" : ""}
              style={{ "--i": 5 }}
            >
              Gallery
              <div className="header__navlist__sub-list">
                <ul>
                  <Link to="/images">
                    <li>Photos</li>
                  </Link>
                  <Link to="/videos">
                    <li>Videos</li>{" "}
                  </Link>
                </ul>
              </div>
            </a>
          </li>

          <Link to="/leaders">
            <li>
              <a
                href="#"
                className={activeItem === "/leaders" ? "active" : ""}
                style={{ "--i": 5 }}
              >
                Leaders
              </a>
            </li>
          </Link>

          <Link to="/contact">
            <li>
              <a
                href="#"
                className={activeItem === "/contact" ? "active" : ""}
                style={{ "--i": 5 }}
              >
                Contact
              </a>
            </li>
          </Link>
        </ul>
      </header>
      <nav>
        <div className="nav__container">
          <a href="#" className="header__logo">
            <img className="header__logo__img" src={raghavendraLogo} />
            <Link to="/aboutMe">
              {" "}
              <span> Raghavendra </span>{" "}
            </Link>
          </a>
          <ul style={navItems} className="nav__links">
            <li>
              <Link to="/">
                <a
                  href="#"
                  className={activeItem === "/" ? "active" : ""}
                  style={{ "--i": 1 }}
                >
                  Home
                </a>
              </Link>
            </li>
            <li>
              <Link to="/aboutMe">
                <a
                  href="#"
                  className={activeItem === "/aboutMe" ? "active" : ""}
                  style={{ "--i": 2 }}
                >
                  About
                </a>
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/activities/political">
                <a> Political</a>
              </Link>
            </li>
            <li>
              <Link to="/activities/community">
                <a>Community</a>
              </Link>
            </li>{" "}
            {/* <li>
              <Link to="/activities/trust">
                <a>Trust</a>
              </Link>
            </li> */}
            <li>
              <Link to="/services">
                <a
                  href="#"
                  className={activeItem === "/services" ? "active" : ""}
                  style={{ "--i": 4 }}
                >
                  News & Media
                </a>
              </Link>
            </li>
            <li>
              <Link to="/images">
                <a>Photos</a>
              </Link>
            </li>
            <li>
              <Link to="/videos">
                <a>Videos</a>
              </Link>
            </li>{" "}
            <li>
              <Link to="/leaders">
                <a
                  href="#"
                  className={activeItem === "/leaders" ? "active" : ""}
                  style={{ "--i": 5 }}
                >
                  Leaders
                </a>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <a
                  href="#"
                  className={activeItem === "/contact" ? "active" : ""}
                  style={{ "--i": 5 }}
                >
                  Contact
                </a>
              </Link>
            </li>
          </ul>
          <button
            className="nav__toggle-btn"
            id="nav__toggle-open"
            style={openNavBtn}
            onClick={handleClickOpen}
          >
            <i className="uil uil-bars"></i>
          </button>
          <button
            className="nav__toggle-btn"
            id="nav__toggle-close"
            style={closeNavBtn}
            onClick={handleClickClose}
          >
            <i className="uil uil-times"></i>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Header;
