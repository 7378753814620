import React, { useState, useEffect } from "react";

import img3 from "../assets/slide2.jpg";
import img22 from "../assets/Lokesh (11).jpg";
import img2 from "../assets/Lokesh (7).jpg";
import img4 from "../assets/Kollu (3).jpg";
import img5 from "../assets/BVR (2).jpg";
import img6 from "../assets/BRC (21).jpg";
import img7 from "../assets/newslide1.JPG";
import img8 from "../assets/newslide2.JPG";
import img9 from "../assets/newslide3.jpg";
import img10 from "../assets/newslide4.jpg";

//blog/src/components/assets/newslide1.JPG
import sliderImg1 from "../assets/yuvachaithanya-yathra (105).jpg";

function HeaderSlider() {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideClick = (index) => {
    setActiveSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % 9);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider">
      <img
        className={`img-slide ${activeSlide === 0 ? "active" : ""}`}
        alt="1"
        src={img3}
      />
      <img
        className={`img-slide ${activeSlide === 1 ? "active" : ""}`}
        src={img22}
        alt="1"
      />
      <img
        className={`img-slide ${activeSlide === 2 ? "active" : ""}`}
        alt="1"
        src={img2}
      />
      <img
        className={`img-slide ${activeSlide === 3 ? "active" : ""}`}
        alt="1"
        src={img4}
      />
      <img
        className={`img-slide ${activeSlide === 4 ? "active" : ""}`}
        alt="1"
        src={img5}
      />
      <img
        className={`img-slide ${activeSlide === 5 ? "active" : ""}`}
        alt="1"
        src={img6}
      />
      <img
        className={`img-slide ${activeSlide === 6 ? "active" : ""}`}
        alt="1"
        src={img8}
      />
      <img
        className={`img-slide ${activeSlide === 7 ? "active" : ""}`}
        alt="1"
        src={img9}
      />
      <img
        className={`img-slide ${activeSlide === 8 ? "active" : ""}`}
        alt="1"
        src={img10}
      />

      <div className="slider__navigation">
        {Array.from({ length: 9 }).map((_, index) => (
          <div
            key={index}
            className={`slider__navigation__btn ${
              activeSlide === index ? "active" : ""
            }`}
            onClick={() => handleSlideClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default HeaderSlider;
