import React, { useState } from "react";
import { v4 as uuidV4 } from "uuid";
// import "./Videos.css";
import { Link } from "react-router-dom";
import Header from "../header/header";
import Footer from "../footer/footer";
import HeaderAbout from "../headerAbout/headerAbout";

const videoTdp = [
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "education",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "service",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "service",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "donations",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "public",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "public",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "awards",
  },
  {
    id: uuidV4(),
    videoUrl: "https://www.youtube.com/embed/owTQshg5_F0",
    category: "awards",
  },
];
function Videos() {
  const [showNewPage, setShowNewPage] = useState(true);
  const [showSelectImage, setSelectImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [bgColor, setBgColor] = useState();
  const [selectedSection, setSelectedSection] = useState(videoTdp);

  const [selectedButton, setSelectedButton] = useState(0);

  const onAllSelectImage = () => {
    setSelectedButton(0);
    setSelectedSection(videoTdp);
  };

  const onEduaction = () => {
    const filterSection = videoTdp.filter(
      (each) => each.category === "education"
    );
    setSelectedButton(1);
    setSelectedSection(filterSection);
  };

  const onServiceImages = () => {
    const filterSection = videoTdp.filter(
      (each) => each.category === "service"
    );
    setSelectedButton(2);
    setSelectedSection(filterSection);
  };
  const onDonationsImages = () => {
    const filterSection = videoTdp.filter(
      (each) => each.category === "donations"
    );
    setSelectedButton(3);
    setSelectedSection(filterSection);
  };
  const onPublicImages = () => {
    const filterSection = videoTdp.filter((each) => each.category === "public");
    setSelectedButton(4);
    setSelectedSection(filterSection);
  };
  const onAwardsImages = () => {
    const filterSection = videoTdp.filter((each) => each.category === "awards");
    setSelectedButton(5);
    setSelectedSection(filterSection);
  };

  console.log(selectedSection);

  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="heading-direction-cont">
        <h1 className="heading-primary ">Videos</h1>
      </div>

      <div className="bg-videos-tdp">
        <div className="gallery-sub-head-cont u-padding-bottom-vidoe-small">
          <div>
            <button
              className={
                selectedButton === 0
                  ? "gallery-sub-head-select"
                  : "gallery-sub-head-unselect"
              }
              onClick={onAllSelectImage}
            >
              ALL
            </button>
          </div>
          <div>
            <button
              className={
                selectedButton === 1
                  ? "gallery-sub-head-select"
                  : "gallery-sub-head-unselect"
              }
              onClick={onEduaction}
            >
              EDUCATION
            </button>
          </div>
          <button
            className={
              selectedButton === 2
                ? "gallery-sub-head-select"
                : "gallery-sub-head-unselect"
            }
            onClick={onServiceImages}
          >
            SERVICE
          </button>
          <div>
            <button
              className={
                selectedButton === 3
                  ? "gallery-sub-head-select"
                  : "gallery-sub-head-unselect"
              }
              onClick={onDonationsImages}
            >
              DONATIONS
            </button>
          </div>
          <div>
            <button
              className={
                selectedButton === 4
                  ? "gallery-sub-head-select"
                  : "gallery-sub-head-unselect"
              }
              onClick={onPublicImages}
            >
              PUBLIC
            </button>
          </div>
          <div>
            {" "}
            <button
              className={
                selectedButton === 5
                  ? "gallery-sub-head-select"
                  : "gallery-sub-head-unselect"
              }
              onClick={onAwardsImages}
            >
              AWARDS
            </button>
          </div>
        </div>
        <div className="videos-cont-tdp">
          {selectedSection.map((each) => (
            <div>
              <iframe
                key={each.id}
                className="you-tube-videos-tdp"
                src={each.videoUrl}
                alt={`Image-${each.id}`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Videos;
